import React, { useEffect, useState, useContext, useCallback } from 'react'
import UserProfile from "../../../assets/icons/usericon.svg";
import StatsDropdown from '../StatsDropdown';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import { UserContext } from '../../../contexts/UserContext';
import axios from 'axios';
import { AWSAmplifyConfigContext } from '../../../contexts/AWSAmplifyConfigContext';
import toast from 'react-hot-toast';
import NotificationList from './NotificationList';
import { useLoader } from '../../../contexts/LoaderContext';
import FilterDropdown from './FilterDropdown';
import { Link } from 'react-router-dom';

function Notification() {
    const [notificationDetails, setNotificationDetails] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [markedNotifications, setMarkedNotifications] = useState([]);
    const { user } = useContext(UserContext);
    const awsContextVal = useContext(AWSAmplifyConfigContext);
    const { showLoader, hideLoader } = useLoader();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
        // If "Select All" is clicked, select all notifications
        if (isChecked == false) {
            setMarkedNotifications(notificationDetails.map((notification) => notification.id));
        } else {
            setMarkedNotifications([]); // Deselect all
        }
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    

    const markAllAsRead = async () => {
        try {
            if (markedNotifications.length > 0) {
                const response = await axios.post(`${awsContextVal?.endpoint}/notification/mark-read`, {
                    ids: markedNotifications,
                });
                toast.success(response?.data?.message);
                // Clear the selected notifications after API call
                setMarkedNotifications([]);
                setIsChecked(false);
                fetchAllNotification();
            } else {
                toast.error('No notifications selected');
            }
        } catch (err) {
            toast.error('Failed to mark notifications as read');
        }
    };

    const markSingleAsRead = async (notificationId) => {
        try {
            showLoader();
            const response = await axios.post(`${awsContextVal?.endpoint}/notification/mark-read`, {
                ids: [notificationId],
            });
            toast.success('Notification marked as read');
            fetchAllNotification(); // Refresh notification list
        } catch (err) {
            toast.error('Failed to mark notification as read');
        } finally {
            hideLoader();
        }
    };

    const fetchAllNotification = useCallback(
        async (is_read = null, start_date = null, end_date = null) => {
            try {
                setLoading(true);

                const requestBody = {
                    user_id: user?.id,
                    sort_by: "n.id",
                    sort_order: "DESC",
                };

                if (is_read !== null) {
                    requestBody.is_read = `${is_read}`;
                }

                if (start_date && end_date) {
                    requestBody.start_date = start_date;
                    requestBody.end_date = end_date;
                }

                const response = await axios.post(
                    `${awsContextVal?.endpoint}/notification/list`,
                    requestBody
                );

                setNotificationDetails(response.data.notificationList || []);
            } catch (error) {
                toast.error(
                    error?.response?.data?.message ||
                    "An error occurred while fetching notifications."
                );
            } finally {
                setLoading(false);
            }
        },
        [user?.id] // Ensure dependencies are correctly tracked
    );

    const handleDateChange = useCallback(({ start_date, end_date }) => {
        if (start_date && end_date) {
            fetchAllNotification(null, start_date, end_date);
        }
    }, [fetchAllNotification]);



    useEffect(() => {
        fetchAllNotification();
    }, [user])

    return (
        <div className="tw-w-full tw-h-full tw-mx-[30px] tw-my-[12px]">
            <div className='tw-flex tw-gap-4 tw-items-center'>
                <Link to={`/dashboard/${user?.user_type}`}>
                    <i className="fas fa-chevron-left tw-w-18.41px tw-h-15.41px tw-text-[#2A2F70]"></i>
                </Link>
                <h1 className=' tw-text-[#000000] tw-font-bold tw-text-[40px] common-font'>
                    Notifications
                </h1>
            </div>
            <p className='tw-text-[18px] tw-font-semibold common-font tw-text-[#202224] tw-opacity-70'>Here is the information about all your notifications.</p>

            {/* Notification Main Container */}
            <div className='tw-w-full tw-h-[813px] tw-border tw-border-[#DDDDDD] tw-rounded-[10px] tw-mt-[30px]'>
                {/* Notification Main container Header */}
                <div className='tw-mx-[20px] tw-h-[79px] tw-border-b-[1px] tw-border-[#DDDDDD] tw-flex tw-justify-between tw-items-center'>
                    <div className=' tw-flex '>
                        <div className='tw-w-[104px] tw-h-[39px] tw-flex tw-items-center'>
                            <div
                                className={`tw-cursor-pointer tw-w-[24px] tw-h-[24px] tw-rounded-[5px] tw-flex tw-justify-center tw-items-center tw-border  ${isChecked ? 'tw-border-[#00B69B] tw-bg-[#00B69B14]' : 'tw-border-[#DDDDDD]'
                                    }`}
                                onClick={handleCheckboxChange}
                                // onClick={() => setIsChecked(!isChecked)}
                            >
                                {isChecked ?
                                    <>
                                        <i className="fas fa-check tw-text-green-500 tw-text-xs"></i>
                                    </>
                                    :
                                    <>
                                    </>
                                }
                            </div>
                            <label htmlFor="selectAll" className="tw-ps-[8px] tw-text-[#4F4F4F] tw-font-semibold common-font tw-text-[16px] tw-cursor-pointer">
                                {isChecked ? "Deselect" : "Select All"}
                            </label>
                        </div>

                        {isChecked && (
                            <div className='tw-ms-[10px] tw-w-[164px] tw-h-[42px] tw-border tw-border-[#DDDDDD] tw-bg-[#7DDE92] tw-rounded-[10px]'>
                                <div className='tw-w-[128px] tw-h-[22px] tw-ms-[17px] tw-mt-[9px] tw-flex tw-justify-between tw-justify-center tw-items-center'>
                                    <div className='relative inline-block'>
                                        <i className="fas fa-bell tw-text-black tw-cursor-pointer"></i>
                                    </div>
                                    <button className=' tw-font-semibold tw-text-[#4F4F4F] tw-text-[16px] common-font'
                                    onClick={markAllAsRead}
                                    >
                                        Mark As Read
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='tw-w-[288px] tw-h-[39px] tw-flex'>
                        <div className='tw-relative tw-w-[133px] tw-h-[39px] tw-border tw-border-[#DDDDDD] tw-rounded-[5px] tw-bg-[#FFFFFF]'>
                            <div
                                className='tw-w-full tw-h-[28.05px] tw-py-[10px] tw-mt-[5px] tw-cursor-pointer tw-flex tw-items-center tw-justify-center tw-gap-x-2'
                                onClick={toggleDropdown}
                            >
                                <i className="fas fa-bell tw-text-black tw-cursor-pointer"></i>
                                <span className='tw-text-[#4F4F4F] tw-text-[14px] tw-font-semibold common-font'>Show All</span>
                            </div>

                            {isOpen && (
                                <div className='borrower-common-menu'>
                                    <div className='tw-py-[8px] tw-cursor-pointer tw-flex tw-items-center tw-justify-center tw-gap-x-2 hover:tw-bg-gray-100 tw-border-b-[2px]'
                                         onClick={() => fetchAllNotification(1)}
                                    >
                                        <i className="fas fa-bell-slash tw-text-gray-400 tw-cursor-pointer"></i>
                                        <span className='tw-text-[#4F4F4F]'>Show Read</span>
                                    </div>

                                    <div className='tw-py-[8px] tw-cursor-pointer tw-flex tw-items-center tw-justify-center tw-gap-x-2 hover:tw-bg-gray-100'
                                         onClick={() => fetchAllNotification(0)}
                                    >
                                        <i className="fas fa-bell tw-text-red-500 tw-cursor-pointer"></i>
                                        <span className='tw-text-[#4F4F4F]'>Show Unread</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="tw-block">
                            <FilterDropdown onDateChange={handleDateChange}  />
                        </div>

                    </div>
                </div>


                <div className='tw-mx-[20px] tw-mt-[20px] tw-h-[694px]'>
                    <div className='tw-overflow-y-auto tw-max-h-[694px]  custom-scroll'>
                        {notificationDetails?.length > 0 ? (
                            notificationDetails?.map((data) => (
                                <NotificationList 
                                    key={data?.id} 
                                    notification={data}
                                    onMarkAsRead={markSingleAsRead}
                                />
                            ))
                        ) : (
                            <NotificationList notification={null} />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Notification