import React, { useContext, useState, useEffect, useRef } from 'react';
import { signOut } from '@aws-amplify/auth';
import { Link, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { UserContext } from '../../../contexts/UserContext';
import LogoImage from "../../../assets/icons/Logo.png";
import UserProfile from "../../../assets/icons/usericon.svg";
import { authLocal } from '../../../utils/common';
import axios from 'axios';
import { AWSAmplifyConfigContext } from '../../../contexts/AWSAmplifyConfigContext';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import ActiveSubscription from "../../common/ActiveSubscription";
import { capitalize } from '@mui/material';

const Header = () => {
  const [notificationDetails, setNotificationDetails] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [isRead, setIsRead] = useState(false);
  const dropdownRef = useRef(null);
  const notificationRef = useRef(null);
  const navigate = useNavigate();
  const { resetUser, userType } = useContext(UserContext);
  const { user } = useContext(UserContext);
  const awsContextVal = useContext(AWSAmplifyConfigContext);

  const goToHome = () => {
    navigate("/");
  }

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleNotificationBox = async () => {
    setIsNotificationOpen(!isNotificationOpen);
    const notificationIds = notificationDetails?.map((notification) => notification?.id);
    // Check if there are any notifications to mark as read
    // Call the API to mark all as read
    await markNotificationsAsRead();
  };

  const markNotificationsAsRead = async () => {

    try {
      // Sending the API request to mark notifications as read
      const response = await axios.post(`${awsContextVal?.endpoint}/notification/mark-read`, {
        user_id: user?.id,
      });
      // Check if the API response is successful
      if (response?.status === 200) {
        // toast.success(response?.data?.message);
        setIsRead(false);
        console.log(response?.data?.message);
      } else {
        toast.error('Failed to mark notifications as read');
      }
    } catch (error) {
      // Handle any errors during the API call
      if (error.response) {
        toast.error(error.response.data.message || 'API request failed');
      } else {
        toast.error('An error occurred while marking notifications as read');
      }
    }
  };

  const handleLogOut = async () => {
    try {
      await signOut();
      toast.success("Signed Out!");
      window.localStorage.removeItem("re_loan_info");
      window.localStorage.removeItem("user");
      authLocal.remove();
      window.localStorage.removeItem("pendingApplicationData");
      resetUser();
      navigate("/");
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };

  const fetchNotification = async () => {
    if (!user) return;

    try {
      const response = await axios.post(`${awsContextVal?.endpoint}/notification/list`,
        {
          user_id: user?.id,
          sort_by: "n.id",
          sort_order: "DESC",
          start: '0',
          limit: '10'
        }
      );
      const hasUnreadNotifications = response?.data?.notificationList?.some(notification => notification?.is_read === 0);
      if (hasUnreadNotifications == true) {
        setIsRead(true);
      }
      setNotificationCount(response?.data?.notificationCount);
      setNotificationDetails(response?.data?.notificationList);
      // console.log("notificationDetails:", notificationDetails);
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data?.message);
      } else {
        toast.error("An error occurred while fetching notification.");
      }
    }
  };

  useEffect(() => {
    fetchNotification();

    const intervalId = setInterval(() => {
      fetchNotification();
    }, 100000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [user]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
      if (notificationRef.current && !notificationRef.current.contains(event.target)) {
        setIsNotificationOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header className="tw-bg-[#555FE1] tw-w-full lg:tw-w-full tw-h-[87px] tw-flex tw-items-center tw-justify-between tw-px-[20px] tw-py-[8px]">
      {/* Left side: Company Logo */}
      <div className="tw-flex tw-items-center tw-w-[137px] tw-h-[21px]" onClick={goToHome}>
        <img src={LogoImage} alt="Re-loan logo" />
      </div>
      {(user?.user_type === "borrower" || user?.user_type === "lender") && (
        <div className="tw-flex tw-items-center">
          <h1 className='tw-text-white tw-text-[40px] tw-font-bold tw-leading-[60px]'>
            {capitalize(user?.user_type)} Dashboard
          </h1>
        </div>
      )}

      {/* Right side: Profile Image, Username, and Dropdown */}
      <div className="tw-relative tw-flex tw-items-center" ref={dropdownRef}>
        {userType === "lender" && <div className='tw-mx-2'><ActiveSubscription plan={user?.plan} offer={user?.offer} /></div>}
        <div className="tw-w-[24px] tw-h-[24px] tw-me-[15px] tw-relative tw-flex tw-items-center" onClick={toggleNotificationBox} ref={notificationRef}>
          <div style={{ position: 'relative', display: 'inline-block' }}>
            <Badge
              badgeContent={isRead ? notificationCount : 0}
              color="error"
              sx={{
                '& .MuiBadge-badge': {
                  top: -5,
                  right: -5,
                },
              }}
            >
              <i className="fas fa-bell tw-text-white tw-cursor-pointer"></i>
            </Badge>
          </div>
          {/* Notification Box */}
          {isNotificationOpen && (
            <div
              className="tw-absolute tw-bg-white tw-border tw-border-[#DDDDDD] tw-rounded-[10px] tw-opacity-100 tw-shadow-lg tw-p-[10px_20px] tw-top-[40px] tw-left-[-370px] tw-z-50"
              style={{ width: '394px' }}
            >
              <div className="tw-w-full tw-flex tw-items-center tw-justify-between tw-border-b-[1px] tw-border-[#DDDDDD] tw-mb-2">
                <h2 className="tw-text-[#444444] tw-text-semibold tw-text-[20px] common-font">
                  New Notifications
                </h2>
                <Link
                  to={`notifications`}
                  className="tw-text-[#2A2F70] tw-text-semibold tw-text-[16px] common-font tw-cursor-pointer"
                >
                  View All
                </Link>
              </div>

              {/* Scrollable content container */}
              <div
                className="tw-overflow-y-auto tw-w-full"
                style={{ maxHeight: '200px' }}
              >
                {notificationDetails.length > 0 ? (
                  notificationDetails.map((item, index) => (
                    <div
                      key={item?.id}
                      className="tw-w-full tw-h-[59px] tw-flex tw-items-center tw-justify-between tw-border-b-[1px] tw-border-[#DDDDDD] tw-py-[10px]"
                    >
                      <img
                        src={item?.profile_image_url ? item?.profile_image_url : UserProfile}
                        alt={`Profile-${item?.created_user_name}`}
                        title={`${item?.created_user_name}`}
                        className="tw-w-[32px] tw-h-[32px] tw-rounded-full tw-border-[1px] tw-border-[#DDDDDD] tw-bg-[#DDDDDD]"
                      />
                      <div className="tw-flex tw-flex-col tw-justify-start tw-ml-4 tw-flex-grow">
                        <h2
                          className={`tw-text-[#444444] tw-font-semibold tw-text-[16px] common-font ${item?.is_read === 0 ? 'tw-bg-[#f0f8ff] tw-text-black tw-hover:tw-bg-[#e0f0ff] tw-shadow-lg' : ''} ${item?.notification.length > 10 ? 'tw-whitespace-normal' : 'tw-whitespace-nowrap'
                            } tw-overflow-hidden tw-text-ellipsis tw-flex-grow`}
                        >
                          {item?.notification}
                        </h2>
                      </div>
                      <p
                        className={`tw-text-[#484848] tw-opacity-70 tw-text-normal tw-text-[16px] common-font ${item?.is_read === 0 ? 'tw-bg-[#f0f8ff] tw-text-black tw-hover:tw-bg-[#e0f0ff] tw-shadow-lg' : ''}`}
                      >
                        {new Date(item?.created_at).toLocaleTimeString([], {
                          hour: '2-digit',
                          minute: '2-digit',
                        }) || 'N/A'}
                      </p>
                    </div>
                  ))
                ) : (
                  <div className="tw-w-full tw-h-[59px] tw-flex tw-items-center tw-justify-center">
                    <p className="tw-text-[#888888] tw-text-[16px] common-font">No new notifications</p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        {/* Profile Image */}
        <img
          src={user?.profile_image_url || UserProfile}
          alt="Profile"
          className="tw-w-[32px] tw-h-[32px] tw-me-[10px] tw-rounded-full"
        />

        <div
          className="tw-ml-2 tw-flex tw-items-center tw-cursor-pointer"
          onClick={toggleDropdown}
        >
          <span className="tw-text-white common-font tw-font-normal tw-text-[17px] tw-capitalize">{user?.first_name + " " + user?.last_name}</span>
          <i className="tw-ml-2 tw-text-white fas fa-chevron-down"></i>
        </div>

        {/* Dropdown Menu */}
        {isDropdownOpen && (
          <div className="tw-absolute tw-right-0 tw-top-full tw-mt-2 tw-w-52 tw-bg-white tw-rounded-md tw-shadow-lg tw-border tw-py-2 tw-z-50">
            <Link
              to="settings"
              className="account_setting tw-block tw-w-full tw-text-left tw-px-4 tw-py-2 tw-text-black hover:tw-bg-gray-100"
            >
              <i className="tw-mr-2 tw-text-black fas fa-cog"></i>
              Account Setting
            </Link>
            {userType === "lender" && <><Link
              to="payment-list"
              className="payment_list tw-block tw-w-full tw-text-left tw-px-4 tw-py-2 tw-text-black hover:tw-bg-gray-100"
            >
              <i className="tw-mr-2 tw-text-black fas fa-list"></i>
              Payment List
            </Link>
              <Link
                to="change-subscription"
                className="update_membership tw-block tw-w-full tw-text-left tw-px-4 tw-py-2 tw-text-black hover:tw-bg-gray-100"
              >
                <i className="tw-mr-2 tw-text-black fas fa-user-edit"></i>
                Update Membership
              </Link></>}

            {
              userType === "admin" && (
                <>
                  <Link
                    to="cms-pages"
                    className="tw-block tw-w-full tw-text-left tw-px-4 tw-py-2 tw-text-black hover:tw-bg-gray-100"
                  >
                    <i className="tw-mr-2 tw-text-black fas fa-list"></i>
                    CMS Pages
                  </Link>
                </>
              )
            }

            <button
              className="logout_btn tw-block tw-w-full tw-text-left tw-px-4 tw-py-2 tw-text-black hover:tw-bg-gray-100"
              onClick={handleLogOut}
            >
              <i className="tw-mr-2 tw-text-black fas fa-sign-out-alt"></i>
              Logout
            </button>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
