import React, { useState, useContext, useEffect } from "react";
import { loadStripe } from '@stripe/stripe-js';
import toast from "react-hot-toast";
import { UserContext } from '../../../contexts/UserContext';
import { AWSAmplifyConfigContext } from '../../../contexts/AWSAmplifyConfigContext';
import axios from 'axios';
import { useLoader } from '../../../contexts/LoaderContext';
import { Link } from 'react-router-dom';

const ChangeSubscription = () => {
    const [disabledButton, setDisabledButton] = useState(null);
    const { user } = useContext(UserContext);
    const [plans, setPlans] = useState([]);
    const awsContextVal = useContext(AWSAmplifyConfigContext);
    const { showLoader, hideLoader } = useLoader();


    useEffect(() => {
        showLoader()
        const fetchPlans = async () => {
            try {
                const response = await axios.post(`${awsContextVal?.endpoint}/subscription-plan/list`, { status: 1 });
                const data = response.data.subscriptionPlanList;
                setPlans(data);
                hideLoader()
            } catch (error) {
                console.error(error);
                hideLoader()
            }
        };

        fetchPlans();
    }, [awsContextVal?.endpoint]);

    console.log("user", user);

    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

    const handleCheckoutStripe = async (priceId, index) => {
        console.log(process.env.REACT_APP_PRICE_ID_TEST_PLAN);
        const stripe = await stripePromise;

        if (!stripe) {
            toast.error('Stripe initialization failed');
            return;
        }
        setDisabledButton(index);
        const { error } = await stripe.redirectToCheckout({
            lineItems: [{ price: priceId, quantity: 1 }], // Use dynamic price ID
            mode: 'subscription',
            successUrl: `${window.location.origin}/subscription/success?session_id={CHECKOUT_SESSION_ID}`,
            cancelUrl: window.location.href,
            customerEmail: user.email,
        });

        if (error) {
            console.error('Stripe Checkout Error:', error.message);
            toast.error('Stripe Checkout Error:', error.message);
        }
    };

    return (
        <div className="tw-w-full tw-min-h-screen">
            <div className="tw-text-[#4853E4] tw-text-center tw-font-inter tw-pt-[35px]">
                Pricing Plans
            </div>

            <div className="tw-flex tw-items-center tw-justify-center">
                <Link to="/dashboard/lender">
                    <i className="fas fa-chevron-left tw-w-18.41px tw-h-15.41px tw-text-[#2A2F70]"></i>
                </Link>
                <div className="tw-ml-2 tw-text-black tw-text-center tw-text-[48px] tw-font-[700] tw-font-oswald">

                    Pricing That Suits Your Needs.
                </div>
            </div>

            <div className="tw-font-inter tw-mt-4 tw-text-[#4D4D4D] tw-text-center tw-text-[18px] tw-font-[400]">
                Whether you’re exploring your first deal or managing a high-volume portfolio, our plans <br className="tw-hidden md:tw-inline" /> offer the tools and flexibility you need to succeed.
            </div>

            <section className="lg:tw-mx-[8%] md:tw-mx-[4%] sm:tw-mx-[4%] tw-mx-[4%] tw-mt-8 tw-pb-5">
                <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 lg:tw-grid-cols-3 tw-gap-8">
                    {plans?.map((plan, index) => (
                        <div key={index} className="tw-w-full tw-max-w-sm tw-px-[48px] tw-py-[40px] tw-bg-white tw-border tw-border-gray-200 tw-rounded-[16px] tw-shadow-[0px_7px_15px_0px_rgba(0,0,0,0.15)] dark:tw-bg-gray-800 dark:tw-border-gray-700">
                            <div>
                                <div className="tw-font-inter tw-text-[#4853E4] tw-text-[27px] tw-font-[700]">
                                    <div className="tw-flex tw-whitespace-nowrap">
                                        {plan?.plan}
                                        {
                                            user.plan === plan?.plan && (
                                                <span className="tw-bg-yellow-100 tw-h-5 tw-text-yellow-800 tw-text-xs tw-font-medium tw-me-2 tw-px-2.5 tw-py-0.5 tw-rounded tw-dark:bg-yellow-900 tw-dark:text-yellow-300">Activated</span>

                                            )
                                        }
                                    </div>
                                </div>
                                <div className="tw-font-inter tw-text-[#252422] tw-text-[13px] tw-font-[400] tw-mt-3">{plan?.description}</div>
                                <div className="tw-flex tw-justify-center tw-mt-[30px] tw-mb-[24px]">
                                    <div className="tw-flex tw-items-baseline tw-text-gray-900 dark:tw-text-white tw-font-inter">
                                        <div className="tw-text-[49px] tw-font-extrabold tw-tracking-tight">
                                            <sup className="tw-text-[13px] tw-text-[#252422] tw-font-[400] tw-align-super">$</sup>{plan?.amount}
                                        </div>
                                        <span className="tw-ms-1 tw-text-[13px] tw-font-normal tw-text-[#252422] dark:tw-text-gray-400">{plan?.pricePeriod || '/month'}</span>
                                    </div>
                                </div>
                                <button
                                    // onClick={() => handleCheckoutStripe(plan?.stripe_price_id, index)}
                                    type="button"
                                    className={`tw-font-inter tw-text-white tw-uppercase tw-bg-[#4853E4] focus:tw-ring-4 focus:tw-outline-none focus:tw-ring-blue-200 dark:tw-bg-blue-600 dark:hover:tw-bg-blue-700 dark:focus:tw-ring-blue-900 tw-font-medium tw-rounded-lg tw-text-[16px] tw-px-5 tw-py-3 tw-inline-flex tw-justify-center tw-w-full tw-text-center ${disabledButton === index ? 'tw-bg-gray-200 tw-cursor-not-allowed' : ''}`}
                                    disabled={disabledButton === index}
                                >
                                    Subscribe
                                </button>
                                <div className="tw-mt-[24px]">
                                    {plan?.facility?.map((feature, featureIndex) => (
                                        <div key={featureIndex} className="tw-flex tw-mt-4">
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53446 21.3746C6.51167 20.6273 4.78465 19.2461 3.61095 17.4371C2.43726 15.628 1.87978 13.4881 2.02167 11.3363C2.16355 9.18455 2.9972 7.13631 4.39827 5.49706C5.79935 3.85781 7.69278 2.71537 9.79618 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999" fill="#4853E4" />
                                                    <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53446 21.3746C6.51167 20.6273 4.78465 19.2461 3.61095 17.4371C2.43726 15.628 1.87978 13.4881 2.02167 11.3363C2.16355 9.18455 2.9972 7.13631 4.39827 5.49706C5.79935 3.85781 7.69278 2.71537 9.79618 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999" stroke="#252422" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M22 4L12 14.01L9 11.01" fill="#4853E4" />
                                                    <path d="M22 4L12 14.01L9 11.01" stroke="#252422" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </div>
                                            <div
                                                className={`tw-ml-4 tw-font-inter tw-text-[13px] tw-text-[#252422] ${featureIndex === 0 ? "tw-font-[700]" : "tw-font-[400]"
                                                    }`}
                                            >
                                                {feature}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

            </section >
            <div className="tw-pt-5 tw-pb-[100px] tw-text-[#252422] tw-text-13px] tw-font-[400] tw-text-center tw-font-inter">
                For institutional inquiries, please reach out to us at admin@re-loan.com.
            </div>
        </div >
    )
}
export default ChangeSubscription;