import React from 'react';

function CmsContent({ title, description }) {
    return (
        <main
            className="tw-flex tw-items-center tw-relative tw-px-[20px] tw-py-[20px] tw-w-full tw-h-auto 
             md:tw-px-[80px] md:tw-py-[0px] tw-w-full"
        >
            <div className="tw-h-full tw-w-full">
                <header className="common-font tw-flex tw-justify-between tw-items-center tw-w-full tw-h-[60px]">
                    <h1 className="tw-text-black tw-text-[40px] tw-font-bold tw-leading-[60px]">{title}</h1>
                </header>
                <div className='tw-w-full tw-h-full'>
                    <div
                        className="common-font tw-text-[#242424] tw-mt-[10px]"
                        dangerouslySetInnerHTML={{ __html: description }}
                    ></div>
                </div>
            </div>
        </main>

    );
}



export default CmsContent;
