const AdvantageCard = ({ id, count, icon, title, description }) => {
    return (
        <div key={id} className="tw-flex tw-w-1/3 tw-flex-col tw-justify-center tw-p-10 tw-drop-shadow-xl tw-border-[1px] tw-rounded-lg tw-bg-[#FFFFFF] tw-cursor-pointer">
            {/* Counter */}
            <p className="tw-text-right tw-text-[#000000]/15 tw-text-3xl tw-font-sans tw-fixed tw-top-[10%] tw-right-[10%]">{count}</p>

            {/* Icon */}
            <div className="tw-w-[fit-content] tw-bg-[#4853E4]/40 tw-rounded-full tw-p-2 tw-fixed tw-top-6 tw-left-1/2 tw-transform -tw-translate-x-1/2 tw-text-center tw-my-6">
                <img src={icon} alt="uil process icon" className="tw-w-10 tw-h-10" />
            </div>

            {/* Text Section */}
            <div className="tw-h-full tw-flex tw-flex-col tw-space-y-4 tw-mt-24">
                <h2 className="tw-h-[65px] tw-font-oswald tw-text-2xl tw-font-bold tw-text-[#191919] tw-text-center">{title}</h2>
                <p className="tw-font-inter tw-text-lg tw-text-[#808285] tw-text-center">{description}</p>
            </div>
        </div>
    )
}

export default AdvantageCard;