import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/swiper-bundle.css";
import '../../../styles/TestimonialSwiper.css';
import QuotesIcon from "../../../assets/icons/quotesIcon.svg";

const TestimonialsArea = () => {
    const testimonials = [
        {
            paragraph: "This product has really helped our business grow significantly.",
            name: "Jennie Ruby",
            designation: "CEO, TechCorp",
            profileImage: "https://randomuser.me/api/portraits/women/1.jpg"
        },
        {
            paragraph: "Amazing service and great support. Highly recommend!",
            name: "Yoo Jimin",
            designation: "CTO, InnovateX",
            profileImage: "https://randomuser.me/api/portraits/men/1.jpg"
        },
        {
            paragraph: "Our team uses this platform daily and we love its simplicity.",
            name: "Shin Ryujin",
            designation: "COO, WebDyno",
            profileImage: "https://randomuser.me/api/portraits/women/2.jpg"
        },
        {
            paragraph: "A fantastic tool that has made our processes much smoother.",
            name: "Kim Taehyung",
            designation: "Founder, VisionSoft",
            profileImage: "https://randomuser.me/api/portraits/men/2.jpg"
        },
    ];

    return (
        <div className="tw-w-full tw-flex tw-flex-col tw-justify-center">
            <div className="tw-flex tw-items-center">
                <span className=" tw-w-8 tw-h-px tw-border-t tw-border-[0.5px] tw-border-blue-400"></span>
                <span className="tw-font-inter tw-px-2 tw-text-black tw-text-lg tw-font-normal tw-text-center">Testimonials</span>
                <span className=" tw-w-8 tw-h-px tw-border-t tw-border-[0.5px] tw-border-blue-400"></span>
            </div>

            <div className="tw-flex tw-justify-between">
                <h2 className="tw-w-1/2 tw-font-oswald tw-text-4xl tw-text-black tw-leading-10 lg:tw-text-5xl tw-font-bold"><span className="tw-text-[#4853e4]">Testimonials</span></h2>
                <p className="tw-w-1/2 tw-font-inter tw-text-lg tw-text-[#949494]">Our brand is very trusted,   Suggest taking down testimonials until you can get some. These ones are too obviously irrelevant, it makes the website lose credibility</p>
            </div>
            
            <div className="tw-py-14 tw-overflow-x-hidden">
                {/* <Swiper
                    modules={[Pagination, Autoplay]}
                    id="swiper"
                    slidesPerView="auto"
                    spaceBetween={30}
                    centeredSlides={true}
                    pagination={{
                        clickable: true,
                        renderBullet: (index, className) => {
                            return `<span className="${className} tw-indicator custom-indicator-swipe"></span>`;
                        },
                    }}
                    autoplay={{ delay: 3000 }}
                    breakpoints={{ 640: { slidesPerView: 1 }, 1024: { slidesPerView: 2 }, 1280: { slidesPerView: 3 } }}
                    style={{ paddingBottom: "50px" }}
                >
                    {testimonials.map((testimonial, index) => (
                        <SwiperSlide key={`slide-${index}`} style={{ listStyle: "none" }}>
                            <div className="tw-bg-white tw-text-black tw-rounded-lg tw-p-4 tw-shadow-lg tw-h-full slide-content">
                                <img src={QuotesIcon} alt="quoted text"/>
                                <p className="tw-font-inter tw-text-lg tw-text-[#949494]">{testimonial.paragraph}</p>
                                <div className="tw-flex tw-items-center tw-mt-4">
                                    <img
                                        src={testimonial.profileImage}
                                        alt={`${testimonial.name}'s profile`}
                                        className="tw-w-12 tw-h-12 tw-rounded-full tw-mr-2"
                                    />
                                    <div>
                                        <div className="tw-font-bold tw-font-oswald tw-text-xl">{testimonial.name}</div>
                                        <div className="tw-text-md tw-text-[#949494] tw-font-inter">{testimonial.designation}</div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper> */}
            </div>
            
        </div>
    )
}

export default TestimonialsArea;