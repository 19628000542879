import React, { useContext, useEffect, useState, memo } from 'react'
import { Link } from 'react-router-dom'
import SubscriptionModal from './SubscriptionModal';
import ApplicationTable from '../../../common/applicationTable/ApplicationTable';
import { UserContext } from '../../../../contexts/UserContext';
import axios from 'axios';
import { AWSAmplifyConfigContext } from '../../../../contexts/AWSAmplifyConfigContext';
import toast from 'react-hot-toast';
import { formatTimeDifference } from '../../../../utils/common';
import { useLoader } from '../../../../contexts/LoaderContext';
import { useModalContext } from '../../../../contexts/ModalContexts';
import { Button, Menu, MenuItem, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';


const ActionModal = memo(({ setHidden, setFormData, setOpenSubscriptionModal, fetchSubscriptionDetail, ...params }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [openConfirm, setOpenConfirm] = useState(false);
    const awsContextVal = useContext(AWSAmplifyConfigContext);
    const { showLoader, hideLoader } = useLoader();
    const { showModal } = useModalContext();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleConfirmOpen = () => {
        setOpenConfirm(true);
    };

    const handleConfirmClose = () => {
        setOpenConfirm(false);
    };

    const deleteSubscriptionHandler = async (params) => {
        showLoader();
        try {
            const response = await axios.delete(
                `${awsContextVal?.endpoint}/subscription-plan/delete/${params?.id}`
            );

            if (response?.status === 200) {
                showModal(response?.data?.message, "success");
            } else if (response?.status === 500) {
                showModal("Something Went Wrong.", "fail");
            }
            fetchSubscriptionDetail();
            setAnchorEl(null);
        } catch (err) {
            console.error('Error deleting user:', err);
            showModal('Failed to delete Subscription. Please try again.', "fail");
        } finally {
            hideLoader();
        }
    };

    // // Confirm deletion function
    const handleDeleteConfirmation = () => {
        deleteSubscriptionHandler(params?.row);
        setOpenConfirm(false); // Close the confirmation dialog
    };

    const handleEditSubscription = async(params) => {
        showLoader();
        try {
            const response = await axios.get(
                `${awsContextVal?.endpoint}/subscription-plan/${params?.id}`
            );
            
            if (response?.status === 200) {
                const responseSubscriptionData = response?.data?.subscriptionPlan; 
                setFormData({
                    plan: responseSubscriptionData[0]?.plan || '',
                    description: responseSubscriptionData[0]?.description || '',
                    amount: String(responseSubscriptionData[0]?.amount) || '',
                    offer: String(responseSubscriptionData[0]?.offer) || '',
                    facilities: responseSubscriptionData[0]?.facility || [],
                    status: String(responseSubscriptionData[0]?.status) || '',
                })
                setHidden(responseSubscriptionData[0]?.id);
                setOpenSubscriptionModal(true);
            } else if (response?.status === 500) {
                showModal("Something Went Wrong.", "fail");
            }
            fetchSubscriptionDetail();
            setAnchorEl(null);
        } catch(err) {
            console.error('Error deleting user:', err);
        } finally {
            hideLoader();
        }
    }

    return <>
        <button className=" tw-text-black tw-rounded tw-px-2 tw-py-1"
            onClick={handleClick}
        >
            <i className="fas fa-ellipsis-v tw-text-[#2A2F70] tw-text-md tw-cursor-pointer tw-opacity-80 tw-pr-2"></i>
        </button>
        <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <MenuItem onClick={handleConfirmOpen}>Delete</MenuItem>
            <MenuItem onClick={() => handleEditSubscription(params?.row)}>Edit</MenuItem>
        </Menu>
        <Dialog
            open={openConfirm}
            onClose={handleConfirmClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete this Subscription?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleConfirmClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleDeleteConfirmation} color="secondary" autoFocus>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    </>
});
function ViewSubscription() {
    const { user } = useContext(UserContext);
    const awsContextVal = useContext(AWSAmplifyConfigContext);
    const { showLoader, hideLoader } = useLoader();
    const [subscriptionData, setSubscriptionData] = useState([]);
    const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false);
    const [hidden, setHidden] = useState(null);
    const [formattedTimes, setFormattedTimes] = useState([]);
    const [formData, setFormData] = useState({
        plan: '',
        description: '',
        amount: '',
        offer: '',
        facilities: [''],
        status: '',
    });
    const [errors, setErrors] = useState({}); // For storing validation errors


    const handleCloseModal = () => setOpenSubscriptionModal(false);

    const handleOpenSubscription = () => {
        setFormData({
            plan: '',
            description: '',
            amount: '',
            offer: '',
            facilities: [''],
            status: '',
        });
        setOpenSubscriptionModal(true);
    };

    const columns = [
        {
            field: 'plan',
            headerName: 'Plan',
            width: 150,
            editable: false,
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 350,
            editable: false,
        },
        {
            field: 'amount',
            headerName: 'Amount',
            width: 120,
            editable: false,
        },
        {
            field: 'offer',
            headerName: 'Offer',
            width: 120,
            editable: false,
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 158,
            renderCell: (params) => {
                const statusId = params?.value;
                if(statusId == 1) {
                    return "Active"
                } else{
                    return "In - Active"
                }
            },
        },
        {
            field: 'facilities',
            headerName: 'Facilities',
            width: 350,
            editable: false,
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            width: 135,
            editable: false,
            renderCell: (params) => {
                const formattedTime = formattedTimes.find((item) => item.application_id === params.row.application_id)?.formattedTime;
                return formattedTime || formatTimeDifference(params.value);
            },
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 100,
            renderCell: (params) => (
                <>
                    <ActionModal 
                        setOpenSubscriptionModal={setOpenSubscriptionModal} 
                        fetchSubscriptionDetail={fetchSubscriptionDetail}
                        setFormData={setFormData}
                        setHidden={setHidden}
                        {...params}
                    />
                </>
            ),
        },
    ];

    const fetchSubscriptionDetail = async () => {
        showLoader();
        if (!user) return;

        try {
            const response = await axios.post(`${awsContextVal?.endpoint}/subscription-plan/list`, {
                status: '1'
            });
            const subscription = response?.data?.subscriptionPlanList || [];
            setSubscriptionData(subscription);

        } catch (err) {
            if (err.response) {
                toast.error(err.response.data?.message);
            } else {
                toast.error("An error occurred while fetching loan applications.");
            }
        } finally {
            hideLoader();
        }
    };
    useEffect(() => {
        fetchSubscriptionDetail();
    }, [user])
    const rows = subscriptionData?.length ? subscriptionData?.map((app) => ({
        id: app?.id,
        plan: app?.plan,
        description: app?.description,
        amount: app?.amount,
        offer: app?.offer,
        status: app?.status,
        facilities: Array.isArray(app?.facility) && app.facility.length > 0 ? app.facility.join(", ") : "",
        createdAt: app.created_at || 'Just now',
    })) : [];

    const handleRowClick = (params) => {
        // console.log("params:", params)
    };

    const validate = () => {
        const newErrors = {};
        if (!formData.plan.trim()) newErrors.plan = 'Plan is required.';
        if (!formData.description.trim()) newErrors.description = 'Description is required.';
        if (!formData.amount.trim()) newErrors.amount = 'Amount is required.';
        if (!formData.offer.trim()) newErrors.offer = 'Offer is required.';
        if (!formData.status.trim()) newErrors.status = 'Status is required.';
        if (formData.facilities.some((facility) => !facility.trim())) {
            newErrors.facilities = 'All facility fields are required.';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {

        showLoader();
        e.preventDefault();
        if (validate()) {
            const submissionData = {
                plan: formData?.plan,
                description: formData?.description,
                amount: formData?.amount,
                offer: formData?.offer,
                facility: JSON.stringify(formData?.facilities),
                status: formData?.status,
            };
            try {
                if(!hidden && hidden == null) {
                    const response = 
                    await axios.post(`${awsContextVal?.endpoint}/subscription-plan/create`, submissionData);
                } else {
                    const response = 
                    await axios.put(`${awsContextVal?.endpoint}/subscription-plan/update/${hidden}`, submissionData);
                }
                fetchSubscriptionDetail();
                setFormData({
                    plan: '',
                    description: '',
                    amount: '',
                    offer: '',
                    facilities: [''],
                    status: '',
                });
                setOpenSubscriptionModal(false);
            } catch (error) {
                console.error("Error during API call:", error);
                if (error.response) {
                    alert(`Error: ${error.response.data.message || 'Something went wrong'}`);
                } else if (error.request) {
                    alert("Error: No response from the server.");
                } else {
                    alert(`Error: ${error.message}`);
                }
            } finally {
                hideLoader();
            }
        } else {
            alert("Please fill in all required fields."); 
            hideLoader();
        }
    };
    return (
        <>
            <div className="tw-w-full tw-h-full tw-my-[12px]">
                <header className="common-font tw-flex tw-justify-between tw-items-center tw-w-full tw-h-[87px]">
                    <div>
                        <div className="tw-w-full tw-h-full tw-my-[12px]">
                            <div className='tw-w-[504px] tw-h-[60px] tw-flex tw-justify-between tw-items-center'>
                                <Link to="/dashboard/admin">
                                    <i className="fas fa-chevron-left tw-w-18.41px tw-h-15.41px tw-text-[#2A2F70]"></i>
                                </Link>
                                <h1 className=' tw-text-[#000000] tw-font-bold tw-text-[40px] common-font'>
                                    Subscription Management
                                </h1>
                            </div>
                            <p className='tw-text-[18px] tw-font-semibold common-font tw-text-[#202224] tw-opacity-70'>Here is the information about all your subscription.</p>

                            {/* <div className="tw-flex tw-space-x-4 tw-mt-4 hidden tw-w-[100%]">
                        <ApplicationTable
                            dataTableProps={{
                                getRowId: (row, index) => `${index}-${row?.application_id}`,
                                userStatus: ["Rejected", 'Loan Granted', "Pending", 'New'],
                                dashboardType: 'application_management'
                            }}
                            rows={rows}
                            columns={columns}
                            onRowClick={handleRowClick}
                            height="400px"
                            customHeaderProps={AllAppplicationHeaderProps}
                            CustomHeaderComponent={AllApplicationHeader}
                        />
                    </div> */}

                        </div>
                    </div>
                    <div className='tw-flex tw-space-x-4'>
                        <Link to="/dashboard/view-subscription" tabIndex={-1}>
                            <button tabIndex={0} className="tw-w-[237.59px] tw-h-[49px] tw-border tw-border-[#2A2F70] tw-rounded-[10px] tw-flex tw-justify-center tw-items-center"
                                onClick={() => handleOpenSubscription()}>
                                <span className="tw-w-[24px] tw-h-[24px] tw-flex tw-items-center tw-justify-center tw-rounded-md tw-border tw-border-[#2A2F70] tw-me-[10px]">
                                    <i className="fas fa-plus-circle tw-text-[16px] tw-text-[#2A2F70]"></i>
                                </span>
                                <h2 className='tw-text-[18px] tw-font-normal common-font tw-text-[#2A2F70]'>
                                    Create Subscription
                                </h2>
                            </button>
                        </Link>
                    </div>
                </header>
                <div className='tw-w-full tw-h-[500px] tw-mt-[20px]'>
                    <ApplicationTable
                        dataTableProps={{
                            getRowId: (row) => row.id, // Use the unique id from your data
                            userStatus: [""],
                            dashboardType: 'subscription',
                            title: "Subscription",
                            getRowClassName: (params) =>
                                params?.row?.id === null ? 'selected-row' : ''
                        }}
                        rows={rows}
                        onRowClick={handleRowClick}
                        columns={columns}
                        tableHeight="400px"
                        maxHeight= '140vh'
                        minHeight= '400px'
                    />
                </div>
            </div>

            <SubscriptionModal
                open={openSubscriptionModal}
                handleClose={handleCloseModal}
                formData={formData}
                setFormData={setFormData}
                handleSubmit={handleSubmit}
                errors={errors}
                setErrors={setErrors}
            />
        </>
    )
}

export default ViewSubscription
