import React, { useState, useEffect, useRef } from 'react';
import { GoogleMap, useLoadScript, MarkerF, Circle } from '@react-google-maps/api';
import MarkerInfoModal from '../dashboards/lender/modal/MarkerInfoModal';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const containerStyle = {
    width: '100%',
    height: '300px',
    borderRadius: '10px',
    marginBottom: '25px'
};

const GoogleAddressMarker = ({ selectedLocation, properyDetails, handleClose, open, handleOpen }) => {

    const [mapCenter, setMapCenter] = useState(null);
    const [isStreetView, setIsStreetView] = useState(false); // Toggle between map and street view
    const streetViewRef = useRef(null);

    useEffect(() => {
        setIsStreetView(false);
        const lat = parseFloat(selectedLocation?.lat);
        const lng = parseFloat(selectedLocation?.lng);

        // Ensure lat and lng are valid numbers
        if (!isNaN(lat) && !isNaN(lng)) {
            setMapCenter({ lat, lng });
        } else {
            setMapCenter(null); // Reset mapCenter if invalid location
        }
    }, [selectedLocation]);

    // Load the Google Maps API script
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY || "AIzaSyBfO515NyW1_dF23Y9ImEC74oCV647PUAw", // Replace with your API key
        libraries: ['places', 'street-view'], // Ensure 'places' library is loaded for StreetView
    });

    useEffect(() => {
        // Initialize street view when it's enabled
        if (isStreetView && mapCenter && streetViewRef.current) {
            const streetView = new window.google.maps.StreetViewPanorama(streetViewRef.current, {
                position: mapCenter,
                pov: { heading: 34, pitch: 10 },
                zoom: 1,
            });

            const map = new window.google.maps.Map(streetViewRef.current, {
                center: mapCenter,
                zoom: 12,
                streetViewControl: false,
            });

            map.setStreetView(streetView);
        }
    }, [isStreetView, mapCenter]); // Reinitialize when mapCenter or street view toggle changes

    // Handle loading and errors
    if (loadError) {
        return <div>Error loading Google Maps: {loadError.message}</div>;
    }
    if (!isLoaded) {
        return <div>Loading...</div>; // Wait until the Google Maps API is loaded
    }
    if (mapCenter === null) {
        return <div>Loading Map...</div>; // Wait for mapCenter to be set
    }

    return (
        <div className='tw-relative'>
            <button
                onClick={() => setIsStreetView(!isStreetView)}
                className='tw-bg-gray-800 tw-p-1 tw-rounded-[2px] tw-absolute tw-top-3 tw-right-16'
                style={{zIndex: 100}}
            >
                 <ArrowBackIcon sx={{ marginRight: '2px', height:"10px", width: "20px" }} /> 
                {isStreetView ? 'View Map' : 'Street View'}
            </button>

            {isStreetView ? (
                <div ref={streetViewRef} style={containerStyle} />
            ) : (
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={mapCenter} // Use mapCenter to center the map
                    zoom={12}
                    key={`${mapCenter.lat}-${mapCenter.lng}-${open}`}
                >
                    <Circle center={mapCenter} radius={500} />
                    <MarkerF position={mapCenter} onClick={handleOpen} />
                </GoogleMap>
            )}

            {/* Open modal on marker click to view property details and street view */}
            <MarkerInfoModal open={open} handleClose={handleClose} properyDetails={properyDetails} mapCenter={mapCenter} />
        </div>
    );
};

export default GoogleAddressMarker;
