import React from 'react';
import { Routes, Route } from 'react-router-dom';

import BaseLayout from './../components/layouts/base/BaseLayout';
import PrivateRoute from './../components/PrivateRoute';
import Borrower from './../components/dashboards/borrower/Borrower';
import Lender from './../components/dashboards/lender/Lender';
import Admin from './../components/dashboards/admin/Admin';
import { ModalProvider } from '../contexts/ModalContexts';
import Notification from '../components/common/notification/Notification';
import Settings from "../components/settings/Settings";
import ViewAllApplication from '../components/dashboards/admin/ViewAllApplication';
import UserManagement from '../components/dashboards/admin/UserManagement';
import ChatWindow from "../components/common/chat-new/Chat"
import ViewSubscription from '../components/dashboards/admin/subscription/ViewSubscription';
import ChangeSubscription from '../components/dashboards/lender/ChangeSubscription';
import PaymentList from '../components/dashboards/lender/PaymentList';
import ContentManagement from '../components/dashboards/admin/cms/ContentManagement';

const Dashboard = () => {
  return (
    <PrivateRoute>
        <ModalProvider>
          <Routes>
            <Route element={<BaseLayout />}>
              <Route path="/borrower" element={<Borrower />} />
              <Route path="/lender" element={<Lender />} />
              <Route path="/admin" element={<Admin />} />
              <Route path="/notifications" element={<Notification />} />
              <Route path="/settings" element={<Settings />}/>
              <Route path="/view-application" element={<ViewAllApplication />}/>
              <Route path="/view-subscription" element={<ViewSubscription />}/>
              <Route path="/user-management" element={<UserManagement />}/>
              <Route path="/chat" element={<ChatWindow />}/>
              <Route path="/change-subscription" element={<ChangeSubscription />}/>
              <Route path="/payment-list" element={<PaymentList />}/>
              <Route path="/cms-pages" element={<ContentManagement />}/>
            </Route>
          </Routes>
        </ModalProvider>
      </PrivateRoute>
  );
};

export default Dashboard;
