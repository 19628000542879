// FormBox.js
import React, { useContext, useEffect, useState, useCallback } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import BoxIcon from "../../../assets/icons/Box_icon.png.png";
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import {
    GetState,
    GetCity,
} from "react-country-state-city";
import { AWSAmplifyConfigContext } from '../../../contexts/AWSAmplifyConfigContext';
import { UserContext } from '../../../contexts/UserContext';
import axios from 'axios';

const FilterForm = ({ filter, setFilter, filterHandler, resetFilterHandler }) => {
    const { user } = useContext(UserContext);
    const awsContextVal = useContext(AWSAmplifyConfigContext);
    const [isOpen, setIsOpen] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(filter?.date_submitted || null);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [propertyTypeList, setPropertyTypeList] = useState([]);
    const [loanPurposeList, setLoanPurposeList] = useState([]);

    const formatPlaceholder = () => {
        if (startDate && endDate) {
            const options = { year: 'numeric', month: 'short', day: '2-digit' };
            return `${startDate.toLocaleDateString('en-US', options)} - ${endDate.toLocaleDateString('en-US', options)}`;
        }
        return "Dec 01, 2023 - Jan 31, 2024";
    };

    //This is to open filter section
    const toggleContainer = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        GetState(233)
            .then((result) => {
                setStateList(result);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const getCities = (stateId) => {
        GetCity(233, stateId)
            .then((result) => {
                // console.log("Fetched Cities:", result);
                setCityList(result);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleStateChange = (e) => {
        const selectedStateVal = Number(e.target.value);
        setFilter((prevFilter) => ({
            ...prevFilter,
            state_id: selectedStateVal
        }));
        getCities(selectedStateVal);
    };

    const handleCityChange = (e) => {
        const selectedCityIndex = e.target.value;
        const city = cityList[selectedCityIndex];
        const cityId = city.id;
        setFilter((prevFilter) => ({
            ...prevFilter,
            city_id: cityId
        }));
    }

    const handleFilterChange = useCallback((e) => {
        const { name, value } = e.target;  // Destructure the name and value from the event target
        setFilter((prevFilter) => ({
            ...prevFilter,
            [name]: value // Dynamically set the key based on the name attribute
        }));
    }, [setFilter]);

    const handleReset = () => {
        resetFilterHandler();
        setStartDate(null);
        setEndDate(null);
    };

    const fetchPropertyTypeList = async () => {
        try {
            const response = await axios.post(`${awsContextVal?.endpoint}/property-type/list`, {
                status: '1',
                sort_by: "type",
                sort_order: "ASC"
            });
            if (response?.data?.propertyTypeList) {
                setPropertyTypeList(response?.data?.propertyTypeList);
            }
        } catch (error) {
            console.error("Error fetching property types:", error);
        }
    };

    const fetchLoanPurposeList = async () => {
        try {
            const response = await axios.post(`${awsContextVal?.endpoint}/loan-purpose/list`, {
                status: '1',
                sort_by: "name",
                sort_order: "ASC"
            });
            if (response?.data?.loadPurposeList) {
                setLoanPurposeList(response?.data?.loadPurposeList);
            }
        } catch (error) {
            console.error("Error fetching Loan Purpose types:", error);
        }
    };
    useEffect(() => {
        fetchPropertyTypeList();
        fetchLoanPurposeList();
    }, [user]);

    return (
        <>
            <div className='tw-w-full tw-border tw-border-[#555FE1] tw-rounded-[10px]'>
                <div className="tw-flex tw-justify-between tw-p-4 tw-border-b-0 tw-cursor-pointer" onClick={toggleContainer}>
                    <h2 className='common-font tw-text-[#444444] tw-text-[22px] tw-mt-[5px] tw-ml-[15px]'>Filters</h2>
                    <button
                        className='tw-ml-2 tw-mt-[5px] tw-text-black'

                    >
                        <i className={`fas fa-chevron-${isOpen ? 'up' : 'down'}`}></i>
                    </button>
                </div>

                {isOpen && (
                    <div className="tw-w-full tw-h-auto tw-px-[24px] tw-py-[24px]">
                        <h2 className='common-font tw-text-[#444444] tw-text-[18px] tw-border-b-[1px] tw-border-[#DDDDDD] tw-pb-[5px]'>Property Details</h2>
                        <div className='tw-flex tw-justify-between tw-space-x-4 tw-mt-4'>
                            <div className='tw-w-[35%]'>
                                <div className='tw-label'>
                                    Property Type
                                </div>
                                <div className='tw-w-full tw-h-[42px]'>
                                    <select className='tw-select'
                                        name="property_type_id"
                                        value={filter?.property_type_id}
                                        onChange={handleFilterChange}
                                    >
                                        <option value="" disabled selected>- Select -</option>
                                        {propertyTypeList?.map((item) => (
                                            <option key={item?.id} value={item?.id}>
                                                {item?.type}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className='tw-w-[35%]'>
                                <div className='tw-label'>
                                    Condition
                                </div>
                                <div className='tw-w-full tw-h-[42px]'>
                                    <select className='tw-select'
                                        name="condition"
                                        value={filter?.condition}
                                        onChange={handleFilterChange}
                                    >
                                        <option value="" disabled selected>- Select -</option>
                                        <option value="all">All</option>
                                        <option value="new">New</option>
                                        <option value="old">Old</option>
                                    </select>
                                </div>
                            </div>
                            <div className='tw-w-[30%]'>
                                <div className='tw-label'>
                                    Property Size (sqft)
                                </div>
                                <div className='tw-h-[42px] tw-flex'>
                                    <div className='tw-property-size tw-flex tw-items-center tw-border tw-border-[#DDDDDD] tw-rounded-[13px] tw-bg-[#f9f9ff] tw-p-2'>
                                        <span className='tw-flex tw-items-center'>
                                            <span className='tw-text-[#888888]'>Min</span>
                                            <span className='tw-text-[#242424] tw-mx-2'>|</span>
                                        </span>
                                        <input
                                            type="text"
                                            name="property_size_min"
                                            className='tw-flex-1 tw-bg-transparent tw-outline-none tw-text-[#202224]'
                                            value={filter?.property_size_min || ''}
                                            onChange={handleFilterChange}
                                        />
                                    </div>

                                    <div className='tw-property-size tw-flex tw-items-center tw-border tw-border-[#DDDDDD] tw-rounded-[13px] tw-bg-[#f9f9ff] tw-p-2 tw-ml-[10px]'>
                                        <span className='tw-flex tw-items-center'>
                                            <span className='tw-text-[#888888]'>Max</span>
                                            <span className='tw-text-[#242424] tw-mx-2'>|</span>
                                        </span>
                                        <input
                                            type="text"
                                            name="property_size_max"
                                            className='tw-flex-1 tw-bg-transparent tw-outline-none tw-text-[#202224]'
                                            value={filter?.property_size_max || ''}
                                            onChange={handleFilterChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='tw-flex tw-space-x-5 tw-mt-5'>
                            <div className='tw-w-[30%]'>
                                <div className='tw-label'>
                                    Property Value ($)
                                </div>
                                <div className='tw-h-[42px] tw-flex'>
                                    {/* Min Input */}
                                    <div className='tw-property-size tw-flex tw-items-center tw-border tw-border-[#DDDDDD] tw-rounded-[13px] tw-bg-[#f9f9ff] tw-p-2'>
                                        <span className='tw-flex tw-items-center'>
                                            <span className='tw-text-[#888888]'>Min</span>
                                            <span className='tw-text-[#242424] tw-mx-2'>|</span>
                                        </span>
                                        <input
                                            type="text"
                                            name="property_value_min"
                                            className='tw-flex-1 tw-bg-transparent tw-outline-none tw-text-[#202224]'
                                            value={filter?.property_value_min || ''}
                                            onChange={handleFilterChange}
                                        />
                                    </div>

                                    {/* Max Input */}
                                    <div className='tw-property-size tw-flex tw-items-center tw-border tw-border-[#DDDDDD] tw-rounded-[13px] tw-bg-[#f9f9ff] tw-p-2 tw-ml-[10px]'>
                                        <span className='tw-flex tw-items-center'>
                                            <span className='tw-text-[#888888]'>Max</span>
                                            <span className='tw-text-[#242424] tw-mx-2'>|</span>
                                        </span>
                                        <input
                                            type="text"
                                            name="property_value_max"
                                            className='tw-flex-1 tw-bg-transparent tw-outline-none tw-text-[#202224]'
                                            value={filter?.property_value_max || ''}
                                            onChange={handleFilterChange}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='tw-w-[30%]'>
                                <div className='tw-label'>
                                    Appraised Value ($)
                                </div>
                                <div className='tw-h-[42px] tw-flex'>
                                    {/* Min Input */}
                                    <div className='tw-property-size tw-flex tw-items-center tw-border tw-border-[#DDDDDD] tw-rounded-[13px] tw-bg-[#f9f9ff] tw-p-2'>
                                        <span className='tw-flex tw-items-center'>
                                            <span className='tw-text-[#888888]'>Min</span>
                                            <span className='tw-text-[#242424] tw-mx-2'>|</span>
                                        </span>
                                        <input
                                            type="text"
                                            name="appraised_value_min"
                                            className='tw-flex-1 tw-bg-transparent tw-outline-none tw-text-[#202224]'
                                            value={filter?.appraised_value_min || ''}
                                            onChange={handleFilterChange}
                                        />
                                    </div>

                                    {/* Max Input */}
                                    <div className='tw-property-size tw-flex tw-items-center tw-border tw-border-[#DDDDDD] tw-rounded-[13px] tw-bg-[#f9f9ff] tw-p-2 tw-ml-[10px]'>
                                        <span className='tw-flex tw-items-center'>
                                            <span className='tw-text-[#888888]'>Max</span>
                                            <span className='tw-text-[#242424] tw-mx-2'>|</span>
                                        </span>
                                        <input
                                            type="text"
                                            name="appraised_value_max"
                                            className='tw-flex-1 tw-bg-transparent tw-outline-none tw-text-[#202224]'
                                            value={filter?.appraised_value_max || ''}
                                            onChange={handleFilterChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h2 className='common-font tw-text-[#444444] tw-text-[18px] tw-border-b-[1px] tw-border-[#DDDDDD] tw-mt-[40px] tw-pb-[5px]'>Application Status</h2>
                        <div className='tw-flex tw-space-x-5 tw-mt-5'>
                            <div className='tw-w-[25%]'>
                                <div className='tw-label'>
                                    Status
                                </div>
                                <div className='tw-h-[42px] tw-flex tw-flex-wrap tw-gap-x-2 tw-items-center'>
                                    <div tabIndex={0}>
                                        <input
                                            type="radio"
                                            id="new-application"
                                            name="application-status" // Ensure radios are grouped
                                            className='tw-mr-2 tw-border tw-border-[#2A2F70]'
                                            value="new_application"
                                            checked={filter.application_status === 'new_application'}
                                            onClick={handleFilterChange}
                                            tabIndex={-1}
                                        // onClick={(e) => {
                                        //     const selectedValue = e.target.value;
                                        //     setFilter((prevFilter) => ({
                                        //         ...prevFilter,
                                        //         application_status: selectedValue
                                        //     }));
                                        // }}
                                        />
                                        <label htmlFor="new-application" className='tw-text-normal tw-text-[16px] common-font tw-text-[#202224]'>
                                            New Application
                                        </label>
                                    </div>

                                    <div tabIndex={0}>
                                        <input
                                            type="radio"
                                            id="reviewed-application"
                                            name="application-status" // Ensure radios are grouped
                                            className='tw-mr-2'
                                            value="reviewed_application"
                                            checked={filter.application_status === 'reviewed_application'}
                                            onClick={handleFilterChange}
                                            tabIndex={-1}
                                        // onClick={(e) => {
                                        //     const selectedValue = e.target.value;
                                        //     setFilter((prevFilter) => ({
                                        //         ...prevFilter,
                                        //         application_status: selectedValue
                                        //     }));
                                        // }}
                                        />
                                        <label htmlFor="reviewed-application" className='tw-text-normal common-font tw-text-[#202224]'>
                                            Reviewed Application
                                        </label>
                                    </div>
                                </div>
                            </div>

                            {/* Date Picker Section */}
                            <div className='tw-w-[23%]'>
                                <div className='tw-label'>
                                    Date Submitted
                                </div>
                                <div className=' tw-h-[42px] tw-border tw-border-[#DDDDDD] tw-rounded-[13px] tw-p-2 tw-text-[#202224] tw-bg-[#f9f9ff]'>
                                    <DatePicker
                                        selected={endDate}
                                        onChange={(date) => {
                                            setEndDate(date);
                                            setFilter((prevFilter) => ({
                                                ...prevFilter,
                                                date_submitted: date ? date.toISOString() : '',
                                            }));
                                        }}
                                        selectsStart
                                        selectsEnd
                                        startDate={startDate}
                                        className='tw-w-full tw-h-full tw-bg-transparent outline-none'
                                        placeholderText={formatPlaceholder()}
                                        dateFormat="MMM dd, yyyy"
                                    />
                                </div>
                            </div>
                        </div>

                        <h2 className='common-font tw-text-[#444444] tw-text-[18px] tw-border-b-[1px] tw-border-[#DDDDDD] tw-mt-[40px] tw-pb-[5px]'>Location Details</h2>
                        <div className='tw-flex tw-space-x-4 tw-mt-4'>
                            <div className='tw-w-[25%]'>
                                <div className='tw-label'>
                                    State
                                </div>
                                <div className='tw-w-full tw-h-[42px]'>
                                    <select className='tw-select'
                                        value={filter?.state_id}
                                        onChange={handleStateChange}
                                    >
                                        <option value="" disabled >- Select -</option>
                                        {stateList.map((item, index) => (
                                            <option key={index} value={item?.id}
                                            >
                                                {item.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className='tw-w-[25%]'>
                                <div className='tw-label'>
                                    City
                                </div>
                                <div className='tw-w-full tw-h-[42px]'>
                                    <select className='tw-select'
                                        onChange={handleCityChange}
                                    >
                                        <option value="" disabled selected>- Select -</option>
                                        {cityList.map((item, index) => (
                                            <option key={index} value={index}>
                                                {item.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className='tw-w-[25%]'>
                                <div className='tw-label'>
                                    Zip Code
                                </div>
                                <div className='tw-w-full tw-h-[42px]'>
                                    <input
                                        type="text"
                                        name="postal_code"
                                        placeholder="Zip Code"
                                        className='tw-select'
                                        value={filter?.postal_code}
                                        onChange={handleFilterChange}
                                    />
                                </div>
                            </div>
                            <div className='tw-w-[25%]'>
                                <div className='tw-label'>
                                    Proximity
                                </div>
                                <div className='tw-w-full tw-h-[42px]'>
                                    <select className='tw-select'
                                        value={filter?.proximity}
                                        name="proximity"
                                        onChange={handleFilterChange}
                                    >
                                        <option value="" disabled selected>- Select -</option>
                                        <option value="10">10Km</option>
                                        <option value="50">50Km</option>
                                        <option value="100">100Km</option>
                                        <option value="150">150Km</option>
                                    </select>
                                </div>
                            </div>

                        </div>

                        <h2 className='common-font tw-text-[#444444] tw-text-[18px] tw-border-b-[1px] tw-border-[#DDDDDD] tw-mt-[40px] tw-pb-[5px]'>Borrower and Loan Details</h2>
                        <div className='tw-flex tw-space-x-4 tw-mt-4'>
                            <div className='tw-w-[22%]'>
                                <div className='tw-label'>
                                    Loan Purpose
                                </div>
                                <div className='tw-w-full tw-h-[42px]'>
                                    <select className='tw-select'
                                        value={filter?.loan_purpose_id}
                                        name="loan_purpose_id"
                                        onChange={handleFilterChange}
                                    >
                                        <option value="" disabled selected>- Select -</option>
                                        {loanPurposeList?.map((item) => (
                                            <option key={item?.id} value={item?.id}>
                                                {item?.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className='tw-w-[22%]'>
                                <div className='tw-label'>
                                    Loan Term
                                </div>
                                <div className='tw-w-full tw-h-[42px]'>
                                    <select className='tw-select'
                                        value={filter?.loan_term}
                                        name="loan_term"
                                        onChange={handleFilterChange}
                                    >
                                        <option value="" disabled selected>- Select -</option>
                                        <option value="5">5 Years</option>
                                        <option value="10">10 Years</option>
                                        <option value="15">15 Years</option>
                                        <option value="20">20 Years</option>
                                    </select>
                                </div>
                            </div>
                            <div className='tw-w-[22%]'>
                                <div className='tw-label'>
                                    LTV %
                                </div>
                                {/* <div className='tw-w-full tw-h-[42px]'>
                                    <select className='tw-select'
                                        value={filter?.ltv}
                                        name="ltv"
                                        onChange={handleFilterChange}
                                    >
                                        <option value="" disabled selected>- Select -</option>
                                        <option value="10">10%</option>
                                        <option value="30">30%</option>
                                        <option value="60">60%</option>
                                        <option value="90">90%</option>
                                    </select>
                                </div> */}
                                <div className="tw-w-full tw-h-[42px] tw-flex tw-items-center">
                                    <Box
                                        sx={{ width: '80%' }}
                                        className="tw-h-full tw-flex tw-items-center tw-pr-2"
                                    >
                                        <Slider
                                            value={filter?.ltv || 0}
                                            onChange={(event, newValue) => {
                                                handleFilterChange({ target: { name: 'ltv', value: newValue } });
                                            }}
                                            aria-label="LTV Slider"
                                            valueLabelDisplay="auto"
                                            min={0}
                                            max={100}
                                            step={1}
                                            sx={{
                                                width: '100%',
                                            }}
                                        />
                                    </Box>

                                    <input
                                        type="number"
                                        className="tw-w-[20%] tw-text-center tw-h-[42px] tw-border-[1px] tw-border-[#DDDDDD] tw-rounded-[5px] tw-text-[#242424]"
                                        value={filter?.ltv || 0}
                                        onChange={(event) => {
                                            const inputValue = event.target.value === '' ? '' : Number(event.target.value);
                                            if (inputValue >= 0 && inputValue <= 100) {
                                                handleFilterChange({ target: { name: 'ltv', value: inputValue } });
                                            }
                                        }}
                                        placeholder="LTV"
                                    />
                                </div>
                            </div>

                            <div className='tw-w-[20%]'>
                                <div className='tw-label'>
                                    Property Value ($)
                                </div>
                                <div className='tw-h-[42px] tw-flex'>
                                    {/* Min Input */}
                                    <div className='tw-property-size tw-flex tw-items-center tw-border tw-border-[#DDDDDD] tw-rounded-[13px] tw-bg-[#f9f9ff] tw-p-2'>
                                        <span className='tw-flex tw-items-center'>
                                            <span className='tw-text-[#888888]'>Min</span>
                                            <span className='tw-text-[#242424] tw-mx-2'>|</span>
                                        </span>
                                        <input
                                            type="text"
                                            name="property_value_min"
                                            className='tw-flex-1 tw-bg-transparent tw-outline-none tw-text-[#202224]'
                                            value={filter?.property_value_min || ''}
                                            onChange={handleFilterChange}
                                        />
                                    </div>

                                    {/* Max Input */}
                                    <div className='tw-property-size tw-flex tw-items-center tw-border tw-border-[#DDDDDD] tw-rounded-[13px] tw-bg-[#f9f9ff] tw-p-2 tw-ml-[10px]'>
                                        <span className='tw-flex tw-items-center'>
                                            <span className='tw-text-[#888888]'>Max</span>
                                            <span className='tw-text-[#242424] tw-mx-2'>|</span>
                                        </span>
                                        <input
                                            type="text"
                                            name="property_value_max"
                                            className='tw-flex-1 tw-bg-transparent tw-outline-none tw-text-[#202224]'
                                            value={filter?.property_value_max || ''}
                                            onChange={handleFilterChange}
                                        />
                                    </div>
                                </div>

                            </div>
                            <div className='tw-w-[14%]'>
                                <div className='tw-label'>
                                    Intrest rate preference
                                </div>
                                <div className='tw-h-[42px] tw-flex tw-flex-wrap tw-gap-2 tw-items-center'>
                                    <div tabIndex={0}>
                                        <input
                                            type="radio"
                                            id="fixed_pref"
                                            name="intrest_rate_preference"
                                            className='tw-mr-2 tw-border tw-border-[#2A2F70]'
                                            value="fixed"
                                            checked={filter.interest_rate_type === 'fixed'}
                                            onChange={handleFilterChange}
                                            tabIndex={-1}
                                        />
                                        <label htmlFor="fixed_pref" className='tw-text-normal tw-text-[16px] common-font tw-text-[#202224]'>
                                            Fixed
                                        </label>
                                    </div>

                                    <div tabIndex={0}>
                                        <input
                                            type="radio"
                                            id="variable_pref"
                                            name="intrest_rate_preference"
                                            className='tw-mr-2'
                                            value="variable"
                                            checked={filter.interest_rate_type === 'variable'}
                                            onChange={handleFilterChange}
                                            tabIndex={-1}
                                        />
                                        <label htmlFor="variable_pref" className='tw-text-normal common-font tw-text-[#202224]'>
                                            Variable
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='tw-flex tw-space-x-4 tw-mt-4'>
                            <div className='tw-w-[20%]'>
                                <div className='tw-label'>
                                    Status
                                </div>
                                <div className='tw-h-[42px] tw-flex tw-flex-wrap tw-gap-x-2'>
                                    <div tabIndex={0}>
                                        <input
                                            type="radio"
                                            id="first_time_borrower"
                                            name="application-status-borrower"
                                            className='tw-mr-2 tw-border tw-border-[#2A2F70]'
                                            value="first"
                                            checked={filter?.borrower_type === 'first'}
                                            onChange={(e) => {
                                                const selectedValue = e.target.value;
                                                setFilter((prevFilter) => ({
                                                    ...prevFilter,
                                                    borrower_type: selectedValue
                                                }));
                                            }}
                                            tabIndex={-1}
                                        />
                                        <label htmlFor="first_time_borrower" className='tw-text-normal tw-text-[16px] common-font tw-text-[#202224]'>
                                            First Time Borrower
                                        </label>
                                    </div>

                                    <div tabIndex={0}>
                                        <input
                                            type="radio"
                                            id="repeat_time_borrower"
                                            name="application-status-borrower"
                                            className='tw-mr-2'
                                            value="repeat"
                                            checked={filter?.borrower_type === 'repeat'}
                                            onChange={(e) => {
                                                const selectedValue = e.target.value;
                                                setFilter((prevFilter) => ({
                                                    ...prevFilter,
                                                    borrower_type: selectedValue
                                                }));
                                            }}
                                            tabIndex={-1}
                                        />
                                        <label htmlFor="repeat_time_borrower" className='tw-text-normal common-font tw-text-[#202224]'>
                                            Repeat Borrower
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className='tw-w-[45%]'>
                                <div className='tw-label'>
                                    Borrower Existing Debt ($)
                                </div>
                                <div className='tw-h-[42px] tw-flex'>
                                    {/* Min Input */}
                                    <div className='tw-property-size tw-flex tw-items-center tw-border tw-border-[#DDDDDD] tw-rounded-[13px] tw-bg-[#f9f9ff] tw-p-2'>
                                        <span className='tw-flex tw-items-center'>
                                            <span className='tw-text-[#888888]'>Min</span>
                                            <span className='tw-text-[#242424] tw-mx-2'>|</span>
                                        </span>
                                        <input
                                            type="text"
                                            name="borrower_existing_dept_min"
                                            className='tw-flex-1 tw-bg-transparent tw-outline-none tw-text-[#202224]'
                                            value={filter?.borrower_existing_dept_min || ''}
                                            onChange={handleFilterChange}
                                        />
                                    </div>

                                    {/* Max Input */}
                                    <div className='tw-property-size tw-flex tw-items-center tw-border tw-border-[#DDDDDD] tw-rounded-[13px] tw-bg-[#f9f9ff] tw-p-2 tw-ml-[10px]'>
                                        <span className='tw-flex tw-items-center'>
                                            <span className='tw-text-[#888888]'>Max</span>
                                            <span className='tw-text-[#242424] tw-mx-2'>|</span>
                                        </span>
                                        <input
                                            type="text"
                                            name="borrower_existing_dept_max"
                                            className='tw-flex-1 tw-bg-transparent tw-outline-none tw-text-[#202224]'
                                            value={filter?.borrower_existing_dept_max || ''}
                                            onChange={handleFilterChange}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='tw-w-[45%]'>
                                <div className='tw-label'>
                                    Borrower Credit Score
                                </div>
                                <div className='tw-h-[42px] tw-flex'>
                                    {/* Min Input */}
                                    <div className='tw-property-size tw-flex tw-items-center tw-border tw-border-[#DDDDDD] tw-rounded-[13px] tw-bg-[#f9f9ff] tw-p-2'>
                                        <span className='tw-flex tw-items-center'>
                                            <span className='tw-text-[#888888]'>Min</span>
                                            <span className='tw-text-[#242424] tw-mx-2'>|</span>
                                        </span>
                                        <input
                                            type="text"
                                            name="borrower_credit_score_min"
                                            className='tw-flex-1 tw-bg-transparent tw-outline-none tw-text-[#202224]'
                                            value={filter?.borrower_credit_score_min || ''}
                                            onChange={handleFilterChange}
                                        />
                                    </div>

                                    {/* Max Input */}
                                    <div className='tw-property-size tw-flex tw-items-center tw-border tw-border-[#DDDDDD] tw-rounded-[13px] tw-bg-[#f9f9ff] tw-p-2 tw-ml-[10px]'>
                                        <span className='tw-flex tw-items-center'>
                                            <span className='tw-text-[#888888]'>Max</span>
                                            <span className='tw-text-[#242424] tw-mx-2'>|</span>
                                        </span>
                                        <input
                                            type="text"
                                            name="borrower_credit_score_max"
                                            className='tw-flex-1 tw-bg-transparent tw-outline-none tw-text-[#202224]'
                                            value={filter?.borrower_credit_score_max || ''}
                                            onChange={handleFilterChange}
                                        />
                                    </div>
                                </div>
                            </div>



                        </div>

                        {/* <div className='tw-h-[49px] tw-my-5'>
                            <button className='tw-w-full tw-h-[49px] tw-border tw-text-[#2A2F70] tw-font-semibold tw-border-[#DDDDDD] tw-rounded-[5px] tw-bg-[#7DDE92] text-center'>Get A Quote</button>
                        </div> */}
                        <div className='tw-flex tw-justify-end'>
                            <div className='tw-w-[362px] tw-h-[58px] tw-mt-[40px] tw-flex'>
                                <div tabIndex={0} className='tw-w-[138px] tw-h-[49px]  tw-m-1 tw-flex tw-justify-center tw-items-center tw-cursor-pointer tw-mr-4'
                                    onClick={handleReset}>
                                    <i className="tw-text-white fas fa-redo tw-me-[10px] tw-filter-icons-bg"></i>
                                    <span className='tw-text-[#2A2F70] tw-text-[18px] tw-font-semibold common-font'>
                                        Reset All
                                    </span>
                                </div>
                                <div tabIndex={0} className='tw-w-[200px] tw-h-[58px] tw-rounded-[10px] tw-bg-[#7DDE92] tw-flex tw-justify-center tw-items-center tw-cursor-pointer'
                                    onClick={filterHandler}>
                                    <div className='tw-bg-[#7DDE92] tw-flex tw-justify-center tw-items-center'>
                                        <i className="fas fa-chevron-right tw-me-[10px] tw-filter-icons-bg"></i>
                                        <button tabIndex={-1} className='tw-text-[#2A2F70] tw-text-[18px] tw-font-semibold common-font'>Apply Filters</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default FilterForm;
