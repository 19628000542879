import React, { useContext, useEffect, useState } from 'react';
import OriginationFeeGraph from '../../common/graphs/OriginationFeeGraph';
import AnnualInterestGraph from '../../common/graphs/AnnualInterestGraph';
import LengthOfLoanGraph from '../../common/graphs/LengthOfLoanGraph';
import { UserContext } from '../../../contexts/UserContext';
import { AWSAmplifyConfigContext } from '../../../contexts/AWSAmplifyConfigContext';
import { fetchGraphData } from '../../../utils/services/apiService';

function AssetGenerated() {
    const { user } = useContext(UserContext);
    const awsContextVal = useContext(AWSAmplifyConfigContext);

    const [graphData, setGraphData] = useState({
        originationFeeData: [],
        annualInterestData: [],
        lengthOfLoanData: [],
    });

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // Fetch Data for All Graphs
    const fetchAllGraphsData = async () => {
        setLoading(true);
        setError(null);
        try {
            const [originationResponse, annualResponse, loanResponse] = await Promise.all([
                fetchGraphData(`${awsContextVal?.endpoint}/dashboard/origination-fee`, { userId: user?.id }),
                fetchGraphData(`${awsContextVal?.endpoint}/dashboard/annual-interest`, { userId: user?.id }),
                fetchGraphData(`${awsContextVal?.endpoint}/dashboard/length-of-loan`, { userId: user?.id }),
            ]);

            setGraphData({
                originationFeeData: [
                    {
                        label: 'Origination Fee',
                        data: originationResponse?.originationData?.length
                            ? originationResponse.originationData.map(item => ({
                                x: `$${item?.fee_value}`,
                                y: item?.fee_count,
                            }))
                            : [],
                    },
                ],
                annualInterestData: [
                    {
                        label: 'Annual Interest',
                        data: annualResponse?.interestData?.length
                            ? annualResponse.interestData.map(item => ({
                                x: `${item?.interest_value}`,
                                y: item?.interest_count,
                            }))
                            : [],
                    },
                ],
                lengthOfLoanData: [
                    {
                        label: 'Length of Loan',
                        data: loanResponse?.loanData?.length
                            ? loanResponse.loanData.map(item => ({
                                x: `${item?.loan_duration}`,
                                y: item?.loan_count,
                            }))
                            : [],
                    },
                ],
            });
        } catch (err) {
            setError('Failed to load graph data. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (user) {
            fetchAllGraphsData();
        }
    }, [user]);

    if (loading) {
        return <div>Loading all graphs...</div>;
    }

    if (error) {
        return <div className="error-message">{error}</div>;
    }

    return (
        <div className="tw-flex tw-flex-col tw-space-y-3 tw-ms-[16px]">
            <OriginationFeeGraph data={graphData.originationFeeData} loading={loading} />
            <AnnualInterestGraph data={graphData.annualInterestData} loading={loading} />
            <LengthOfLoanGraph data={graphData.lengthOfLoanData} loading={loading} />
        </div>
    );
}

export default AssetGenerated;
