import { useState, useEffect, useContext } from "react";
import Header from "./layouts/homepage/Header";
import HomeInfoArea from "./layouts/homepage/HomeInfoArea";
import TrustedPartnersArea from "./layouts/homepage/TrustedPartnersArea";
import AdvantagesArea from "./layouts/homepage/AdvantagesArea";
import { UserContext } from "../contexts/UserContext";
import LoginModal from "./modals/LoginModal";
import SignupModal from "./modals/SignupModal";
import ValidationModal from "./modals/ValidationModal";
import ProductArea from "./layouts/homepage/ProductArea";
import TestimonialsArea from "./layouts/homepage/TestimonialsArea";
import LoanCalculatorArea from "./layouts/homepage/LoanCalculatorArea";
import Footer from "./layouts/homepage/Footer";
import { authLocal } from "../utils/common";
import GenericButton from "./common/buttons/GenericButton";
import BorrowerSignupIcon from "../assets/icons/borrowerIcon.svg";
import LenderSignupIcon from "../assets/icons/lenderIcon.svg";
import { Link } from "react-router-dom";

function Home() {

    const { user, userType, setUserType } = useContext(UserContext);
    // const [userType, setUserType] = useState("borrower");
    // const { userType, setUserType } = useUserType();

    const [showSignupFormModal, setShowSignupFormModal] = useState(false);
    const [showSigninFormModal, setShowSigninFormModal] = useState(false);
    const [showValidationModal, setShowValidationModal] = useState(false);
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
    const closeSigninFormModal = () => setShowSigninFormModal(false);

    const closeSignupFormModal = (success = false) => {
        setShowSignupFormModal(false)

        if (success) {
            setShowValidationModal(true);
        }
    };

    const closeValidationModal = (success = false) => {
        setShowValidationModal(false);
        if (success) {
            setShowSigninFormModal(true);
        }
    };

    useEffect(() => {
        console.log(user);
    }, [user])

    useEffect(() => {
        const userData = authLocal.get();
        setIsUserLoggedIn(userData && userData.email ? true : false);
    }, []);

    return (
        <div className="tw-relative">
            <Header isUserLoggedIn={isUserLoggedIn} />

            <div className="tw-hidden lg:tw-flex tw-mx-20 tw-mt-20 tw-mt-[80px]">
                <HomeInfoArea isUserLoggedIn={isUserLoggedIn} />
            </div>

            <div className="tw-hidden lg:tw-flex tw-py-10">
                <TrustedPartnersArea />
            </div>

            <div className="tw-hidden lg:tw-flex tw-my-10">
                <AdvantagesArea openSignupBorrowerModal={setShowSignupFormModal} />
            </div>

            <div className="tw-hidden lg:tw-flex tw-py-10">
                <LoanCalculatorArea openSignupBorrowerModal={setShowSignupFormModal} />
            </div>

            <div className="tw-hidden lg:tw-flex tw-py-10">
                <ProductArea isUserLoggedIn={isUserLoggedIn} />
            </div>

            <div className="tw-hidden lg:tw-flex tw-py-10">
                <TrustedPartnersArea />
            </div>

            <div className="tw-hidden lg:tw-flex tw-mx-10">
                <TestimonialsArea />
            </div>

            <div className="tw-hidden lg:tw-flex tw-mx-10 tw-mb-10 tw-items-center tw-divide-x-2">
                <div className="tw-flex-1 tw-justify-center tw-flex-col tw-space-y-4 tw-p-2">
                    <p className="tw-h-[100px] tw-font-inter tw-text-black tw-text-center tw-mr-2">Need a bridge loan ASAP and no time to shop around for the best terms? We give you flexible loan options from multiple lenders to choose from. Now you can get the better deal without stress, even when you’re in a time crunch. Applying now will only take a few minutes.</p>
                    <div className="tw-flex tw-justify-center tw-h-12" 
                        onClick={() => setUserType('borrower')}>
                        <Link to={"/sign-up/borrower"} tabIndex={-1}>
                            <GenericButton title="Signup As Borrower" icon={BorrowerSignupIcon} />
                        </Link>

                    </div>
                </div>

                <div className="tw-flex-1 tw-justify-center tw-flex-col tw-space-y-4 tw-p-2">
                    <p className="tw-h-[100px] tw-font-inter tw-text-center tw-text-black tw-mr-2">We’re here to help you.  Bridge loans can take you from property and financing issues to a better, more secure future. Let’s cross that bridge together today.</p>
                    <div className="tw-flex tw-justify-center tw-h-12"
                        onClick={() => setUserType('lender')}>
                        <Link to={"/sign-up/lender"} tabIndex={-1}>
                            <GenericButton title="Signup As Lender" icon={LenderSignupIcon} />
                        </Link>
                    </div>
                </div>
            </div>

            
            <div className="tw-flex lg:tw-hidden tw-justify-center tw-items-center tw-h-[100vh]">
                <h1 className="tw-text-3xl tw-font-oswald tw-text-[#4853e4]">COMING SOON ON MOBILE</h1>
            </div>
            
            <Footer />

            {/* Render the SignUp Form Modal */}
            <SignupModal
                role={''}
                isOpen={showSignupFormModal}
                onClose={closeSignupFormModal}
            />

            {/* Render the SignIn Form Modal */}
            <LoginModal
                isOpen={showSigninFormModal}
                onClose={closeSigninFormModal}
            />

            {/* Render the Validation Modal */}
            <ValidationModal isOpen={showValidationModal} onClose={closeValidationModal} />
        </div>
    );
}

export default Home;