import React, { useEffect, useState, useContext, useRef } from 'react';
import ApplicationTable from '../../common/applicationTable/ApplicationTable'
import FilterForm from './FilterForm'
import StatsCards from '../../common/StatsCards';
import Chat from '../../common/chat-new/Chat';
import AssetGenerated from './AssetGenerated';
import AreaInterest from './AreaInterest';
import { UserContext } from '../../../contexts/UserContext';
import { AWSAmplifyConfigContext } from '../../../contexts/AWSAmplifyConfigContext';
import axios from 'axios';
import toast from 'react-hot-toast';
import PropertyDetail from './PropertyDetail';
import StatsDropdown from '../../common/StatsDropdown';
import SendOffers from './modal/SendOffers';
import GoogleAddressMarker from '../../common/GoogleAddressMarker';
import { formatTimeDifference, formatPrice, getApplicationStatus } from '../../../utils/common';
import LenderHeader from './LenderHeader';

import { useLoader } from '../../../contexts/LoaderContext';
const initialFilterState = {
    user_id: '',
    search_text: '',
    loan_purpose_id: '',
    condition: '',
    property_type_id: '',
    property_size_min: '',
    property_size_max: '',
    property_value_min: '',
    property_value_max: '',
    appraised_value_min: '',
    appraised_value_max: '',
    application_status: '',
    date_submitted: '',
    city_id: '',
    state_id: '',
    postal_code: '',
    proximity: '',
    loan_purpose_id: '',
    loan_term: '',
    ltv: '',
    interest_rate_type: '',
    borrower_type: '',
    borrower_existing_dept_min: '',
    borrower_existing_dept_max: '',
    borrower_credit_score_min: '',
    borrower_credit_score_max: '',
    sort_by: 'a.id',
    sort_order: 'DESC',
    lat: '37.7749',
    lng: '-122.4194'
};

function Lender() {
    const { user } = useContext(UserContext);
    const { setCurrentOfferPropertyDetails } = useContext(UserContext);
    const awsContextVal = useContext(AWSAmplifyConfigContext);

    const [openSendOfferModal, setOpenSendOfferModal] = useState(false);
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [applicationId, setApplicationId] = useState(null);
    const [userAllloanApplications, setUserAllloanApplications] = useState([]);
    const [lenderApplicationsSummary, setLenderApplicationsSummary] = useState([]);
    const [propertyDetails, setPropertyDetails] = useState({});
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [selectedRowBorrowerId, setSelectedBorrowerId] = useState(0);
    const [conversationList, setConversationList] = useState([]);
    const [selectedRowStatus, setSelectedRowStatus] = useState('');
    const [formattedTimes, setFormattedTimes] = useState([]);
    const [filter, setFilter] = useState(initialFilterState);
    const [isFilter, setIsFilter] = useState(false);
    const [chatWindowRender, setChatWindowRender] = useState(false);
    const { showLoader, hideLoader } = useLoader();
    const [propertyDetailHeight, setPropertyDetailHeight] = useState(0);
    const propertyDetailRef = useRef(null);
    const [stats, setStats] = useState([
        { title: 'Total Applications', value: '40,689', change: '8.5%' },
        { title: 'Accepted Applications', value: '40,689', change: '8.5%' },
        { title: 'Pending Applications', value: '40,689', change: '8.5%' },
        { title: 'Rejected Applications', value: '40,689', change: '8.5%' },
        { title: 'Total Amount Lent', value: '40,689', change: '8.5%' },
        { title: 'Loan Acceptance Rate', value: '120%', change: '8.5%' },
    ]);

    const handleCloseModal = () => setOpenSendOfferModal(false);

    //This is to get Borrower Dashboard Summary
    const fetchLenderLoanSummary = async () => {
        if (!user) return;

        try {
            const response = await axios.post(`${awsContextVal?.endpoint}/getLenderDashboardSummary`, { userId: user?.id });
            setLenderApplicationsSummary(response?.data);
        } catch (err) {
            if (err.response) {
                toast.error(err.response.data?.message);
            } else {
                toast.error("An error occurred while fetching loan applications.");
            }
        }
    };

    const updatePropertyDetails = (property) => {
        setCurrentOfferPropertyDetails(property);
        setPropertyDetails(property);
        setApplicationId(property?.application_id);
        // handleRowClick(property);
    }

    const fetchAllUserLoanApplications = async () => {
        if (!user) return;

        try {
            const response = await axios.post(`${awsContextVal?.endpoint}/application/lender/list`, filter);
            const applications = response?.data?.applications || [];
            setUserAllloanApplications(applications);
        } catch (err) {
            if (err.response) {
                toast.error(err.response.data?.message);
            } else {
                toast.error("An error occurred while fetching loan applications.");
            }
        }
    };
    // console.log("selectedRowId:", selectedRowId)
    useEffect(() => {
        // Set up an interval to call it every 30 seconds
        const intervalId = setInterval(() => {
            fetchAllUserLoanApplications();
        }, 30000);

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    }, [user]);

    const filterHandler = () => {
        setIsFilter(!isFilter);
    };
    const resetFilterHandler = () => {
        setFilter(initialFilterState);
        setIsFilter(!isFilter);
    }

    const handleSortChange = (type) => {
        let newSortOrder = '';

        if (type === 'oldFirst') {
            newSortOrder = 'ASC';  // Set sort order to ASC for "Old First"
        } else if (type === 'newFirst') {
            newSortOrder = 'DESC'; // Set sort order to DESC for "New First"
        }

        setFilter((prevFilters) => ({
            ...prevFilters,
            sort_order: newSortOrder,
        }));
        setIsFilter(!isFilter);
    };

    useEffect(() => {
        fetchAllUserLoanApplications();
        fetchLenderLoanSummary()
    }, [user, isFilter]);

    useEffect(() => {
        const stats = [
            { title: 'Total Applications', value: lenderApplicationsSummary?.total_applications, change: '8.5%' },
            { title: 'Accepted Applications', value: lenderApplicationsSummary?.accepted_applications, change: '8.5%' },
            { title: 'Pending Applications', value: lenderApplicationsSummary?.pending_applications, change: '8.5%' },
            { title: 'Rejected Applications', value: lenderApplicationsSummary?.rejected_applications, change: '8.5%' },
            { title: 'Total Amount Lent', value: '40,689', change: '8.5%' },
            { title: 'Loan Acceptance Rate', value: '120%', change: '8.5%' },
        ];
        setStats(stats)
    }, [lenderApplicationsSummary]);

    // Update every 60 seconds
    // useEffect(() => {
    //     const intervalId = setInterval(() => {
    //         setFormattedTimes((prevTimes) => {
    //             return userAllloanApplications.map((app) => ({
    //                 application_id: app.application_id,
    //                 formattedTime: formatTimeDifference(app.application_created_at),
    //             }));
    //         });
    //     }, 20000); // Update every 60 seconds

    //     return () => clearInterval(intervalId); // Clean up the interval on component unmount
    // }, [userAllloanApplications]);


    const columns = [
        // { field: 'application_id', headerName: 'ID', width: 90, editable: true },
        {
            field: 'streetAddress',
            headerName: 'Address',
            width: 150,
            editable: true,
            sortable: true,
            renderCell: (params) => {
                const { value, row } = params;
                const createdAt = new Date(row.createdAt);
                const currentTime = new Date();
                const isNew = (currentTime - createdAt) / (1000 * 60 * 60) <= 1;

                return (
                    <div className="tw-flex tw-items-center tw-gap-2">
                        {isNew && (
                            <span className="tw-bg-red-500 tw-text-white tw-text-xs tw-py-1 tw-px-2 tw-rounded-full">
                                NEW
                            </span>
                        )}
                        <span>{value}</span>
                    </div>
                );
            },
        },
        {
            field: 'loanAmount',
            headerName: 'Loan Amount',
            type: 'number',
            width: 100,
            editable: true,
            sortable: true
        },
        {
            field: 'propertyValue',
            headerName: 'Property Value',
            type: 'number',
            width: 110,
            editable: true,
            sortable: true
        },
        {
            field: 'propertyType',
            headerName: 'Property Type',
            type: 'text',
            minWidth: 120,
            flex: 0.8,
            editable: false,
        },
        {
            field: 'purpose',
            headerName: 'Loan Purpose',
            width: 110,
            editable: true,
            type: 'text',
            align: 'left'
        },
        {
            field: 'ltv',
            headerName: 'LTV',
            type: 'text',
            width: 90,
            editable: true,
        },
        {
            field: 'borrower',
            headerName: 'Borrower',
            type: 'text',
            width: 120,
            editable: true,
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 158,
            type: 'text',
            renderCell: (params) => {
                const statusId = params?.value; // Numeric status ID
                const { render } = getApplicationStatus(statusId, user?.user_type);
                return render();
            },
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            width: 120,
            editable: true,
            renderCell: (params) => {
                const formattedTime = formattedTimes.find((item) => item.application_id === params.row.application_id)?.formattedTime;
                return formattedTime || formatTimeDifference(params.value);
            },
        },
    ];
    const rows = userAllloanApplications?.length ? userAllloanApplications?.map((app) => ({
        application_id: app?.application_id,
        propertyType: app?.property_type,
        purpose: app?.loan_purpose,
        propertyValue: app?.property_value ? formatPrice(app?.property_value) : '',
        loanAmount: app?.loan_amount ? formatPrice(app?.loan_amount) : '',
        ltv: app?.ltv ? `${app.ltv}%` : '0%',
        borrower: app?.first_name + ' ' + app?.last_name,
        status: app?.property_status || '',
        streetAddress: app.property_address,
        lat: app?.lat ? app?.lat : '',
        lng: app?.lng ? app?.lng : '',
        city: app?.city ? app?.city : '',
        state: app?.state ? app?.state : '',
        country: app?.country ? app?.country : '',
        postal_code: app?.postal_code ? app?.postal_code : '',
        borrower_id: app?.user_id,
        createdAt: app.application_created_at || 'Just now',
    })) : [];

    const handleRowClick = async (params) => {
        try {
            // Show the loader
            showLoader();

            if (params !== '') {
                // Update property details and fetch conversation list
                updatePropertyDetails(params);
                const location = rows.find((loc) => loc.application_id === params.application_id);
                setSelectedLocation({ lat: location?.lat, lng: location?.lng });
                setSelectedRowId(params?.application_id);
                setSelectedBorrowerId(params?.borrower_id);
                setSelectedRowStatus(params?.status);
            } else {
                setConversationList([]);
            }

            setTimeout(() => {
                hideLoader();
            }, 1000);

        } catch (error) {
            console.error("Error in handleRowClick:", error);
            toast.error(error.message || "Something went wrong. Please try again.");

            // Ensure the loader is hidden in case of an error
            hideLoader();
        }
    };
    useEffect(() => {
        if (rows.length > 0) {
            const firstRow = rows[0];
            const lastRow = rows[rows.length - 1];
            if (selectedRowId > 0) {
                const location = rows.find((loc) => loc.application_id === selectedRowId);
                setSelectedLocation({ lat: location?.lat, lng: location?.lng });
                const selectedRow = rows.find((data) => data.application_id === selectedRowId);
                updatePropertyDetails(selectedRow);
            } else {
                setSelectedRowId(firstRow?.application_id);
                setSelectedLocation({ lat: firstRow?.lat, lng: firstRow?.lng });
                setSelectedRowStatus(firstRow?.status);
                updatePropertyDetails(firstRow);
            }
        } else {
            updatePropertyDetails([]);
            handleRowClick([]);
        }
    }, [userAllloanApplications]);

    const lenderHeaderProps = {
        handleSortChange,
        setFilter,
        setIsFilter,
        isFilter
    };

    useEffect(() => {
        const updateHeight = () => {
            if (propertyDetailRef.current) {
                setPropertyDetailHeight(propertyDetailRef.current.offsetHeight);
            }
        };
    
        setTimeout(()=>{
            updateHeight();
        },500)
    }, [propertyDetails]);
    
    return (
        <div className="tw-h-full tw-w-full">
            {/* <header className="common-font tw-flex tw-justify-between tw-items-center tw-w-full tw-h-[60px]">
                <div>
                    <h1 className="tw-text-black tw-text-[40px] tw-font-bold tw-leading-[60px]">Lender Dashboard</h1>
                </div>
            </header> */}
            <div className='tw-block tw-mt-[30px]'>
                {/* <StatsDropdown /> */}

                <div className="tw-flex tw-justify-between tw-bg-white tw-rounded-lg tw-hidden">
                    {stats?.map((stat, index) => (
                        <StatsCards
                            key={index}
                            title={stat?.title}
                            value={stat?.value}
                            change={stat?.change}
                        />
                    ))}
                </div>
            </div>
            <div className="tw-flex tw-space-x-4 tw-mt-1 hidden">
                <FilterForm
                    filter={filter}
                    setFilter={setFilter}
                    filterHandler={filterHandler}
                    resetFilterHandler={resetFilterHandler}
                />
            </div>
            <div className="tw-flex tw-space-x-4 tw-mt-10 tw-h-full">
                <div className="tw-w-2/3">
                    <ApplicationTable
                        dataTableProps={{
                            getRowId: (row, index) => `${index}-${row?.application_id}`,
                            userStatus: ['Offer Sent', "Rejected", "Pending"],
                            dashboardType: 'lender',
                            handleSortChange,
                            getRowClassName: (params) =>
                                params?.row?.application_id === selectedRowId ? 'selected-row' : ''
                        }}
                        onRowClick={handleRowClick}
                        rows={rows}
                        selectedRow={rows.find(row => row.application_id === selectedRowId) || rows[rows.length - 1]}
                        columns={columns}
                        tableHeight="400px"
                        maxHeight={`${propertyDetailHeight}px`} // Set maxHeight dynamically based on PropertyDetail height
                        minHeight='50%'
                        customHeaderProps={lenderHeaderProps}
                        CustomHeaderComponent={LenderHeader}
                    />
                </div>
                <div className="tw-w-1/3 tw-h-full" ref={propertyDetailRef}>
                    <PropertyDetail
                        property={propertyDetails}
                        setOpenSendOfferModal={setOpenSendOfferModal}
                        selectedLocation={selectedLocation}
                        rowStatus={selectedRowStatus}
                    />
                </div>
            </div>

            {/* <div className='hidden'>
                <Chat />
            </div> */}
            <div className='tw-flex tw-mt-4 hidden tw-justify-between'>
                <div className='tw-w-2/3'>
                    <Chat application_id={selectedRowId} setOpenSendOfferModal={setOpenSendOfferModal}
                        rowStatus={selectedRowStatus} chatWindowRender={chatWindowRender}
                    />
                </div>

                <div className='tw-w-1/3'>
                    <AssetGenerated />
                </div>
            </div>

            {/* <div className='hidden tw-flex tw-mt-10'>
                <div className='tw-w-[67%]'>
                    <AreaInterest />
                </div>
            </div> */}


            {/* <SendOffer open={openSendOfferModal} handleClose={handleCloseModal} /> */}
            <SendOffers
                open={openSendOfferModal}
                handleClose={handleCloseModal}
                applicationId={applicationId}
                selectedRowBorrowerId={selectedRowBorrowerId}
                fetchAllUserLoanApplications={fetchAllUserLoanApplications}
                setSelectedRowId={setSelectedRowId}
                setSendOfferSelectedAppId={setSelectedRowId}
                setChatWindowRender={setChatWindowRender}
            />



            {/* <div className="tw-flex tw-space-x-4 tw-mt-4">
                <div className="tw-flex-1">
                    <ApplicationTable />
                </div>
                <div className="tw-w-[300px]">
                    <FormBox />
                </div>
            </div> */}
        </div>
    )
}

export default Lender
