import React, { useState } from "react";
import { confirmSignUp } from "@aws-amplify/auth";

const ValidationModal = ({isOpen, onClose}) => {
    const [username, setUsername] = useState("");
    const [authenticationCode, setAuthenticationCode] = useState(0);
    const [validating, setValidating] = useState(false);

    const validateUser = async () => {
        try {
            setValidating(true);
            const {isSignUpComplete} = await confirmSignUp({username: username, confirmationCode: authenticationCode});

            if (isSignUpComplete) {
                setValidating(false);
                onClose(true);
            }
        } catch (err) {
            setValidating(false);
            console.log(err);
        }
    }

    if (!isOpen) return null;

    return (
        <div
            className="tw-fixed tw-font-oswald tw-inset-0 tw-bg-black tw-border-white tw-shadow-xl tw-bg-opacity-50 tw-flex tw-items-center tw-justify-center tw-z-50"
            onClick={() => onClose(false)}
        >
            <div
                className="tw-bg-black tw-text-center tw-p-5 tw-rounded-xl tw-w-full md:tw-w-1/2 lg:tw-w-1/3"
                onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
            >
                <div className="tw-flex tw-items-center tw-flex-col tw-justify-center">
                    <span className='tw-bg-logo-image tw-bg-center tw-flex tw-justify-center tw-w-full tw-h-16 tw-bg-contain tw-bg-no-repeat'></span>
                    <h2 className="tw-text-xl tw-text-white tw-mb-4">
                        Confirm OTP
                    </h2>
                </div>

                <div className="tw-flex tw-flex-col tw-space-y-4">
                    <input
                        type="email"
                        name="email"
                        className="tw-font-oswald tw-text-black tw-w-full tw-p-2 tw-border tw-border-gray-300 tw-rounded-xl"
                        placeholder="Username/Email"
                        onChange={event => setUsername(event.target.value)}
                    />
                    <input
                        type="text"
                        name="authentication_code"
                        className="tw-font-oswald tw-text-black tw-w-full tw-p-2 tw-border tw-border-gray-300 tw-rounded-xl"
                        placeholder="Enter OTP"
                        onChange={event => setAuthenticationCode(event.target.value)}
                    />
                    <button
                        className="tw-bg-blue-500 hover:tw-bg-blue-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded-xl tw-w-full"
                        onClick={validateUser}
                        disabled={validating}
                    >
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ValidationModal;