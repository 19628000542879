import React, { useContext, useEffect } from 'react';
import Sidebar from './Sidebar';
import { Outlet } from 'react-router-dom';
// import { Container } from '@material-tailwind/react'; 
import { useNavigate } from 'react-router-dom';

import { UserContext } from '../../../contexts/UserContext';

const AuthLayout = () => {
  const navigate = useNavigate();

  const { user } = useContext(UserContext)

  useEffect(() => {
    if (user) {
      if (user.user_type === 'borrower') {
        navigate("/dashboard/borrower");
      } else if (user.user_type === 'lender') {
        navigate("/dashboard/lender");
      } else if (user.user_type === 'admin') {
        navigate("/dashboard/admin");
      }
    }
  }, [user, navigate]); // Only re-run if user or navigate changes

  return (
    <>
      <div className="tw-h-screen tw-flex">
        <div className="tw-w-[37%]">
          <Sidebar />
        </div>
        <div className="tw-w-[63%]">
          <Outlet /> {/* This is where the SignUp component will render */}
        </div>
      </div>
    </>
  );
};

export default AuthLayout;
