import { useState } from "react";
import EditIcon from "../../../assets/icons/editIcon.svg";
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const LoanPreferencesForm = () => {
    const propertyTypes = ["Old", "New", "Re-constructed"];
    const conditions = ["Excellent", "Good", "Poor", "Average"];
    const loanPurposes = ["Refinance", "Construction"];
    const loanTerms = ["1 Year", "3 Years", "5 Years", "10 Years"];
    const ltvValues = ["10%", "12%", "15%", "20%"];

    const [propertyType, setPropertyType] = useState("");
    const [propertyCondition, setPropertyCondition] = useState("");
    const [loanPurpose, setLoanPurpose] = useState("");
    const [loanTerm, setLoanTerm] = useState("");
    const [ltv, setLtv] = useState("");
    const [propertySize, setPropertySize] = useState({
        min: '450',
        max: '1000'
    });
    const [propertyValue, setPropertyValue] = useState({
        min: '450',
        max: '1000'
    });
    const [loanRangeAmount, setLoanRangeAmount] = useState({
        min: '5000',
        max: '15000'
    });
    const [interestRatePreference, setInterestRatePreference] = useState('Fixed');

    return (
        <div className="tw-flex tw-flex-col tw-border-[1px] tw-border-[#0A263F]/14 tw-rounded-lg tw-p-5 tw-space-y-8">
            <div className="tw-flex tw-justify-between tw-border-b-[1px] tw-pb-4">
                <h2 className="tw-text-xl tw-text-[#202224] tw-font-semibold tw-font-nunito">Loan Preferences</h2>

                <button className="tw-flex tw-space-x-2 tw-items-center">
                    <img src={EditIcon} alt="change icon" />
                    <span className="tw-text-[#2A2F70] tw-font-nunito tw-text-lg tw-font-semibold">Edit Preferences</span>
                </button>
            </div>

            <form className="tw-flex tw-space-x-4">
                <div className="tw-w-full tw-flex tw-flex-col tw-space-y-2">
                    <div className="tw-flex tw-justify-between tw-space-x-4">
                        <div className="tw-flex tw-flex-col tw-w-1/2"> {/* Increased width */}
                            <p className="tw-text-[#202224]/70 tw-font-nunito tw-text-md tw-font-semibold">Property Type</p>
                            <FormControl sx={{ width: '100%' }} variant="outlined">
                                <select
                                    className="tw-text-black tw-font-inter tw-py-4 tw-border-[1px] tw-rounded-lg tw-border-black-300 tw-ring-0 tw-ring-offset-0 tw-p-4 tw-bg-white"
                                    onChange={() => { }}
                                    value={propertyType}
                                >
                                    {propertyTypes.map((item, index) => (
                                        <option key={index} value={index}>
                                            {item}
                                        </option>
                                    ))}
                                </select>
                            </FormControl>
                        </div>

                        <div className="tw-flex tw-flex-col tw-w-1/2"> {/* Increased width */}
                            <p className="tw-text-[#202224]/70 tw-font-nunito tw-text-md tw-font-semibold">Condition</p>
                            <FormControl sx={{ width: '100%' }} variant="outlined">
                                <select
                                    className="tw-text-black tw-font-inter tw-py-4 tw-border-[1px] tw-rounded-lg tw-border-black-300 tw-ring-0 tw-ring-offset-0 tw-p-4 tw-bg-white"
                                    onChange={() => { }}
                                    value={propertyCondition}
                                >
                                    {conditions.map((item, index) => (
                                        <option key={index} value={index}>
                                            {item}
                                        </option>
                                    ))}
                                </select>
                            </FormControl>
                        </div>

                        <div className="tw-flex tw-flex-col tw-w-1/4">
                            <p className="tw-text-[#202224]/70 tw-font-nunito tw-text-md tw-font-semibold">Property Size (sqft)</p>

                            <div className="tw-flex tw-space-x-2">
                                <FormControl sx={{ width: '100%' }} variant="outlined">
                                    <div className="tw-h-[55px] tw-flex tw-items-center tw-border tw-border-[#DDDDDD]  tw-p-2">
                                        <span className="tw-flex tw-items-center">
                                            <span className="tw-text-[#888888] common-font">Min</span>
                                            <span className="tw-text-[#242424] tw-mx-2">|</span>
                                        </span>
                                        <input
                                            type="text"
                                            name="property_value_min"
                                            className="tw-flex-1 tw-bg-transparent tw-outline-none tw-text-[#202224] common-font"
                                            placeholder="450"
                                        />
                                    </div>
                                </FormControl>

                                <FormControl sx={{ width: '100%' }} variant="outlined">
                                    <div className="tw-h-[55px] tw-flex tw-items-center tw-border tw-border-[#DDDDDD]  tw-p-2">
                                        <span className="tw-flex tw-items-center">
                                            <span className="tw-text-[#888888] common-font">Max</span>
                                            <span className="tw-text-[#242424] tw-mx-2">|</span>
                                        </span>
                                        <input
                                            type="text"
                                            name="property_value_max"
                                            className="tw-flex-1 tw-bg-transparent tw-outline-none tw-text-[#202224] common-font"
                                            placeholder="1000"
                                        />
                                    </div>
                                </FormControl>
                            </div>
                        </div>

                        <div className="tw-flex tw-flex-col tw-w-1/4">
                            <p className="tw-text-[#202224]/70 tw-font-nunito tw-text-md tw-font-semibold">Property Value ($)</p>

                            <div className="tw-flex tw-space-x-2">
                                <FormControl sx={{ width: '100%' }} variant="outlined">
                                    <div className="tw-h-[55px] tw-flex tw-items-center tw-border tw-border-[#DDDDDD]  tw-p-2">
                                        <span className="tw-flex tw-items-center">
                                            <span className="tw-text-[#888888] common-font">Min</span>
                                            <span className="tw-text-[#242424] tw-mx-2">|</span>
                                        </span>
                                        <input
                                            type="text"
                                            name="property_value_min"
                                            className="tw-flex-1 tw-bg-transparent tw-outline-none tw-text-[#202224] common-font"
                                            placeholder="450"
                                        />
                                    </div>
                                </FormControl>

                                <FormControl sx={{ width: '100%' }} variant="outlined">
                                    <div className="tw-h-[55px] tw-flex tw-items-center tw-border tw-border-[#DDDDDD]  tw-p-2">
                                        <span className="tw-flex tw-items-center">
                                            <span className="tw-text-[#888888] common-font">Max</span>
                                            <span className="tw-text-[#242424] tw-mx-2">|</span>
                                        </span>
                                        <input
                                            type="text"
                                            name="property_value_max"
                                            className="tw-flex-1 tw-bg-transparent tw-outline-none tw-text-[#202224] common-font"
                                            placeholder="1000"
                                        />
                                    </div>
                                </FormControl>
                            </div>
                        </div>
                    </div>

                    <div className="tw-flex tw-justify-between tw-space-x-4">
                        <div className="tw-flex tw-flex-col tw-w-1/3">
                            <p className="tw-text-[#202224]/70 tw-font-nunito tw-text-md tw-font-semibold">Loan Purpose</p>

                            <FormControl sx={{ width: '100%' }} variant="outlined">
                                <select
                                    className="tw-text-black tw-font-inter tw-py-4 tw-border-[1px] tw-rounded-lg tw-border-black-300 tw-ring-0 tw-ring-offset-0 tw-p-4 tw-bg-white"
                                    onChange={() => { }}
                                    value={loanPurpose}
                                >
                                    {loanPurposes.map((item, index) => (
                                        <option key={index} value={index}>
                                            {item}
                                        </option>
                                    ))}
                                </select>
                            </FormControl>
                        </div>

                        <div className="tw-flex tw-flex-col tw-w-1/3">
                            <p className="tw-text-[#202224]/70 tw-font-nunito tw-text-md tw-font-semibold">Loan Term</p>

                            <FormControl sx={{ width: '100%' }} variant="outlined">
                                <select
                                    className="tw-text-black tw-font-inter tw-py-4 tw-border-[1px] tw-rounded-lg tw-border-black-300 tw-ring-0 tw-ring-offset-0 tw-p-4 tw-bg-white"
                                    onChange={() => { }}
                                    value={loanTerm}
                                >
                                    {loanTerms.map((item, index) => (
                                        <option key={index} value={index}>
                                            {item}
                                        </option>
                                    ))}
                                </select>
                            </FormControl>
                        </div>

                        <div className="tw-flex tw-flex-col tw-w-1/3">
                            <p className="tw-text-[#202224]/70 tw-font-nunito tw-text-md tw-font-semibold">LTV %</p>

                            <FormControl sx={{ width: '100%' }} variant="outlined">
                                <select
                                    className="tw-text-black tw-font-inter tw-py-4 tw-border-[1px] tw-rounded-lg tw-border-black-300 tw-ring-0 tw-ring-offset-0 tw-p-4 tw-bg-white"
                                    onChange={() => { }}
                                    value={ltv}
                                >
                                    {ltvValues.map((item, index) => (
                                        <option key={index} value={index}>
                                            {item}
                                        </option>
                                    ))}
                                </select>
                            </FormControl>
                        </div>

                        <div className="tw-flex tw-flex-col tw-w-1/3">
                            <p className="tw-text-[#202224]/70 tw-font-nunito tw-text-md tw-font-semibold">Loan Amount Range ($)</p>

                            <div className="tw-flex tw-space-x-2">
                                <FormControl sx={{ width: '100%' }} variant="outlined">
                                    <div className="tw-h-[55px] tw-flex tw-items-center tw-border tw-border-[#DDDDDD]  tw-p-2">
                                        <span className="tw-flex tw-items-center">
                                            <span className="tw-text-[#888888] common-font">Min</span>
                                            <span className="tw-text-[#242424] tw-mx-2">|</span>
                                        </span>
                                        <input
                                            type="text"
                                            name="property_value_min"
                                            className="tw-flex-1 tw-bg-transparent tw-outline-none tw-text-[#202224] common-font"
                                            placeholder="5000"
                                        />
                                    </div>
                                </FormControl>

                                <FormControl sx={{ width: '100%' }} variant="outlined">
                                    <div className="tw-h-[55px] tw-flex tw-items-center tw-border tw-border-[#DDDDDD]  tw-p-2">
                                        <span className="tw-flex tw-items-center">
                                            <span className="tw-text-[#888888] common-font">Max</span>
                                            <span className="tw-text-[#242424] tw-mx-2">|</span>
                                        </span>
                                        <input
                                            type="text"
                                            name="property_value_max"
                                            className="tw-flex-1 tw-bg-transparent tw-outline-none tw-text-[#202224] common-font"
                                            placeholder="15000"
                                        />
                                    </div>
                                </FormControl>
                            </div>
                        </div>

                        <div className="tw-flex tw-flex-col tw-w-1/3">
                            <p className="tw-text-[#202224]/70 tw-font-nunito tw-text-md tw-font-semibold">Interest rate preference</p>

                            <div className="tw-flex tw-space-x-2">
                                <FormControl>
                                    <RadioGroup
                                        row
                                        aria-labelledby="interest-preferences-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel value="fixed" control={<Radio tabIndex={-1} />} label="Fixed" className="tw-text-black" tabIndex="0" />
                                        <FormControlLabel value="variable" control={<Radio tabIndex={-1} />} label="Variable" className="tw-text-black" tabIndex="0" />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                </div>
                <></>
            </form>
        </div>
    )
}

export default LoanPreferencesForm;