import React, { createContext, useCallback, useMemo } from 'react';
// import { Amplify, Auth } from 'aws-amplify';
import { Amplify } from 'aws-amplify';
// import { getCurrentUser } from '@aws-amplify/auth';
import awsconfig from './../aws-exports'; 
// const AWS = require('aws-sdk');


Amplify.configure(awsconfig);

// Create a context
export const AWSAmplifyConfigContext = createContext({
  data:{},
  endpoint:""
});

// Create a provider component
export const AWSAmplifyConfigProvider = ({value, children }) => {
    const removeCognitoUser = useCallback(async(username) => {
      try {

        // Retrieve credentials using Auth.currentCredentials()
        // const credentials = await getCurrentUser();
        // if (!credentials) {
        //   console.error('No credentials found.');
        //   return false;
        // }

        // console.log("credentials", credentials)

        // // Configure AWS SDK with temporary credentials
        // AWS.config.update({
        //   region: process.env.REACT_APP_AWS_REGION, // Use your region
        //   credentials: credentials, // Use AWS Amplify-generated credentials
        // });

        // // Create a new instance of CognitoIdentityServiceProvider
        // const cognito = new AWS.CognitoIdentityServiceProvider();

        // const params = {
        //   UserPoolId: process.env.REACT_APP_AWS_REGION,
        //   Username: username,  // This can be an email or user ID
        // };
    
        // // Call the adminDeleteUser method
        // await cognito.adminDeleteUser(params).promise();
        // console.log(`User ${username} has been deleted successfully.`);
        // return true;
        
      } catch (error) {
        // console.error('Error deleting user: ', error);
        // return false;
      }
    },[]);

    // Assuming your API is part of `aws_cloud_logic_custom`
    const toBePassedData = useMemo(()=>{
        const cloudLogicCustomConfig = awsconfig.aws_cloud_logic_custom;
        let endpoint  =''
        if (cloudLogicCustomConfig && cloudLogicCustomConfig.length > 0) 
          endpoint = cloudLogicCustomConfig[0].endpoint;
          

        return {
          data:cloudLogicCustomConfig,
          endpoint:endpoint,
          removeCognitoUser
        }
    },[removeCognitoUser])
    
   
      return (
        <AWSAmplifyConfigContext.Provider value={toBePassedData}>
          {children}
        </AWSAmplifyConfigContext.Provider>
      );
};
