import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import rootReducer from './store/reducers';
import {thunk} from 'redux-thunk'

const store = configureStore({ reducer: rootReducer, middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware().concat(thunk),
 })

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
