import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { resetPassword, confirmResetPassword } from "@aws-amplify/auth";
import toast from "react-hot-toast";
import { Input } from '@material-tailwind/react';
import { Visibility, VisibilityOff } from '@mui/icons-material';

function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [otpError, setOtpError] = useState('');
    const [newPasswordError, setNewPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [step, setStep] = useState(1); // Track the current step

    const navigate = useNavigate();

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleForgotPasswordRequest = async (e) => {
        e.preventDefault();
        // Simple email validation
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailPattern.test(email)) {
            setError('Please enter a valid email address.');
            return;
        }

        // Clear error if validation passes
        if (!email) {
            toast.error("Email address is required");
            return;
        }

        try {
            await resetPassword({ username: email }); // Step 1: Request password reset
            toast.success("Verification code sent to your email.");
            setStep(2); // Move to the next step
        } catch (error) {
            console.error("Forgot Password Error:", error);
            toast.error(error?.message || "Failed to send verification code.");
        }
    };

    const handlePasswordReset = async (e) => {
        e.preventDefault();
        if (!verificationCode) {
            setOtpError("OTP is required")
        }
        if (!newPassword) {
            setNewPasswordError("New Password is required")
        }
        if (!confirmPassword) {
            setConfirmPasswordError("Confirm Password is required")
        }

        if (!verificationCode || !newPassword || !confirmPassword) {
            toast.error("All fields are required.");
            return;
        }

        if (newPassword !== confirmPassword) {
            toast.error("Passwords do not match.");
            return;
        }


        try {
            await confirmResetPassword({ username: email, confirmationCode: verificationCode, newPassword }); // Step 2: Submit new password
            toast.success("Password reset successful! Please sign in with your new password.");
            setStep(1);
            setEmail('');
            setVerificationCode('');
            setNewPassword('');
            setConfirmPassword('');
            navigate(`/sign-in`);
        } catch (error) {
            console.error("Password Reset Error:", error);
            toast.error(error?.message || "Failed to reset password.");
        }
    };

    return (
        <>
            <div className="tw-bg-white tw-px-4 sm:tw-px-4 md:tw-px-12 lg:tw-px-12 tw-py-16 tw-flex tw-justify-center tw-items-center tw-h-screen">
                <div className="tw-w-full">

                    <h1 className="tw-font-oswald tw-text-4xl tw-text-black tw-font-bold mb-4">Recover Password</h1>
                    {step === 1 ? <p className="tw-mb-4 tw-text-2xl tw-text-black tw-mt-2">
                        Enter the email that you used when you signed up to recover your password. <br />
                        You will receive a password verification code.
                    </p> : <p className="tw-mb-4 tw-text-2xl tw-text-black tw-mt-2">
                        Enter the verification code sent to your email, and create a new password. <br />
                    </p>}

                    {step === 1 && (
                        <form onSubmit={handleForgotPasswordRequest} className="tw-space-y-10">
                            <div className="tw-flex tw-flex-wrap md:tw-flex-nowrap lg:tw-gap-x-4 md:tw-gap-x-4 lg:tw-gap-y-0">
                                <div className="tw-w-full tw-w-full sm:tw-w-full md:tw-w-[50%] lg:tw-w-[50%]  tw-mt-5">
                                    <label htmlFor="email" className="form-label">Email Address</label>
                                    <input
                                        id="email"
                                        type="email"
                                        autoFocus = {true}
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        className={`tw-border tw-p-3 tw-border-gray-400 tw-h-14 tw-w-[100%] tw-rounded-16 tw-text-black tw-font-oswald ${error ? 'tw-border-red-500' : ''}`}
                                    />
                                    {error && <p className="tw-text-red-500 tw-text-[14px]">{error}</p>}
                                </div>
                                <button className="tw-w-[80%] sm:tw-w-[80%] md:tw-w-1/3 lg:tw-w-[170px]  tw-h-[70px] tw-bg-green-300 tw-border tw-text-blue-900 tw-font-bold tw-py-4 tw-rounded-full tw-mt-10">
                                    Send Code
                                    <span className="tw-ml-2 tw-bg-white tw-p-2 tw-rounded-full">➔</span>
                                </button>
                            </div>
                        </form>
                    )}
                    {step === 2 && (
                        <div>
                            <h2>Reset Password</h2>
                            <form onSubmit={handlePasswordReset}>
                                <div>
                                    <div className='tw-flex tw-flex-wrap md:tw-flex-nowrap lg:tw-gap-x-4 md:tw-gap-x-4 lg:tw-gap-y-0'>
                                        <div className="tw-w-full tw-w-full sm:tw-w-full md:tw-w-[50%] lg:tw-w-[50%] tw-mt-5">
                                            <label htmlFor="verificationCode" className="form-label">Verification Code</label>
                                            <input
                                                type="number"
                                                placeholder="Enter verification code"
                                                value={verificationCode}
                                                onChange={(e) => setVerificationCode(e.target.value)}
                                                className={`tw-border tw-p-3 tw-border-gray-400 tw-h-14 tw-w-[100%] tw-rounded-16 tw-text-black tw-font-oswald ${otpError ? 'tw-border-red-500' : ''}`}
                                            />
                                            {otpError && <p className="tw-text-red-500 tw-text-[14px]">{otpError}</p>}
                                        </div>
                                        <div className="tw-relative tw-w-full tw-w-full sm:tw-w-full md:tw-w-[50%] lg:tw-w-[50%] tw-mt-1 sm:tw-mt-1 md:tw-mt-5 lg:tw-mt-5">
                                            <label htmlFor="password" className="form-label">New Password</label>
                                            <Input
                                                id="password"
                                                type={showPassword ? 'text' : 'password'}
                                                value={newPassword}
                                                onChange={(e) => setNewPassword(e.target.value)}
                                                className={`tw-border tw-p-3 tw-border-gray-400 tw-h-14 tw-w-[100%] tw-rounded-16 tw-text-black tw-font-oswald ${newPasswordError ? 'tw-border-red-500' : ''}`}
                                                icon={
                                                    <button
                                                        tabIndex={-1}
                                                        type="button"
                                                        onClick={togglePasswordVisibility}
                                                        className="tw-absolute tw-right-3 tw-pt-4 tw-transform"
                                                    >
                                                        {
                                                            showPassword ? <VisibilityOff className='tw-text-[#00000066]' /> : <Visibility className='tw-text-[#00000066]' />
                                                        }
                                                    </button>
                                                }
                                            />
                                            {newPasswordError && <p className="tw-text-red-500 tw-text-[14px]">{newPasswordError}</p>}
                                        </div>
                                    </div>
                                    <div className='tw-flex tw-flex-wrap md:tw-flex-nowrap lg:tw-gap-x-4 md:tw-gap-x-4 lg:tw-gap-y-0 tw-items-end tw-justify-between'>
                                        <div className="tw-relative tw-w-full tw-w-full sm:tw-w-full md:tw-w-[50%] lg:tw-w-[50%] tw-mt-1">
                                            <label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
                                            <Input
                                                id="confirmPassword"
                                                type={showConfirmPassword ? 'text' : 'password'}
                                                value={confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                                className={`tw-border tw-p-3 tw-border-gray-400 tw-h-14 tw-w-[100%] tw-rounded-16 tw-text-black tw-font-oswald ${confirmPasswordError ? 'tw-border-red-500' : ''}`}
                                                icon={
                                                    <button
                                                        tabIndex={-1}
                                                        type="button"
                                                        onClick={toggleConfirmPasswordVisibility}
                                                        className="tw-absolute tw-right-3 tw-pt-4 tw-transform"
                                                    >
                                                        {
                                                            showConfirmPassword ? <VisibilityOff className='tw-text-[#00000066]' /> : <Visibility className='tw-text-[#00000066]' />
                                                        }
                                                    </button>
                                                }
                                            />
                                            {confirmPasswordError && <p className="tw-text-red-500 tw-text-[14px]">{confirmPasswordError}</p>}
                                        </div>
                                        <button className="tw-w-full sm:tw-w-full md:tw-w-1/2 lg:tw-w-[200px] tw-h-[65px] tw-bg-green-300 tw-border tw-text-blue-900 tw-font-bold tw-py-4 tw-rounded-full tw-mt-10">
                                            Reset Password
                                            <span className="tw-ml-2 tw-bg-white tw-p-2 tw-rounded-full">➔</span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default ForgotPassword