import React, { useContext, useEffect, useRef, useState } from 'react'
import { UserContext } from '../../../contexts/UserContext';

function ChatFooter({ sendMessage, message, setMessage, isSending, setShowTypingIndicator, setOpenSendOfferModal, rowStatus }) {
    const { user } = useContext(UserContext);
    let typingTimeout;

    return (
        <div
            className='tw-footer'
            style={{position:"sticky",bottom:"0px"}}
        >
            <div className='tw-ml-[20px] tw-my-[40px] tw-mr-[10px] tw-flex tw-items-center tw-justify-center'>
                {user?.user_type === 'borrower' ? (
                    <button className='tw-w-[30px] tw-h-[30px] tw-text-center tw-justify-center tw-border-[2px] tw-border-[#646464] tw-rounded-[10px] tw-pt-[2px] tw-text-gray-600 add_chat_btn' 
                    name='add_chat_btn'>
                        <i className=" fas fa-plus"></i>
                    </button>
                ) : (
                    <button
                        className='tw-flex tw-font-inter tw-px-1 tw-items-center tw-space-x-2 tw-text-center tw-justify-center tw-border-[2px] tw-border-[#646464] tw-rounded-[10px] tw-text-gray-600 hover:tw-bg-green-900 hover:tw-text-white hover:tw-border-black add_chat_offer_btn'
                        name='add_chat_offer_btn'
                        onClick={() => {
                            if (rowStatus !== "Settled" && rowStatus !== "Archive") {
                                setOpenSendOfferModal(true);
                            }
                        }}
                    >
                        Offer
                        <i className="fas fa-paper-plane tw-mx-2"></i>
                    </button>
                )}
            </div>
            <div className='tw-w-[1204px] tw-h-[54px]'>
                <input
                    type="text"
                    placeholder="Message... "
                    className='tw-p-5 tw-w-full tw-h-full tw-border tw-border-[#DDDDDD] tw-rounded-[10px] tw-p-2 tw-text-[#202224]'
                    id="chatRoomMessageInput"
                    name='chatMessage'
                    value={message}
                    onChange={(e) => {
                        setMessage(e.target.value);
                        setShowTypingIndicator(true);

                        // Clear the previous timeout
                        if (typingTimeout) clearTimeout(typingTimeout);

                        // Hide typing indicator after 2 seconds of inactivity
                        typingTimeout = setTimeout(() => {
                            setShowTypingIndicator(false);
                        }, 3000);
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && !isSending) {
                            sendMessage();
                        }
                    }}
                />
            </div>

            <div
                className="tw-w-[54px] tw-h-[54px] tw-px-[15px] tw-rounded-[10px] tw-ml-[5px] tw-my-[40px] tw-bg-[#7DDE92] tw-border-[1px] tw-border-[#DDDDDD] tw-flex tw-items-center tw-justify-center hover:tw-border-[#2A2F70] focus-within:tw-border-[#2A2F70]"
                style={{ cursor: isSending ? "not-allowed" : "pointer" }}
                onClick={isSending ? null : sendMessage} 
            >
                <button
                    className="fas fa-paper-plane tw-text-[#2A2F70] tw-text-[20px] focus:tw-outline-none focus:tw-border-[#2A2F70] send_msg_btn"
                    id='send_msg_btn'
                    disabled={isSending}
                    style={{
                        background: "none",
                        border: "none",
                        outline: "none",
                        cursor: "inherit", 
                    }}
                    onClick={(e) => e.preventDefault()}
                ></button>
            </div>
        </div>
    )
}

export default ChatFooter
