import React, { useState, useEffect, useRef } from 'react';
import {
    Menu,
    MenuHandler,
    MenuList,
    MenuItem,
} from "@material-tailwind/react";
import DatePicker from "react-datepicker"; // Ensure you have react-datepicker installed
import "react-datepicker/dist/react-datepicker.css"; // Import CSS for the date picker

function FilterDropdown({ onDateChange }) {
    const [selectedDate, setSelectedDate] = useState('Select');
    const [isCustomDate, setIsCustomDate] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const datePickerRef = useRef(null);

    const dateOptions = [
        'Today',
        'This Week',
        'Last Week',
        'This Month',
        'Last Month',
        'This Year',
        'Last Year',
        'Custom Date',
    ];

    const getDateRange = (option) => {
        const today = new Date();
        let start_date, end_date;

        switch (option) {
            case 'Today':
                start_date = end_date = formatDate(today);
                break;
            case 'This Week':
                const thisWeekStart = new Date(today);
                thisWeekStart.setDate(today.getDate() - today.getDay());
                start_date = formatDate(thisWeekStart);
                end_date = formatDate(today);
                break;
            case 'Last Week':
                const lastWeekStart = new Date(today);
                lastWeekStart.setDate(today.getDate() - today.getDay() - 7);
                const lastWeekEnd = new Date(lastWeekStart);
                lastWeekEnd.setDate(lastWeekStart.getDate() + 6);
                start_date = formatDate(lastWeekStart);
                end_date = formatDate(lastWeekEnd);
                break;
            case 'This Month':
                const thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
                start_date = formatDate(thisMonthStart);
                end_date = formatDate(today);
                break;
            case 'Last Month':
                const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);
                start_date = formatDate(lastMonth);
                end_date = formatDate(lastMonthEnd);
                break;
            case 'This Year':
                const thisYearStart = new Date(today.getFullYear(), 0, 1);
                start_date = formatDate(thisYearStart);
                end_date = formatDate(today);
                break;
            case 'Last Year':
                const lastYear = new Date(today.getFullYear() - 1, 0, 1);
                const lastYearEnd = new Date(today.getFullYear() - 1, 11, 31);
                start_date = formatDate(lastYear);
                end_date = formatDate(lastYearEnd);
                break;
            default:
                start_date = null;
                end_date = null;
        }

        return { start_date, end_date };
    };

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const handleOptionSelect = (option) => {
        setSelectedDate(option);
        setIsCustomDate(option === 'Custom Date');

        if (option !== 'Custom Date') {
            const { start_date, end_date } = getDateRange(option);
            if (onDateChange) {
                onDateChange({ start_date, end_date });
            }
        }
    };

    const handleClickOutside = (event) => {
        if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
            setIsCustomDate(false);
        }
    };

    useEffect(() => {
        if (isCustomDate) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isCustomDate]);

    useEffect(() => {
        // Ensure both startDate and endDate are set before calling onDateChange
        if (onDateChange && startDate && endDate) {
            onDateChange({ 
                start_date: formatDate(startDate), 
                end_date: formatDate(endDate) 
            });
        }
    }, [startDate, endDate, onDateChange]);

    return (
        <div className="tw-flex tw-justify-end tw-relative tw-w-full">
            <Menu>
                <MenuHandler>
                    <div className="tw-w-[145px] tw-h-[40px] tw-py-[15px] tw-ps-8 tw-flex tw-items-center tw-justify-between tw-text-[16px] tw-text-[#4F4F4F] common-font tw-cursor-pointer">
                        <span>{selectedDate}</span>
                        <i className="fas fa-chevron-down tw-text-black tw-text-xs tw-w-8 tw-h-8 tw-flex tw-items-center tw-justify-center"></i>
                    </div>
                </MenuHandler>

                <MenuList className="tw-w-[142px] tw-my-[-12px] tw-bg-white tw-border tw-border-[#DDDDDD] tw-rounded-[5px] tw-py-[5px] tw-shadow-md tw-z-10">
                    {dateOptions.map((option) => (
                        <MenuItem
                            key={option}
                            onClick={() => handleOptionSelect(option)}
                            className="tw-px-7 tw-cursor-pointer tw-text-[#4F4F4F]"
                        >
                            {option}
                            <div className="tw-border-b tw-border-[#DDDDDD] tw-w-full tw-my-2"></div>
                        </MenuItem>
                    ))}
                </MenuList>
            </Menu>

            {isCustomDate && (
                <div
                    ref={datePickerRef}
                    className="tw-absolute tw-top-[50px] tw-right-0 tw-z-20 tw-bg-white tw-p-4 tw-border tw-border-gray-300 tw-rounded-md tw-shadow-lg"
                    style={{ width: "450px" }}
                >
                    <div className="tw-flex tw-gap-4">
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            placeholderText="Start Date"
                            className="tw-border tw-rounded-md tw-px-2 tw-text-black"
                        />
                        <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                            placeholderText="End Date"
                            className="tw-border tw-rounded-md tw-px-2 tw-text-black"
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export default FilterDropdown;
