import React, { useContext, useEffect, useState } from "react";
import ApplicationTable from "../../common/applicationTable/ApplicationTable";
import { UserContext } from '../../../contexts/UserContext';
import { AWSAmplifyConfigContext } from '../../../contexts/AWSAmplifyConfigContext';
import { useLoader } from '../../../contexts/LoaderContext';
import axios from 'axios';
import { formatPrice, capitalizeWords, formatDate } from '../../../utils/common';
import { Link } from 'react-router-dom'

const PaymentList = () => {
    const { user } = useContext(UserContext);
    const [paymentList, setPaymentList] = useState([]);
    const awsContextVal = useContext(AWSAmplifyConfigContext);
    const { showLoader, hideLoader } = useLoader();
    console.log("user", user);

    useEffect(() => {
        showLoader();
        const fetchPayments = async () => {
            try {
                const response = await axios.post(`${awsContextVal?.endpoint}/payment/list`, { user_id: user?.id }); // Replace with your API endpoint
                const data = response.data;
                setPaymentList(data.payments);

            } catch (error) {
                console.error('Error fetching payment data:', error);
            } finally {
                hideLoader();
            }
        };

        fetchPayments();
    }, []);

    const columns = [
        { field: 'created_at', headerName: 'Created At', width: 200, editable: false, headerAlign: 'center' },
        { field: 'user_name', headerName: 'Lender Name', width: 150, editable: false, headerAlign: 'center' },
        { field: 'customer_id', headerName: 'Customer ID', width: 200, editable: false, headerAlign: 'center' },
        { field: 'invoice_id', headerName: 'Invoice ID', width: 200, editable: false, headerAlign: 'center' },
        { field: 'product_name', headerName: 'Product Name', width: 150, editable: false, headerAlign: 'center' },
        { field: 'amount_total', headerName: 'Total Amount ($)', width: 150, editable: false, headerAlign: 'center' },
        { field: 'currency', headerName: 'Currency', width: 150, editable: false, headerAlign: 'center' },
        { field: 'payment_status', headerName: 'Payment Status', width: 200, editable: false, headerAlign: 'center' },
    ];

    const rows = paymentList?.length ? paymentList?.map((app) => ({
        id: app?.id,
        created_at: app?.created_at ? formatDate(app?.created_at) : null,
        user_name: app?.user_name,
        customer_id: app?.customer_id,
        invoice_id: app?.invoice_id,
        product_name: app.product_name,
        amount_total: app?.amount_total ? formatPrice(app?.amount_total / 100) : 0,
        currency: app.currency ? capitalizeWords(app.currency) : '',
        payment_status: app.payment_status ? capitalizeWords(app.payment_status) : '',
    })) : [];

    return (
        <div className="tw-w-full tw-h-full tw-my-[12px]">
            <div>
                <div className='tw-h-[60px] tw-flex tw-items-center'>
                    <Link to="/dashboard/lender">
                        <i className="fas fa-chevron-left tw-w-18.41px tw-h-15.41px tw-text-[#2A2F70]"></i>
                    </Link>
                    <h1 className='tw-ml-2 tw-text-[#000000] tw-font-bold tw-text-[40px] common-font'>
                        Payment List
                    </h1>
                </div>
                <p className='tw-text-[18px] tw-font-semibold common-font tw-text-[#202224] tw-opacity-70'>Here is the information about all your payment list.</p>
            </div>
            <div className='tw-w-full tw-h-[500px] tw-mt-[20px]'>
                <ApplicationTable dataTableProps={{
                    getRowId: (row) => row.id, // Use the unique id from your data
                    userStatus: [""],
                    dashboardType: 'payment',
                    title: "Payment",
                    getRowClassName: (params) =>
                        params?.row?.id === null ? 'selected-row' : ''
                }}
                    rows={rows}
                    columns={columns}
                    onRowClick={() => { }}
                    height="400px" />
            </div>
        </div>
    )
}
export default PaymentList;