import React, { useContext, useEffect, useState, forwardRef } from 'react';
import ChatWindow from './ChatWindow';
import { UserContext } from '../../../contexts/UserContext';
import axios from 'axios';
import { AWSAmplifyConfigContext } from '../../../contexts/AWSAmplifyConfigContext';
import toast from 'react-hot-toast';
import ChatUserList from './ChatUserList';
import { formatPrice } from '../../../utils/common';
import UserProfile from "../../../assets/images/user_profile_img.jpg";

const Chat = forwardRef(({
  application_id,
  setOpenSendOfferModal,
  fetchUserLoanApplications,
  setSelectedAppId,
  rowStatus,
  chatWindowRender
}, ref) => {
  const { user, setIsOfferSend } = useContext(UserContext);
  const awsContextVal = useContext(AWSAmplifyConfigContext);

  const [selectedConversationId, setSelectedConversationId] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [showTypingIndicator, setShowTypingIndicator] = useState(false);
  const [conversationList, setConversationList] = useState([]);
  const [chatUserName, setChatUserName] = useState('');
  const [last_conversation_message_id, set_last_conversation_message_id] = useState('');
  const [sortOrder, setSortOrder] = useState('DESC');
  const [conversationFilter, setConverSationFilter] = useState({
    interest_on_loan: '',
    loan_duration: '',
    fees: ''
  });

  // This is to get chat conversation list
  const getConversationList = async () => {
    if (!user) return;
    if (!application_id) return;

    try {
      const response = await axios.post(`${awsContextVal?.endpoint}/conversation/list`, {
        "application_id": application_id,
        'user_type': user?.user_type,
        'user_id': user?.id,
        'sort_by': 'm.message_id',
        'sort_order': sortOrder,
        ...conversationFilter
      });
      setConversationList(response?.data?.conversationList);
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data?.message);
      } else {
        toast.error("An error occurred while fetching loan applications.");
      }
    }
  };

  useEffect(() => {
    getConversationList();
  }, [conversationFilter]);

  const handleSortToggle = () => {
    const newSortOrder = sortOrder === 'ASC' ? 'DESC' : 'ASC';
    setSortOrder(newSortOrder);
    getConversationList();
  };

  useEffect(() => {
    if (chatWindowRender && application_id) {
      setChatMessages([]);
      getConversationList();
    }
  }, [chatWindowRender, application_id]);

  // Generate the chat list from conversationList
  const generateChatList = (conversationList) => {
    return conversationList.map((conversation) => {
      const isBorrower = user?.user_type === 'borrower';
      return {
        id: conversation?.conversation_id,
        name: isBorrower ? conversation?.lender_name : conversation?.borrower_name,
        message: conversation?.last_message,
        date: new Date(conversation?.created_at).toLocaleDateString(),
        profileImg: conversation?.lender_profile_image_url ? conversation?.lender_profile_image_url : UserProfile,
        isOnline: true,
        property_address: conversation?.property_address,
        loan_purpose: conversation?.loan_purpose,
        interest_on_loan: conversation?.interest_on_loan,
        fees: formatPrice(conversation?.fees),
        loan_duration: conversation?.loan_duration,
        loan_duration_type: (conversation?.loan_duration_type == 'monthly') ? "# Of Months" : "# Of Weeks"
      };
    });
  };

  const chatList = (() => {
    if (conversationList && conversationList.length > 0) {
      return generateChatList(conversationList);
    } else {
      return [
        {
          id: 'no-chat',
          name: "No Conversations",
          message: "No chat available for the selected application.",
          date: "",
          profileImg: null,
          isOnline: false,
        },
      ];
    }
  })();

  // Fetch chat messages based on conversation_id
  const fetchChatMessages = async (conversation_id) => {
    try {
      if (conversationList.length > 0) {
        const response = await axios.post(`${awsContextVal?.endpoint}/conversation/message`, {
          conversation_id,
          last_conversation_message_id,
        });

        const newMessages = response?.data?.messageList || [];

        setChatMessages((prevMessages) => {
          const existingMessageIds = new Set(prevMessages.map((msg) => msg.message_id));
          const filteredMessages = newMessages.filter((msg) => !existingMessageIds.has(msg.message_id));

          return [...prevMessages, ...filteredMessages];
        });
      } else {
        setChatMessages([]);
      }
    } catch (error) {
      console.error("Error fetching chat messages:", error);
    } finally {
      setShowTypingIndicator(false);
    }
  };

  useEffect(() => {
    const selectedChatMessages = chatMessages.filter(
      (message) => message.chat_id === selectedConversationId
    );

    if (selectedChatMessages.length > 0) {
      const lastMessageId = selectedChatMessages.reduce(
        (maxId, message) => (message?.message_id > maxId ? message?.message_id : maxId),
        0
      );

      set_last_conversation_message_id(lastMessageId);
    } else {
      set_last_conversation_message_id(0);
    }
  }, [chatMessages]);

  useEffect(() => {
    if (conversationList.length > 0) {
      if (selectedConversationId === '') {
        const firstConversationId = conversationList[0]?.conversation_id;
        const currentUserName = user?.user_type === "borrower" ? conversationList[0]?.lender_name : conversationList[0]?.borrower_name;
        setSelectedConversationId(firstConversationId);
        setChatUserName(currentUserName);
      }
    } else {
      setChatUserName('');
      setSelectedConversationId('');
      setChatMessages([]);
    }
  }, [conversationList]);

  useEffect(() => {
    let interval;
    if (selectedConversationId) {
      setChatMessages([]);
      fetchChatMessages(selectedConversationId);
      if (conversationList.length === 0) {
        fetchChatMessages(selectedConversationId);
      }
      interval = setInterval(async () => {
        try {
          await fetchChatMessages(selectedConversationId);
        } catch (error) {
          console.error('Error fetching chat messages:', error);
        }
        setTimeout(() => {
          setShowTypingIndicator(false);
        }, 3000);
      }, 10000);

      return () => {
        if (interval) clearInterval(interval);
      };
    }
  }, [selectedConversationId]);

  useEffect(() => {
    setSelectedConversationId('');
    setChatMessages([]);
    getConversationList();

    const intervalId = setInterval(() => {
      if (application_id) {
        getConversationList();
      }
    }, 30000);

    return () => clearInterval(intervalId);
  }, [application_id, conversationFilter]);

  useEffect(() => {
    const hasOffer = chatMessages.some((message) => message.is_offer > 0);
    if (hasOffer) {
      setIsOfferSend(true);
    } else {
      setIsOfferSend(false);
    }
  }, [chatMessages, setIsOfferSend]);

  return (
    <div ref={ref} className="tw-custom-container tw-resize tw-overflow-auto tw-mb-[20px]"
      style={{
        height: "100%",
        minHeight: "50%",
        maxHeight: "141vh",
        minWidth: "75%",
        maxWidth: "100%",
        overflow: "hidden"
      }}
    >
      <div className="tw-flex" style={{
        height: "100%",
        overflow: "hidden"
      }}>
        {user?.user_type === "borrower" ? (
          <ChatUserList
            chatList={chatList}
            setSelectedConversationId={setSelectedConversationId}
            selectedConversationId={selectedConversationId}
            setChatUserName={setChatUserName}
            handleSortToggle={handleSortToggle}
            sortOrder={sortOrder}
            conversationFilter={conversationFilter}
            setConverSationFilter={setConverSationFilter}
          />
        ) : null}

        <ChatWindow
          chatUserName={chatUserName}
          chatMessages={chatMessages}
          fetchChatMessages={fetchChatMessages}
          showTypingIndicator={showTypingIndicator}
          setShowTypingIndicator={setShowTypingIndicator}
          setOpenSendOfferModal={setOpenSendOfferModal}
          fetchUserLoanApplications={fetchUserLoanApplications}
          setSelectedAppId={setSelectedAppId}
          application_id={application_id}
          rowStatus={rowStatus}
        />
      </div>
    </div>
  );
});

export default Chat;
