import React, { useContext, useEffect, useRef, useState } from 'react'
import ChatProfile from "../../../assets/icons/Chat_profile-img.png";
import { UserContext } from '../../../contexts/UserContext';
import axios from 'axios';
import { AWSAmplifyConfigContext } from '../../../contexts/AWSAmplifyConfigContext';
import toast from 'react-hot-toast';
import { useLoader } from '../../../contexts/LoaderContext';
import OfferDetailModal from './OfferDetailModal';
import ChatHeader from './ChatHeader';
import ChatList from './ChatList';
import ChatFooter from './ChatFooter';

function ChatWindow({ chatUserName, chatMessages, fetchChatMessages, showTypingIndicator, setShowTypingIndicator, setOpenSendOfferModal, fetchUserLoanApplications, setSelectedAppId, application_id, rowStatus }) {
    const { user } = useContext(UserContext);
    const [message, setMessage] = useState("");
    const [offerDetails, setOfferDetails] = useState(null); // State to store offer details
    const [isModalOpen, setIsModalOpen] = useState(false);
    const awsContextVal = useContext(AWSAmplifyConfigContext);
    // State to track loading or error
    const [isSending, setIsSending] = useState(false);
    const { showLoader, hideLoader } = useLoader();
    const chatContainerRef = useRef(null);
    // API call to send the message
    const scrollToBottom = () => {
        if (!chatContainerRef.current) return; // Exit if the ref is null
        setTimeout(() => {
            chatContainerRef.current.scrollTo({
                top: chatContainerRef.current.scrollHeight,
                behavior: 'smooth',
            });
        }, 100);
    };

    // Optimized sendMessage with fast UI response
    const sendMessage = async () => {
        if (message.trim() === "") return;

        const newMessage = {
            conversation_id: chatMessages[0]?.conversation_id,
            sender_id: user?.id,
            message_text: message,
        };

        // Optimistically add the message to chatMessages
        const updatedMessages = [
            ...chatMessages,
            {
                ...newMessage,
                id: `temp-${Date.now()}`, // Temporary ID for optimistic UI
                createdAt: new Date().toISOString(),
            },
        ];

        setMessage("");
        setShowTypingIndicator(true);
        setIsSending(true);

        // Update chat list immediately and scroll to bottom
        fetchChatMessages(chatMessages[0]?.conversation_id, updatedMessages);
        scrollToBottom(); // Scroll after adding the message

        try {
            const response = await axios.post(
                `${awsContextVal?.endpoint}/message/send`,
                newMessage
            );

            if (response.status === 200) {
                fetchChatMessages(chatMessages[0]?.conversation_id);
                scrollToBottom(); // Scroll after fetching new messages
            } else {
                toast.error("Failed to send message");
            }
        } catch (error) {
            toast.error("Error sending message");
        } finally {
            setIsSending(false);
            setShowTypingIndicator(false);
        }
    };

    // Scroll to bottom when chatMessages or typing indicator changes
    useEffect(() => {
        scrollToBottom();
    }, [chatMessages, showTypingIndicator]);

    const handleViewOfferDetails = async (offerId) => {
        showLoader();
        try {
            const response = await axios.get(
                `${awsContextVal?.endpoint}/application-offer/get/${offerId}`
            );
            if (response?.status === 200) {
                setOfferDetails(response?.data);
                if (user?.user_type == "borrower") {
                    await axios.put(`${awsContextVal?.endpoint}/application-offer/mark-read/${offerId}`);
                    fetchUserLoanApplications();
                    setSelectedAppId(application_id);
                }
                setIsModalOpen(true);
            } else {
                toast.error('Something went wrong.');
            }
        } catch (err) {
            console.log("err:", err)
            toast.error(err?.response?.data?.error);
        } finally {
            hideLoader();
        }
    };

    const offerStatusHandler = async (application_offer_id, status) => {
        showLoader();
        try {
            const response = await axios.put(
                `${awsContextVal?.endpoint}/application-offer/update-status/${application_offer_id}`,
                {
                    status_id: status,
                    user_id: user?.id
                }
            );
            // console.log("response:", response)
            if (response.status == 200) {
                toast.success(response?.data?.message, 'success');
                fetchUserLoanApplications();
                setSelectedAppId(application_id);
            } else {
                toast.error('Something went wrong.');
            }
        } catch (error) {
            console.error("Error:", error);
        } finally {
            hideLoader();
            setTimeout(() => {
                setIsModalOpen(false);
            }, 500);
        }
    };

    return (
        <>
            <div style={{
                height: "100%",
                display: "flex",
                flex: 1,
                flexDirection: "column",
                overflow: "hidden",
                zIndex: 0
            }} className={`${user.user_type === 'borrower' ? 'tw-chat-window' : 'tw-w-full'}`}>
                {/* Chat header for chat window */}
                <ChatHeader chatUserName={chatUserName} />
                <div style={{
                    height: "100%",
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                    overflow: "hidden"
                }} className='tw-chat-section tw-bg-[#FAFAFA] tw-rounded-b-[10px]'>
                    <div style={{
                        display: 'flex',
                        height: "100%",
                        flexDirection: "column",
                        overflow: "hidden"

                    }} className='tw-chat-container tw-h-[613px] tw-rounded-[10px]'>
                        <div style={{
                            width: "100%",
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            overflow: "auto"
                        }}>
                            <ChatList
                                chatMessages={chatMessages}
                                handleViewOfferDetails={handleViewOfferDetails}
                                showTypingIndicator={showTypingIndicator}
                                chatContainerRef={chatContainerRef}
                            />
                        </div>
                        {chatMessages.length > 0 && (
                            <ChatFooter
                                sendMessage={sendMessage}
                                message={message}
                                setMessage={setMessage}
                                isSending={isSending}
                                setShowTypingIndicator={setShowTypingIndicator}
                                setOpenSendOfferModal={setOpenSendOfferModal}
                                rowStatus={rowStatus}
                            />
                        )}
                    </div>
                </div>
            </div>
            <OfferDetailModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                details={offerDetails}
                offerStatusHandler={offerStatusHandler}
            />
        </>
    )
}

export default ChatWindow