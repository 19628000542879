import { useEffect, useState } from 'react';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import FormControl from '@mui/material/FormControl';

const PhoneNumberInput = ({ value, onChange, disabled  }) => {
    const [inputValue, setInputValue] = useState(value);

    useEffect(() => {
        if (value) {
            setInputValue(value);
        }
    }, [value]);

    const handlePhoneChange = (phone) => {
        setInputValue(phone);
        onChange(phone);
    };

    return (
        <FormControl sx={{ mr: 2, width: '100%' }} variant="outlined">
            <PhoneInput
                country={'us'}
                placeholder="Enter phone number"
                value={inputValue}
                onChange={handlePhoneChange}
                disabled={disabled}
                inputStyle={{
                    color: disabled ? '#a9a9a9' : '#4d4d4d',
                    fontSize: '16px',
                    height: '55.99px'
                }}
                dropdownStyle={{ color: '#4d4d4d', borderRadius: '5px' }}
            />
        </FormControl>
    );
};

export default PhoneNumberInput;
