import React from 'react';
import BasicBadge from "../../assets/icons/basic-badge.png";
import PrimiumBadge from "../../assets/icons/pro-badge.png";
import SuperPremiumBadge from "../../assets/icons/premium-badge.png";

const ActiveSubscription = ({ plan, offer }) => {
    const badgeMap = {
        Basic: {
            badge: BasicBadge,
            bgColor: "tw-bg-[#8f969b]",
            offers: offer ?? "1",
        },
        Premium: {
            badge: PrimiumBadge,
            bgColor: "tw-bg-[#3f9afc]",
            offers: offer ?? "25",
        },
        "Super Premium": {
            badge: SuperPremiumBadge,
            bgColor: "tw-bg-[#5dbb72]",
            offers: offer === -1 ? "∞" : "∞",
        },
    };

    const currentPlan = badgeMap[plan];

    return (
        <div>
            {currentPlan && <div className="tw-flex tw-gap-1">
                <div
                    className={`tw-px-2 tw-py-3.5 tw-rounded-[60px] tw-flex tw-justify-center tw-items-center tw-h-[20px] ${currentPlan?.bgColor}`}
                >
                    {plan && currentPlan?.badge && (
                        <img
                            src={currentPlan?.badge}
                            alt={plan}
                            className="tw-rounded-full tw-w-[16px] tw-h-[16px] tw-object-cover"
                        />
                    )}
                    <div
                        className={`tw-ml-2 tw-font-inter tw-text-[14px] tw-font-[700] tw-text-white`}
                    >
                        {plan}
                    </div>
                </div>
                <div
                    className={`tw-font-inter tw-text-[14px] tw-font-[700] ${currentPlan?.bgColor} tw-px-4 tw-min-w-[35px] tw-py-3.5 tw-rounded-[60px] tw-flex tw-justify-center tw-items-center tw-h-[20px]`}
                >
                    {currentPlan?.offers}
                </div>
            </div>}
        </div>
    );
};

export default ActiveSubscription;
