import React, { useState, useEffect, useContext } from 'react';
import '../../../styles/Header.css';
import LogoImage from "../../../assets/icons/Logo_blue.png";
import BorrowerSignupIcon from "../../../assets/icons/borrowerIcon.svg";
import LenderSignupIcon from "../../../assets/icons/lenderIcon.svg";
import SignupOptionsModal from '../../modals/SignupOptionsModal';
import SignupModal from '../../modals/SignupModal';
import ValidationModal from '../../modals/ValidationModal';
import LoginModal from '../../modals/LoginModal';
import { signOut } from '@aws-amplify/auth';
import { Link, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import AnimatePulse from '../../loaders/AnimatePulse';
import { UserContext } from '../../../contexts/UserContext';
import axios from 'axios';
import { AWSAmplifyConfigContext } from '../../../contexts/AWSAmplifyConfigContext';

function Header({isUserLoggedIn }) {
  const { setUserType } = useContext(UserContext);
  const awsContextVal = useContext(AWSAmplifyConfigContext)
  const navigate = useNavigate();
  const { setUser, resetUser } = useContext(UserContext);

  const [showSignupOptionsModal, setShowSignupOptionsModal] = useState(false); // State to control modal visibility
  const [showSignupFormModal, setShowSignupFormModal] = useState(false);
  const [showSigninFormModal, setShowSigninFormModal] = useState(false);
  const [showValidationModal, setShowValidationModal] = useState(false);
  const [signupRole, setSignupRole] = useState(''); // borrower or lender
  const [loggedIn, setLoggedIn] = useState(window.localStorage.getItem("re_loan_info") || 0);
  const [showLoader, setShowLoader] = useState(false);
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState(window.localStorage.getItem("user_email") || "");
  const openSignupOptionsModal = () => setShowSignupOptionsModal(true);

  const closeSignupOptionsModal = (role) => {
    if (role) {
      setShowSignupFormModal(true);
    }
    setSignupRole(role);
    setShowSignupOptionsModal(false);
  };

  const closeSignupFormModal = (success = false) => {
    setShowSignupFormModal(false)

    if (success) {
      setShowValidationModal(true);
    }
  };

  const openSigninFormModal = () => {
    setSignupRole('');
    setShowSignupOptionsModal(false);
    setShowSignupFormModal(false);
    setShowSigninFormModal(true);
  }

  const closeSigninFormModal = () => setShowSigninFormModal(false);

  const closeValidationModal = (success = false) => {
    setShowValidationModal(false);
    if (success) {
      setShowSigninFormModal(true);
    }
  };

  const handleLogOut = async () => {
    try {
      await signOut();
      toast.success("Signed Out!");
      window.localStorage.removeItem("re_loan_info");
      window.localStorage.removeItem("user_email");
      resetUser();
      setUserName('');
      setUserEmail('');
      setLoggedIn(0);
      navigate("/");
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  const goToHome = () => {
    navigate("/");
  }

  const goToDashboard = () => {
    navigate("/dashboard");
  }

  const setUpUserContext = async () => {
    setShowLoader(true);
    try {

      console.log("userEmail", userEmail);
      const _user = await axios.post(`${awsContextVal?.endpoint}/fetch-user`, { email: userEmail }).then(response => {
        return response.data;
      })
        .catch(err => {
          if (err.response) {
            toast.error(err.response.data?.message)
          }
        });
      console.log("_user", _user)

      // const _user = post('apiGateway', '/fetch-user', {body: {email: userEmail}});
      // console.log(_user);
      const _userFromRDS = typeof _user.user === 'string' ? JSON.parse(_user.user) : _user.user;

      if (_userFromRDS) {
        console.log("_userFromRDS", _userFromRDS)
        setUser(_userFromRDS);
        setUserName(_userFromRDS.first_name);
        setSignupRole(_userFromRDS.user_type);
        setShowLoader(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('Error Fetching User Data!')
      setShowLoader(false);
    }
  }

  useEffect(() => {
    if (userEmail && loggedIn) {
      setUpUserContext();
    }
  }, [userEmail, loggedIn])

  return (
    <>
      <header className="tw-flex tw-flex-col lg:tw-space-y-0 lg:tw-flex-row tw-items-center tw-mx-20 tw-pt-[34px]">
        <div className='tw-flex tw-justify-center tw-cursor-pointer' onClick={goToHome}>
          <img src={LogoImage} className='tw-w-[168px]' alt='re-loan logo' />
        </div>

        {
          showLoader
            ? <div className='tw-flex-1 tw-flex tw-justify-end'><AnimatePulse /></div>
            : <div className='tw-flex-1 tw-flex tw-justify-end tw-items-center'>
              {!isUserLoggedIn ? (
                <div className='tw-flex tw-gap-x-4 tw-justify-evenly tw-items-center'>
                  <Link to={`/sign-in`} tabIndex={-1}>
                    <button className='tw-flex'
                    // onClick={openSigninFormModal}
                    >
                      <p className='tw-text-md tw-text-black tw-font-oswald'>Sign In</p>
                    </button>
                  </Link>

                  <div className='tw-border-[#4853E4] tw-h-8 tw-w-1 tw-border-2'></div>
                  <div className='tw-flex tw-gap-x-2 tw-justify-center'>
                    <Link to={"/sign-up/borrower"} tabIndex={-1}>
                      <button
                        // onClick={openSignupOptionsModal}
                        onClick={() => setUserType('borrower')}
                        className='tw-flex tw-gap-x-1 tw-items-center tw-p-1 tw-border-[1px] tw-drop-shadow-lg tw-rounded-3xl hover:tw-border-[#7DDE92] hover:tw-drop-shadow-xl'
                      >
                        <img src={BorrowerSignupIcon} className='tw-w-6 tw-h-6' alt='borrower' />
                        <p className='tw-text-md tw-text-black tw-font-oswald'>Borrower Signup</p>
                      </button>
                    </Link>
                    <Link to={"/sign-up/lender"} tabIndex={-1}>
                      <button
                        onClick={() => setUserType('lender')}
                        className='tw-flex tw-gap-x-1 tw-items-center tw-p-1 tw-border-[1px] tw-drop-shadow-lg tw-rounded-3xl hover:tw-border-[#4853E4] hover:tw-drop-shadow-xl'
                      >
                        <img src={LenderSignupIcon} className='tw-w-6 tw-h-6' alt='lender' />
                        <p className='tw-text-md tw-text-black tw-font-oswald'>Lender Signup</p>
                      </button>
                    </Link>
                  </div>
                </div>
              ) : (
                <Link to={"/sign-up/borrower"} tabIndex={-1}>
                  <button
                    // onClick={openSignupOptionsModal}
                    className='tw-flex tw-gap-x-1 tw-items-center tw-p-1 tw-px-3 tw-border-[1px] tw-drop-shadow-lg tw-rounded-3xl hover:tw-border-[#7DDE92] hover:tw-drop-shadow-xl'
                  >
                    <img src={BorrowerSignupIcon} className='tw-w-6 tw-h-6' alt='dashboard' />
                    <p className='tw-text-md tw-text-black tw-font-oswald'>Dashboard</p>
                  </button>
                </Link>
              )}
            </div>
        }
      </header>
      {loggedIn
        ? showLoader
          ? <div className='tw-flex-1 tw-flex tw-justify-center'><AnimatePulse /></div>
          : <div className='tw-flex tw-justify-center tw-font-oswald tw-text-[#4853e4] tw-cursor-pointer' onClick={goToDashboard}>
            <h2 className='tw-text-2xl'>{`${signupRole.toLocaleUpperCase()} DASHBOARD`}</h2>
          </div>
        : null}
      {/* Render the Signup Options Modal */}
      <SignupOptionsModal showSignupOptionsModal={showSignupOptionsModal} onClose={closeSignupOptionsModal} />

      {/* Render the Signup Form Modal */}
      <SignupModal role={signupRole} isOpen={showSignupFormModal} onClose={closeSignupFormModal} />

      {/* Render the SignIn Form Modal */}
      <LoginModal
        isOpen={showSigninFormModal}
        onClose={closeSigninFormModal}
      />

      {/* Render the Validation Modal */}
      <ValidationModal isOpen={showValidationModal} onClose={closeValidationModal} />
    </>
  );
}

export default Header;
