import React from 'react'
import { Routes, Route } from 'react-router-dom';

import Auth from './Auth'
import Dashboard from './Dashboard'
import { ModalProvider } from '../contexts/ModalContexts';

const Routers = () => {
  return (
      <ModalProvider>
        <Routes>
          <Route path="dashboard/*" element={<Dashboard />} />
          <Route path="/*" element={<Auth />} />
          <Route path="*" element={<>It is 404</>} />
        </Routes>
        </ModalProvider>
  )
}

export default Routers