import React, { memo } from 'react';

function FilterInputs({ inputs }) {
    return (
        <div className='tw-w-[265px] tw-ms-[20px] tw-mt-[20px]'>
            {inputs.map((input, idx) => (
                <input
                    key={idx}
                    type="text"
                    name={input.name}
                    value={input.value}
                    onChange={input.onChange}
                    className={`tw-w-[48%] tw-h-[40px] tw-border tw-border-[#DDDDDD] tw-rounded-md tw-px-2 tw-text-black ${
                        idx === 0 ? 'tw-mr-[4%]' : ''
                    }`}
                    placeholder={input.placeholder}
                />
            ))}
        </div>
    );
}

export default memo(FilterInputs);
