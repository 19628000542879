import { Link } from "react-router-dom";
import LoginIcon from "../../../assets/icons/borrowerIcon.svg";
import ProductImage1 from "../../../assets/images/product_image_1.png";
import ProductImage2 from "../../../assets/images/product_image_2.png";
import GenericButton from "../../common/buttons/GenericButton";
import { useContext } from "react";
import { UserContext } from "../../../contexts/UserContext";

const ProductArea = ({ isUserLoggedIn }) => {
    const { setUserType } = useContext(UserContext);

    return (
        <div className="tw-w-full">
            <div className="tw-flex tw-flex-col tw-justify-center">
                <div className="tw-flex tw-justify-center tw-items-center">
                    <span className=" tw-w-8 tw-h-px tw-border-t tw-border-[0.5px] tw-border-blue-400"></span>
                    <span className="tw-font-inter tw-px-2 tw-text-black tw-text-lg tw-font-normal tw-text-center">Product</span>
                    <span className=" tw-w-8 tw-h-px tw-border-t tw-border-[0.5px] tw-border-blue-400"></span>
                </div>

                <div className="tw-flex tw-flex-col tw-my-4">
                    <h2 className="tw-font-oswald tw-text-4xl tw-text-black tw-leading-10 lg:tw-text-5xl tw-font-bold tw-text-center">Seamless connection between bridge loan</h2>
                     
                    <h2 className="tw-font-oswald tw-text-4xl tw-leading-10 lg:tw-text-5xl tw-font-bold tw-text-center tw-text-[#4853e4]">lenders and borrowers.</h2>
                </div>

                <div className="tw-flex tw-items-center tw-my-8">
                    <div className="tw-w-1/2 tw-flex-col tw-m-10 tw-space-y-4">
                        <h2 className="tw-font-oswald tw-text-2xl tw-text-[#191919] tw-font-bold">Lenders love Re-Loan</h2>
                        <p className="tw-font-inter tw-text-lg tw-text-[#949494]">Re-Loan equips lenders with the essential tools to streamline loan reviews, access comprehensive borrower insights, and communicate with borrowers. Now they can make faster and less risky lending decisions, processing more loans more profitably.</p>
                        {!isUserLoggedIn && (
                            <div>
                                <Link to={`/sign-in`} tabIndex={-1}>
                                    <GenericButton icon={LoginIcon} title='Log In As Lender' 
                                    onClick={() => setUserType('lender')} />
                                </Link>
                            </div>
                        )}
                    </div>

                    <img className="tw-w-1/2" src={ProductImage1} alt="Lenders Love Our Services" loading="lazy" />
                </div>
            </div>
            <div className="tw-flex tw-flex-col tw-justify-center">

                <div className="tw-flex tw-items-center tw-my-8">
                    <img className="tw-w-1/2" src={ProductImage2} alt="Borrowers Thrive with Our Easy Process" loading="lazy" />

                    <div className="tw-w-1/2 tw-flex-col tw-m-10 tw-space-y-4">
                        <h2 className="tw-font-oswald tw-text-2xl tw-text-[#191919] tw-font-bold">Borrowers thrive on Re-Loan</h2>
                        <p className="tw-font-inter tw-text-lg tw-text-[#949494]">At Re-Loan, we prioritize borrower convenience and savings. Submit loan applications, track progress, and directly communicate with multiple lenders—all in one place. With a personalized dashboard, borrowers access real-time updates, compare offers, and make informed decisions. Get the best bridge loan options without unnecessary delays, broker commissions, hidden costs, or headaches.
                        </p>
                        {!isUserLoggedIn && (
                            <div>
                                <Link to={`/sign-in`} tabIndex={-1}>
                                    <GenericButton icon={LoginIcon} title='Log In As Borrower'
                                        onClick={() => setUserType('borrower')}  />
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductArea;