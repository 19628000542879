import React, { useEffect, useContext, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AWSAmplifyConfigContext } from '../../contexts/AWSAmplifyConfigContext';
import { UserContext } from '../../contexts/UserContext';
import axios from 'axios';
import toast from "react-hot-toast";
import { signUp } from "@aws-amplify/auth";
import { useLoader } from '../../contexts/LoaderContext';

const PackageSuccessPage = () => {
    const awsContextVal = useContext(AWSAmplifyConfigContext);
    const { signUpUser, userType } = useContext(UserContext);
    const { showLoader, hideLoader } = useLoader();
    const location = useLocation();
    const isCalled = useRef(false);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const sessionIdFromUrl = params.get('session_id');

        if (sessionIdFromUrl && !isCalled.current) {
            isCalled.current = true; // Set to true after first call
            fetchSessionDetails(sessionIdFromUrl);
        }
    }, [location.search]);

    const fetchSessionDetails = async (sessionId) => {
        try {
            showLoader()
            // Fetch session details using axios
            const response = await axios.get(`${awsContextVal?.endpoint}/payment/session-details?session_id=${sessionId}`);

            // Check if session details are returned
            if (response.status === 200 && response.data) {
                const session = response.data;
                // check if payment status success
                if (session.session.payment_status === 'paid' && session.session.status === 'complete') {
                    handleSignUpClick(session);
                    hideLoader();
                } else {
                    navigate('/sign-up/lender');
                    toast.error("Payment failed please sign up again.");
                    console.error('No session data found');
                }
            } else {
                throw new Error('Failed to fetch valid session details');
            }
        } catch (error) {
            console.error('Error fetching session details or making payment success API call:', error);
        }
    };


    const handleSignUpClick = async (session) => {
        try {
            const { userId } = await signUp({
                username: signUpUser.email,
                password: signUpUser.password,
                options: {
                    userAttributes: {
                        email: signUpUser.email,
                        phone_number: signUpUser.phone,
                        name: `${signUpUser.firstName} ${signUpUser.lastName}`,
                        'custom:user_type': userType,
                        'custom:FirstName': signUpUser.firstName,
                        'custom:LastName': signUpUser.lastName,
                    },
                },
            });

            if (userId) {
                const createUserResponse = await axios.post(`${awsContextVal?.endpoint}/create-user`,
                    {
                        email: signUpUser.email,
                        phone_number: signUpUser.phone,
                        name: signUpUser.firstName + " " + signUpUser.lastName,
                        'custom:user_type': userType,
                        'custom:FirstName': signUpUser.firstName,
                        'custom:LastName': signUpUser.lastName
                    }
                );

                // Check if the response is successful (status 200)
                let user_id = '';
                if (createUserResponse.status === 200) {
                    hideLoader();
                    user_id = createUserResponse.data.id;
                    // You can handle the successful response here

                    const paymentData = {
                        user_id: user_id,
                        product: session.product,
                        session: session.session
                    };

                    await axios.post(
                        `${awsContextVal?.endpoint}/payment/success`,
                        paymentData
                    );
        
                } else {
                    toast.error(`Unexpected response status: ${createUserResponse.status}` || "Cannot sign up user.");
                    console.log('Unexpected response status:', createUserResponse.status);
                }
            }
        }
        catch (err) {
            toast.error(err.message || "Cannot sign up user.");
            console.log(err);
        } finally {
            hideLoader(); // Ensure the loader is hidden even if there's an error in this function
        }
    };

    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate(`/otp-verify`);
    }


    return (
        <div className="tw-min-h-screen tw-flex tw-items-center tw-justify-center tw-bg-gray-100 tw-p-4">
            <div className="tw-bg-white tw-p-8 lg:tw-p-20 md:tw-p-14 sm:tw-p-8 tw-rounded-lg tw-shadow-lg tw-text-center tw-w-full tw-max-w-[90%] lg:tw-max-w-[30%] md:tw-max-w-[50%] sm:tw-max-w-full">
                <div className="tw-flex tw-justify-center">
                    <div className="tw-mb-4 tw-flex tw-justify-center tw-items-center tw-bg-green-100 tw-w-[100px] tw-h-[100px] tw-rounded-full">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="tw-w-20 tw-h-24 tw-text-green-500"
                        >
                            <path d="M20 6L9 17l-5-5" />
                        </svg>
                    </div>
                </div>
                <h1 className="tw-text-3xl tw-font-extrabold tw-text-green-600">Payment Success</h1>
                <p className="tw-text-lg tw-text-gray-700 tw-mt-4">
                    Thank you for your payment! Your transaction has been successfully completed.
                </p>
                <div className="tw-mt-8">
                    <button onClick={handleNavigate} className="tw-bg-green-500 tw-text-white tw-font-semibold tw-px-8 tw-py-4 tw-rounded-full hover:tw-bg-green-600">
                        Continue
                    </button>
                </div>
            </div>
        </div>
    )
}
export default PackageSuccessPage;