import React, { memo, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from './Modal';
import { AWSAmplifyConfigContext } from '../../../../contexts/AWSAmplifyConfigContext';
import { useLoader } from '../../../../contexts/LoaderContext';
import { UserContext } from '../../../../contexts/UserContext';
import axios from 'axios';
import ApplicationTable from '../../../common/applicationTable/ApplicationTable';
import toast from 'react-hot-toast';
import { useModalContext } from '../../../../contexts/ModalContexts';
import { Button, Menu, MenuItem, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { formatTimeDifference } from '../../../../utils/common';

const ActionModal = memo(({ setHidden, setCmsData, setIsModalOpen, fetchCmsDetail, ...params }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [openConfirm, setOpenConfirm] = useState(false);
    const awsContextVal = useContext(AWSAmplifyConfigContext);
    const { showLoader, hideLoader } = useLoader();
    const { showModal } = useModalContext();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleConfirmOpen = () => {
        setOpenConfirm(true);
    };

    const handleConfirmClose = () => {
        setOpenConfirm(false);
    };

    const deleteCmsDetailHandler = async (params) => {
        showLoader();
        try {
            const response = await axios.delete(
                `${awsContextVal?.endpoint}/cms/delete/${params?.id}`
            );
            if (response?.status === 200) {
                showModal(response?.data?.message, "success");
            } else if (response?.status === 500) {
                showModal("Something Went Wrong.", "fail");
            }
            fetchCmsDetail();
            setAnchorEl(null);
        } catch (err) {
            console.error('Error deleting user:', err);
            showModal('Failed to delete Cms. Please try again.', "fail");
        } finally {
            hideLoader();
        }
    };

    // // Confirm deletion function
    const handleDeleteConfirmation = () => {
        deleteCmsDetailHandler(params?.row);
        setOpenConfirm(false);
    };

    const handleEditCmsData = async (params) => {
        showLoader();
        try {
            const response = await axios.get(
                `${awsContextVal?.endpoint}/cms/get/${params?.id}`
            );

            if (response?.status === 200) {
                const responseCmsDetail = response?.data?.cms;
                console.log("responseCmsDetail:", responseCmsDetail);
                setCmsData({
                    title: responseCmsDetail?.title || '',
                    slug: responseCmsDetail?.slug || '',
                    description: responseCmsDetail?.description || '',
                });
                setHidden(responseCmsDetail?.id);
                setIsModalOpen(true);
            } else if (response?.status === 500) {
                showModal("Something Went Wrong.", "fail");
            }
            fetchCmsDetail();
            setAnchorEl(null);
        } catch (err) {
            console.error('Error deleting user:', err);
        } finally {
            hideLoader();
        }
    }

    return <>
        <button className=" tw-text-black tw-rounded tw-px-2 tw-py-1"
            onClick={handleClick}
        >
            <i className="fas fa-ellipsis-v tw-text-[#2A2F70] tw-text-md tw-cursor-pointer tw-opacity-80 tw-pr-2"></i>
        </button>
        <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <MenuItem onClick={handleConfirmOpen}>Delete</MenuItem>
            <MenuItem onClick={() => handleEditCmsData(params?.row)}>Edit</MenuItem>
        </Menu>
        <Dialog
            open={openConfirm}
            onClose={handleConfirmClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete this Cms?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleConfirmClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleDeleteConfirmation} color="secondary" autoFocus>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    </>
});

function ContentManagement() {
    const { user } = useContext(UserContext);
    const awsContextVal = useContext(AWSAmplifyConfigContext);
    const { showLoader, hideLoader } = useLoader();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [hidden, setHidden] = useState(null);
    const [errors, setErrors] = useState({});
    const [cmsData, setCmsData] = useState({
        title: '',
        slug: '',
        description: '',
    });
    const [fetchCmsData, setFetchCmsData] = useState([]);

    const openModal = () => {
        setCmsData({
            title: '',
            slug: '',
            description: '',
        });
        setHidden(null);
        setIsModalOpen(true);
    };
    const closeModal = () => setIsModalOpen(false);

    const validate = (data) => {
        const newErrors = {};
        if (!data.title || !data.title.trim()) newErrors.title = 'Title is required.';
        if (!data.slug || !data.slug.trim()) newErrors.slug = 'Slug is required.';
        if (!data.description || !data.description.trim()) newErrors.description = 'Description is required.';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (data) => {
        // Validate input data before proceeding
        if (!validate(data)) {
            hideLoader();
            toast.error(errors);
            return;
        }

        showLoader();

        const payload = {
            title: data?.title,
            slug: data?.slug,
            description: data?.description,
        };

        const endpoint = hidden ?
            `${awsContextVal?.endpoint}/cms/update/${hidden}` :
            `${awsContextVal?.endpoint}/cms/create`;

        const method = hidden ? axios.put : axios.post;

        try {
            const response = await method(endpoint, payload);
            const message = response?.data?.message || 'Operation successful';
            toast.success(message);

            // Additional cleanup or actions after success
            closeModal();
            fetchCmsDetail();
        } catch (err) {
            console.error("Error during CMS operation:", err);
            const errorMessage = err.response?.data?.message || "Something went wrong. Please try again.";
            toast.error(errorMessage);
        } finally {
            hideLoader();
        }
    };

    const columns = [
        {
            field: 'title',
            headerName: 'Title',
            width: 180,
            editable: false,
        },
        {
            field: 'slug',
            headerName: 'Slug',
            width: 350,
            editable: false,
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 548,
            editable: false,
        },
        {
            field: 'created_at',
            headerName: 'Created At',
            width: 145,
            editable: false,
            renderCell: (params) => {
                var formattedTimes = [];
                const formattedTime = formattedTimes.find((item) => item.application_id === params.row.application_id)?.formattedTime;
                return formattedTime || formatTimeDifference(params.value);
            },
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 100,
            renderCell: (params) => (
                <>
                    <ActionModal
                        setIsModalOpen={setIsModalOpen}
                        fetchCmsDetail={fetchCmsDetail}
                        setCmsData={setCmsData}
                        setHidden={setHidden}
                        {...params}
                    />
                </>
            ),
        },
    ];

    const fetchCmsDetail = async () => {
        showLoader();
        if (!user) return;

        try {
            const response = await axios.post(`${awsContextVal?.endpoint}/cms/list`);
            const cmsData = response?.data?.cms || [];

            setFetchCmsData(cmsData);

        } catch (err) {
            if (err.response) {
                toast.error(err.response.data?.message);
            } else {
                toast.error("An error occurred while fetching loan applications.");
            }
        } finally {
            hideLoader();
        }
    };
    useEffect(() => {
        fetchCmsDetail();
    }, [user]);

    const rows = fetchCmsData?.length ? fetchCmsData?.map((app) => ({
        id: app?.id,
        title: app?.title,
        slug: app?.slug,
        description: app?.description,
        created_at: app?.created_at
    })) : [];

    const handleRowClick = (params) => {
        // console.log("params:", params)
    };

    return (
        <div className="tw-w-full tw-h-full">
            <header className="tw-flex tw-justify-between tw-items-center tw-py-4">
                <main>
                    <div className="tw-flex tw-gap-4 tw-items-center">
                        <Link to={`/dashboard/admin`}>
                            <i className="fas fa-chevron-left tw-w-[18.41px] tw-h-[15.41px] tw-text-[#2A2F70]"></i>
                        </Link>
                        <h1 className="tw-text-[#000000] tw-font-bold tw-text-[40px] common-font">
                            Content Management
                        </h1>
                    </div>
                    <p className="tw-text-[18px] tw-font-semibold common-font tw-text-[#202224] tw-opacity-70">
                        Here is the information about all your Cms Pages.
                    </p>
                </main>

                <button
                    tabIndex={0}
                    className="tw-w-[200.59px] tw-h-[49px] tw-border tw-border-[#2A2F70] tw-rounded-[10px] tw-flex tw-justify-center tw-items-center"
                    onClick={openModal}
                >
                    <span className="tw-w-[24px] tw-h-[24px] tw-flex tw-items-center tw-justify-center tw-rounded-md tw-border tw-border-[#2A2F70] tw-me-[10px]">
                        <i className="fas fa-plus-circle tw-text-[16px] tw-text-[#2A2F70]"></i>
                    </span>
                    <h2 className="tw-text-[18px] tw-font-normal common-font tw-text-[#2A2F70]">
                        Create Cms Pages
                    </h2>
                </button>
            </header>
            <div className='tw-w-full tw-h-[500px] tw-my-[20px]'>
                <ApplicationTable
                    dataTableProps={{
                        getRowId: (row) => row.id, // Use the unique id from your data
                        userStatus: [""],
                        dashboardType: 'cms',
                        title: "Content Management System",
                        getRowClassName: (params) =>
                            params?.row?.id === null ? 'selected-row' : ''
                    }}
                    rows={rows}
                    onRowClick={handleRowClick}
                    columns={columns}
                    tableHeight="200px"
                    maxHeight='140vh'
                    minHeight='400px'
                />
            </div>


            {isModalOpen && <Modal cmsData={cmsData} closeModal={closeModal} onSubmit={handleSubmit} errors={errors} />}
        </div>
    );
}

export default ContentManagement;