import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import Header from "../layouts/homepage/Header";
import CmsContent from "./CmsContent";
import { authLocal } from "../../utils/common";
import { AWSAmplifyConfigContext } from "../../contexts/AWSAmplifyConfigContext";
import axios from 'axios';

function CmsPage() {
    const { slug } = useParams();
     const awsContextVal = useContext(AWSAmplifyConfigContext);
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
    const [cmsData, setCmsData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Check if the user is logged in
        const userData = authLocal.get();
        setIsUserLoggedIn(userData && userData.email ? true : false);
    }, []);

    useEffect(() => {
        // Fetch CMS content from the API
        const fetchCmsContent = async () => {
            setLoading(true);
            setError(null);

            try {
                const response = await axios.get(`${awsContextVal?.endpoint}/cms/get-with-slug/${slug}`);
               
                const data = await response?.data?.cms;

                setCmsData(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchCmsContent();
    }, [slug]);

    const handleUserTypeChange = (type) => {
        console.log("User type changed to:", type);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!cmsData) {
        return <div>No content available for this page.</div>;
    }

    return (
        <div className="tw-relative">
            <Header isUserLoggedIn={isUserLoggedIn} handleUserTypeChange={handleUserTypeChange} />
            <div className="tw-mt-10 tw-flex tw-justify-center">
                <CmsContent title={cmsData?.title} description={cmsData?.description} />
            </div>
        </div>
    );
}

export default CmsPage;
