import { Link } from "react-router-dom";
import ProfileIcon from "../../assets/icons/profileIcon.png";
import ChangePasswordForm from "./ChangePasswordForm";

const AdminSettings = () => {
    return (
        <div className="tw-flex tw-flex-col tw-w-full">
            <div className='tw-w-[377px] tw-h-[60px] tw-flex tw-items-center tw-my-1'>
                <Link to="/dashboard/admin">
                    <i className="fas fa-chevron-left tw-w-[18.41px] tw-h-[15.41px] tw-text-[#2A2F70]"></i>
                </Link>
                <h1 className='tw-text-[#000000] tw-font-bold tw-text-[40px] common-font'>
                    Profile Settings
                </h1>
            </div>
            <p className='tw-text-lg tw-text-[#202224] tw-font-nunito tw-font-light'>
                Here is the information about all your orders.
            </p>

            <ChangePasswordForm />
        </div>
    )
};

export default AdminSettings;