import {
    SIGN_UP_REQUEST,
    SIGN_UP_SUCCESS,
    SIGN_UP_FAILURE,
    CLEAR_SIGN_UP_ERRORS
} from '../../utils/constants';

const initialState = {
    loading: false,
    user: null,
    error: null,
};

const signUpReducer = (state = initialState, action) => {
    switch (action.type) {
        case SIGN_UP_REQUEST:
            return { ...state, loading: true, error: null };
        case SIGN_UP_SUCCESS:
            return { ...state, loading: false, user: action.payload };
        case SIGN_UP_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case CLEAR_SIGN_UP_ERRORS:
            return { ...state, error: null };
        default:
            return state;
    }
};

export default signUpReducer;
