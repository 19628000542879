import React, { useContext, useState, useEffect } from 'react';
import {
    Popover,
    PopoverHandler,
    PopoverContent,
    Typography,
    Button,
    List,
    ListItem
} from "@material-tailwind/react";

function UserManagementHeader({title, userStatus, handleItemClick, setFilter, filter}) {
    const [isOpen, setIsOpen] = useState(false);
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleSortChange = (type) => {
        let newSortOrder = '';
        
        if (type === 'oldFirst') {
            newSortOrder = 'ASC';  // Set sort order to ASC for "Old First"
        } else if (type === 'newFirst') {
            newSortOrder = 'DESC'; // Set sort order to DESC for "New First"
        }

        setFilter((prevFilters) => ({
            ...prevFilters,
            sort_order: newSortOrder, 
        }));
    };

    const handleStatusClick = (id) => {
        setFilter((prevFilters) => ({
            ...prevFilters,
            status: id,
        }));
    }

    return (
        <div className='tw-h-[40px] tw-mx-[20px] tw-my-[20px] tw-flex'>
            <>
                {title && title != '' ?
                    <>
                        <Typography className='tw-text-[#202224] common-font tw-text-[22px] tw-font-semibold'> {title}</Typography>
                    </>
                    :

                    <div className='tw-flex-grow-[4] tw-border-b-[1px] tw-border-[#DDDDDD] tw-items-center tw-pl-3'>
                        <i className="fas fa-search tw-text-[#4F4F4F] tw-mr-2 tw-opacity-70"></i>
                        <input
                            type="text"
                            name="search_text"
                            placeholder="Search application here..."
                            className="tw-text-[#4F4F4F] common-font tw-text-[14px] tw-font-normal tw-italic tw-bg-transparent tw-border-none focus:tw-outline-none"
                            onChange={(e) => {
                                const { value } = e.target;
                                setFilter((prevFilters) => ({
                                    ...prevFilters,           // Spread the previous filters to preserve them
                                    search_text: value,       // Update the search_text field
                                }));
                            }}
                        />
                    </div>
                }
            </>
            <div className='tw-flex-grow-[6] tw-flex tw-justify-end'>
                <div className='tw-flex tw-space-x-4'>

                    <div className='tw-relative tw-flex tw-items-center tw-justify-center tw-w-[100px] tw-border tw-border-[#DDDDDD] tw-rounded-[5px] tw-bg-[#FFFFFF]'>
                        <div
                            className='tw-w-full tw-cursor-pointer tw-flex tw-items-center tw-justify-center tw-gap-x-2'
                            onClick={toggleDropdown}
                        >
                            <i className="fas fa-sort-alpha-down tw-text-[#4F4F4F]"></i>
                            <span className='tw-text-[#4F4F4F]'>Sort</span>
                        </div>

                        {/* Dropdown Options */}
                        {isOpen && (
                            <div className='tw-absolute tw-w-full tw-mt-28 tw-border tw-border-[#DDDDDD] tw-rounded-b-[5px] tw-bg-[#FFFFFF] tw-z-10 fadeIn'>
                                {/* Old First */}
                                <div className='tw-py-[8px] tw-cursor-pointer tw-flex tw-items-center tw-justify-center tw-gap-x-2 hover:tw-bg-gray-100 tw-border-b-[2px]'
                                    onClick={() => handleSortChange('oldFirst')}>
                                    <i className="fas fa-sort-down tw-text-[#4F4F4F]"></i>
                                    <span className='tw-text-[#4F4F4F]'>Old First</span>
                                </div>

                                {/* New First */}
                                <div className='tw-py-[8px] tw-cursor-pointer tw-flex tw-items-center tw-justify-center tw-gap-x-2 hover:tw-bg-gray-100'
                                    onClick={() => handleSortChange('newFirst')}>
                                    <i className="fas fa-sort-up tw-text-[#4F4F4F]"></i>
                                    <span className='tw-text-[#4F4F4F]'>New First</span>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className='tw-relative tw-flex tw-px-4 tw-h-full tw-border tw-border-[#DDDDDD]     tw-rounded-[5px] tw-bg-[#FFFFFF] tw-items-center'>
                        <Popover placement="bottom">
                            <PopoverHandler>
                                <Button className='tw-text-[#4F4F4F]'>All Statuses</Button>
                            </PopoverHandler>
                            <PopoverContent className="tw-w-[8%]  tw-border tw-border-[#DDDDDD]">
                                <List className="p-0 tw-bg-white">
                                    {[
                                        { id: '', status: 'All' },
                                        ...userStatus,
                                    ].map((item) => (
                                        <ListItem
                                            key={item.id}
                                            className="tw-text-[#4F4F4F] common-font tw-text-[16px] tw-my-[10px] tw-flex tw-justify-center tw-border-b-[1px]"
                                            onClick={() => handleStatusClick(item?.id)}
                                        >
                                            {item?.status}
                                        </ListItem>
                                    ))}
                                </List>
                            </PopoverContent>
                        </Popover>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserManagementHeader
