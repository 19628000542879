import React, { useState, useEffect, useRef } from 'react'
import { RxDotsHorizontal } from "react-icons/rx";
import { MdFilterList } from "react-icons/md";
import { MdSort } from "react-icons/md";
import { Popover, PopoverHandler, PopoverContent } from "@material-tailwind/react";
import ConversationFilter from './ConversationFilter';

function ChatUserList({ chatList, setSelectedConversationId, selectedConversationId, setChatUserName, handleSortToggle, sortOrder, setConverSationFilter, conversationFilter }) {
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredChatList, setFilteredChatList] = useState(chatList);
    const [isFilterMenuOpen, setFilterMenuOpen] = useState(false);
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value.toLowerCase());
    };

    useEffect(() => {
        const filteredChats = chatList.filter((chat) => {
            const chatName = chat?.name?.toLowerCase();

            return (
                chatName.includes(searchQuery)
            );
        });

        setFilteredChatList(filteredChats);
    }, [searchQuery, chatList]);

    const popoverRef = useRef(null);

    const handleApplyFilters = async (filters) => {
        try {
            const {
                interest: interest_on_loan = '',
                loanMonths: loan_duration = '',
                originationFee: fees = '',
            } = filters || {};

            if (interest_on_loan || loan_duration || fees) {
                setConverSationFilter({ interest_on_loan, loan_duration, fees });
                // No need to close the popover
            } else {
                console.warn('No filters were applied. Please provide at least one filter value.');
            }
        } catch (error) {
            console.error('Error applying filters:', error);
        }
    };

    const handleResetFilters = () => {
        // Reset the conversation filters
        setConverSationFilter({
            interest_on_loan: '',
            loan_duration: '',
            fees: '',
        });
    };

    const handleKeyDown = (e) => {
        if (!popoverRef.current) return;

        const focusableElements = popoverRef.current.querySelectorAll(
            'input, button, [tabindex]:not([tabindex="-1"])'
        );
        const firstElement = focusableElements[0];
        const lastElement = focusableElements[focusableElements.length - 1];

        if (e.key === 'Tab') {
            if (e.shiftKey) {
                if (document.activeElement === firstElement) {
                    e.preventDefault();
                    lastElement.focus();
                }
            } else {
                if (document.activeElement === lastElement) {
                    e.preventDefault();
                    firstElement.focus();
                }
            }
        }
    };

    const truncateText = (text, maxLength) => {
        if (!text) return ''; // Return an empty string if text is falsy
        return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
    };

    return (
        <>
            <div className='tw-custom-chat-sidebar'>
                <div className='tw-h-[830px] tw-absolute  // or tw-relative depending on your layout context tw-top-[24px]'>
                    <div className='tw-mx-[10px] tw-mb-[20px] '>
                        <div className='tw-flex tw-justify-between tw-items-center'>
                            <div className='tw-font-extrabold tw-text-black tw-text-[20px] common-font'>Messages</div>
                            <div className="tw-border tw-border-gray-300 tw-border-[2px] tw-rounded-full tw-p-[5px] chat_user_list_settings" id='chat_user_list_settings'>
                                {/* <RxDotsHorizontal className='tw-text-green-500 tw-text-[20px] tw-cursor-pointer' /> */}
                                <Popover open={isFilterMenuOpen} handler={setFilterMenuOpen}>
                                    <PopoverHandler>
                                        <div className="tw-relative tw-cursor-pointer tw-flex tw-space-x-2">
                                            <RxDotsHorizontal className="tw-text-green-500 tw-text-[20px]" />
                                        </div>
                                    </PopoverHandler>
                                    <PopoverContent
                                        ref={popoverRef}
                                        className="tw-w-[250px] tw-bg-white tw-z-10 tw-mt-[5px] tw-border tw-border-gray-200 tw-rounded-lg tw-shadow-lg"
                                        onKeyDown={handleKeyDown} // Handle focus trapping
                                    >
                                        <ConversationFilter
                                            onApply={handleApplyFilters}
                                            onReset={handleResetFilters}
                                            initialFilters={conversationFilter}
                                        />
                                    </PopoverContent>
                                </Popover>
                            </div>
                        </div>
                    </div>
                    <div className='tw-h-[44px] tw-px-[10px]'>
                        <div className='tw-flex tw-gap-[10px] tw-items-center'>
                            <input
                                type="text"
                                placeholder="Search Messages Here..."
                                className='tw-w-full tw-h-full tw-border tw-border-[#DDDDDD] tw-rounded-full tw-px-2 tw-py-3 tw-text-[#202224] chat_list_search'
                                id='chat_list_search'
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                            <div className='tw-flex tw-justify-between'>
                                <div className="tw-cursor-pointer" onClick={handleSortToggle}>
                                    {sortOrder === 'ASC' ? (
                                        <MdFilterList className='tw-text-black hover:tw-text-green-500 tw-text-[20px] tw-cursor-pointer' />
                                    ) : (
                                        <MdSort className="tw-text-black hover:tw-text-green-500 tw-text-[20px]" />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='tw-w-full tw-mt-[25px] tw-overflow-hidden hover:tw-overflow-auto'>
                        <div className='tw-bg-[#F9F9F9] tw-rounded-[10px] tw-py-[10px] tw-rounded-[10px] tw-mx-[5px]'>
                            {filteredChatList?.map((chat, index) => (
                                <div
                                    key={chat?.id}
                                    onClick={() => {
                                        setSelectedConversationId(chat.id);
                                        setChatUserName(chat?.name);
                                    }}
                                    id='chat_user_list'
                                    className={`chat_user_list tw-flex ${chat.id === "no-chat" ? 'tw-bg-green-500 tw-text-white' : selectedConversationId === chat.id ? 'tw-bg-green-500 tw-text-white' : 'hover:tw-bg-gray-300'} tw-cursor-pointer tw-rounded-[10px] tw-py-4 tw-px-[5px] tw-mx-[5px] tw-relative`}
                                    style={{ minWidth: '0', overflow: 'hidden' }} // Ensure children can shrink
                                >
                                    <div className="tw-h-[60px] tw-flex tw-items-center tw-w-full">
                                        <div className="tw-h-full tw-flex tw-gap-[10px] tw-items-center">
                                            {/* Profile Section */}
                                            <div className="tw-h-full tw-flex tw-justify-center tw-items-center">
                                                <div className="tw-w-[45px] tw-h-[45px] tw-rounded-full tw-relative">
                                                    {/* Chat Profile Image */}
                                                    <img
                                                        className="tw-rounded-full tw-h-11 tw-w-11 tw-object-cover"
                                                        src={chat?.profileImg}
                                                        alt="Profile"
                                                    />
                                                    <span
                                                        className={`tw-top-0 tw-left-0 tw-absolute tw-w-3 tw-h-3 ${chat?.isOnline ? 'tw-bg-green-400' : 'tw-bg-gray-400'
                                                            } tw-border-2 tw-border-white tw-dark:border-gray-800 tw-rounded-full`}
                                                    ></span>
                                                </div>
                                            </div>

                                            {/* Chat Information Section */}
                                            <div className="tw-w-full tw-overflow-hidden">
                                                {/* Name and Date */}
                                                <div className="tw-flex tw-items-center tw-justify-between tw-w-full">
                                                    <div
                                                        className={`tw-capitalize common-font tw-font-extrabold tw-text-[15px] ${index === 0 ? 'tw-text-[#484848]' : 'tw-text-[#444444]'
                                                            } tw-overflow-hidden tw-whitespace-nowrap tw-text-ellipsis`}
                                                    >
                                                        {chat?.name?.length > 15 ? `${chat.name.slice(0, 15)}...` : chat?.name}
                                                    </div>
                                                    <div
                                                        className={`tw-font-bold tw-text-[12px] ${index === 0 ? 'tw-text-gray-700' : 'tw-text-gray-600'
                                                            }`}
                                                        style={{ flexShrink: 0 }}
                                                    >
                                                        {chat?.date}
                                                    </div>
                                                </div>

                                                {/* Additional Chat Details */}
                                                {chatList[0]?.id > 0 && (
                                                    <div className="tw-w-full tw-mt-1">
                                                        {/* Loan Purpose and Property Address */}
                                                        <div className="tw-text-[#484848] tw-text-[10px] common-font tw-font-normal tw-italic tw-overflow-hidden tw-whitespace-nowrap tw-text-ellipsis">
                                                            {chat?.loan_purpose || ''} | {truncateText(chat?.property_address, 50)}
                                                        </div>
                                                        {/* Loan Details */}
                                                        <div className="tw-text-[#484848] tw-text-[8px] common-font tw-font-normal tw-italic">
                                                            Interest Rate: {chat?.interest_on_loan ? `${chat.interest_on_loan}%` : ''} | Origination Fee: {chat?.fees || ''} |{' '}
                                                            {chat?.loan_duration_type || ''}: {chat?.loan_duration || ''}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default ChatUserList