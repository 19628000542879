import LineImage from "../../../assets/icons/trustePartnersLeftLines.svg";
import { GoogleMapsProvider } from "../../../contexts/GoogleMapsContext";
import LoanCalculatorComponent from "../../LoanCalculatorComponent";

const LoanCalculatorArea = ({ openSignupBorrowerModal }) => {
    return (
        <div className="tw-bg-[#4853E4] tw-w-full tw-relative">
            <img src={LineImage} alt="side line image" className="tw-absolute tw-rotate-90 tw-top-0 tw-w-40 tw-h-40" />

            <div className="tw-flex tw-items-center tw-m-10 tw-space-x-20 tw-mx-20">
                <div className="tw-flex tw-flex-col tw-w-1/2">
                    <div className="tw-flex tw-items-center">
                        <span className=" tw-w-8 tw-h-px tw-border-t tw-border-[0.5px] tw-border-[#FFFFFF]"></span>
                        <span className="tw-font-inter tw-px-2 tw-text-[#FFFFFF] tw-text-lg tw-font-normal tw-text-center">Loan Calculator</span>
                        <span className=" tw-w-8 tw-h-px tw-border-t tw-border-[0.5px] tw-border-[#FFFFFF]"></span>
                    </div>

                    <div className="tw-flex tw-flex-col tw-space-y-4">
                        <h2 className="tw-text-[#FFFFFF] tw-font-oswald tw-font-bold tw-text-4xl lg:tw-text-5xl">Loan Calculator: Your Real Estate Loan Starts Here</h2>

                        <p className="tw-font-inter tw-text-[#FFFFFF] tw-text-lg">Our loan calculator makes it easy to plan your next real estate investment. Simply enter your details to instantly explore loan options tailored to your needs.
                        </p>
                        <p className="tw-font-inter tw-text-[#FFFFFF] tw-text-lg">We connect you directly with trusted lenders, providing real-time rate comparisons and program details.
                        </p>
                    </div>
                </div>

                <div className="tw-w-1/2 tw-flex tw-flex-col">
                    <GoogleMapsProvider>
                        <LoanCalculatorComponent openSignupBorrowerModal={openSignupBorrowerModal} />
                    </GoogleMapsProvider>
                </div>
            </div>
        </div>
    )
}

export default LoanCalculatorArea;