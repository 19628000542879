// import axios from 'axios';
import { 
    SIGN_UP_REQUEST, 
    SIGN_UP_SUCCESS, 
    SIGN_UP_FAILURE, 
    CLEAR_SIGN_UP_ERRORS 
} from '../../utils/constants';
import { signUp } from "@aws-amplify/auth";

// Action to clear errors
export const clearSignUpErrors = () => ({
    type: CLEAR_SIGN_UP_ERRORS,
});

// Async action to handle sign-up
export const signUpUser = (formData, 
    userType, 
    awsContextVal, 
    navigate, 
    toast, 
    showLoader, 
    hideLoader,
    setSignUpUserEmail,
    setSignUpUserPassword
    ) => async (dispatch) => {
    dispatch({ type: SIGN_UP_REQUEST });
    
    try {
        showLoader();
        
        // First API call for sign-up
        const { userId } = await signUp({
            username: formData.email,
            password: formData.password,
            options: {
                userAttributes: {
                    email: formData.email,
                    phone_number: formData.phone,
                    name: formData.firstName + " " + formData.lastName,
                    'custom:user_type': userType,
                    'custom:FirstName': formData.firstName,
                    'custom:LastName': formData.lastName
                }
            }
        });

        if (userId) {
            setSignUpUserEmail(formData.email);
            setSignUpUserPassword(formData.password);
            // Second API call to create a user
            // const _applicationsResponse = await axios.post(`${awsContextVal?.endpoint}/create-user`, {
            //     email: formData.email,
            //     phone_number: formData.phone,
            //     name: formData.firstName + " " + formData.lastName,
            //     'custom:user_type': userType,
            //     'custom:FirstName': formData.firstName,
            //     'custom:LastName': formData.lastName,
            // });

            hideLoader();
            toast.success("User registered successfully! Please validate and log in.");
            navigate(`/otp-verify`);

            dispatch({ type: SIGN_UP_SUCCESS, payload: { userId, email: formData.email } });
        }
    } catch (err) {
        hideLoader();

        const errorMessage = err.message || "Cannot sign up user.";
        toast.error(errorMessage);

        dispatch({ type: SIGN_UP_FAILURE, payload: errorMessage });
    }
};
