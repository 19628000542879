
import React, { useState, useRef, useContext } from 'react';

import BorrowerSignupIcon from "../../../assets/icons/borrowerIcon.svg";
import LenderSignupIcon from "../../../assets/icons/lenderIcon.svg";
import { Link } from 'react-router-dom';

import { UserContext } from '../../../contexts/UserContext';
import VideoWMusic from '../../../assets/videos/VideoWMusic.mp4';

const HomeInfoArea = ({ isUserLoggedIn }) => {
  // const { userType, setUserType } = useUserType();
  const { userType, setUserType } = useContext(UserContext);

  const videoRef = useRef(null);

  const handleUserTypeChange = (type) => {
    setUserType(type);
  };

  return (
    <>
      <div className="tw-flex tw-items-center tw-justify-center  tw-space-x-8">
        {/* Left Text Container */}
        <div className="tw-flex tw-flex-col tw-space-y-14 tw-w-1/2">
          <div className="tw-flex tw-flex-col">
            <h2 className="tw-font-oswald tw-leading-10 tw-text-start md:tw-text-4xl lg:tw-text-7xl tw-text-black tw-my-4 tw-font-bold">
              The All-in-One <br /> Bridge <span className="tw-text-[#4853e4]"> Loan Platform</span>
            </h2>
            <p className="tw-font-inter tw-text-[#404040] tw-text-lg">
              Get the right bridge loan for your specific
              needs, without paying a broker commission
              or shopping around.
            </p>
            <p className="tw-font-inter tw-text-[#404040] tw-text-lg tw-mt-[20px]">
              Our platform combines real-time data, AI
              technology, and a nationwide lender network
              to simplify applying, communicating with
              lenders, choosing the right bridge loan, and
              getting your funds fast.
            </p>
          </div>

          <div className="tw-flex tw-gap-x-4">
            {!isUserLoggedIn ? (
              <>
                <Link to={"/sign-up/borrower"} tabIndex={-1}>
                  <button
                    onClick={() => handleUserTypeChange('borrower')}
                    className="tw-flex tw-gap-x-1 tw-items-center tw-p-2 tw-border-[1px] tw-drop-shadow-lg tw-rounded-3xl hover:tw-border-[#7DDE92] hover:tw-drop-shadow-xl"
                  >
                    <img src={BorrowerSignupIcon} className="tw-w-6 tw-h-6" alt="borrower" />
                    <p className="tw-text-md tw-text-black tw-font-oswald">Borrower Signup</p>
                  </button>
                </Link>
                <Link to={"/sign-up/lender"} tabIndex={-1}>
                  <button
                    onClick={() => handleUserTypeChange('lender')}
                    className="tw-flex tw-gap-x-1 tw-items-center tw-p-2 tw-border-[1px] tw-drop-shadow-lg tw-rounded-3xl hover:tw-border-[#4853E4] hover:tw-drop-shadow-xl"
                  >
                    <img src={LenderSignupIcon} className="tw-w-6 tw-h-6" alt="lender" />
                    <p className="tw-text-md tw-text-black tw-font-oswald">Lender Signup</p>
                  </button>
                </Link>
              </>
            ) : null}
          </div>
        </div>

        <div className="tw-relative tw-w-1/2 tw-flex tw-justify-center tw-h-full">
          <video
            ref={videoRef}
            className="tw-rounded-lg tw-w-full tw-h-auto tw-object-cover"
            src={VideoWMusic}
            muted
            playsInline
            loop
            autoPlay
          />
        </div>
      </div>
    </>
  );
};

export default HomeInfoArea;
