import React, { useState, useContext, useReducer, useEffect } from 'react';
// import GetAQuoteIcon from "../assets/icons/borrowerIcon.svg";
// import GenericButton from "./common/buttons/GenericButton";

import { Input } from '@mui/material';
import toast from 'react-hot-toast';
import axios from 'axios';
import { UserContext } from '../../src/contexts/UserContext';
import { authLocal } from '../utils/common';
import { useLoader } from '../contexts/LoaderContext';
import { AWSAmplifyConfigContext } from '../contexts/AWSAmplifyConfigContext';
import { useNavigate } from 'react-router-dom';
import { useModalContext } from '../contexts/ModalContexts';
import { GoogleMapsContext } from '../contexts/GoogleMapsContext';

const initialState = {
    loanPurpose: '',
    address: '',
    propertyValue: '',
    loanToValue: '',
    lat: null,
    lng: null
};


function reducer(state, action) {
    switch (action.type) {
        case 'SET_FIELD_VALUE':
            return { ...state, [action.field]: action.value };
        case 'RESET':
            return initialState;
        default:
            return state;
    }
}

const LoanCalculatorComponent = ({ openSignupBorrowerModal }) => {
    const navigate = useNavigate();
    const [state, dispatch] = useReducer(reducer, initialState);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [errors, setErrors] = useState({});
    const { showLoader, hideLoader } = useLoader();
    const { user } = useContext(UserContext);
    const awsContextVal = useContext(AWSAmplifyConfigContext);
    const { isLoaded, autocomplete } = useContext(GoogleMapsContext);
    const { propertyType, loanPurpose, address, propertyValue, loanToValue, lat, lng } = state;
    const { showModal } = useModalContext();
    const [propertyTypeList, setPropertyTypeList] = useState([]);
    const [loanPurposeList, setLoanPurposeList] = useState([]);
    const [ltv, setLtv] = useState(0);

    const handleChange = (e) => {
        let { name, value } = e.target;

        // Check if the field is propertyValue or loanToValue and format the value
        if (name === 'propertyValue' || name === 'loanToValue') {
            // Remove non-numeric characters and add commas
            value = value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
            value = value.length ? parseInt(value).toLocaleString() : ''; // Add commas for thousands

            // Update the state
            dispatch({ type: 'SET_FIELD_VALUE', field: name, value });
        } else {
            dispatch({ type: 'SET_FIELD_VALUE', field: name, value: e.target.value });
        }
    };

    const validateForm = () => {
        const newErrors = {};
        if (!propertyType) newErrors.propertyType = "Property Type is required.";
        if (!loanPurpose) newErrors.loanPurpose = "Loan Purpose is required.";
        if (!address) newErrors.address = "Property Address is required.";
        if (!propertyValue) newErrors.propertyValue = "Property Value is required.";
        if (!loanToValue) newErrors.loanToValue = "Loan Amount is required.";

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const createBorrowerLoanApplication = async () => {
        showLoader();
        try {
            const response = await axios.post(`${awsContextVal?.endpoint}/application/create`, {
                userId: user.id,
                property_type_id: parseInt(propertyType),
                property_address: address,
                property_value: parseFloat(propertyValue.replace(/,/g, '')),
                loan_purpose_id: loanPurpose,
                loan_amount: parseFloat(loanToValue.replace(/,/g, '')),
                ltv: ltv,
                lat: lat,
                lng: lng,
                property_status: 1
            });

            if (response.status === 200) {
                // toast.success("Application Submitted Successfully.");
                dispatch({ type: 'RESET' });
                setErrors({});
                setFormSubmitted(false);
                showModal("Your Application Has Been Submitted!", "success");
            } else {
                toast.error("Application Submission Failed.");
            }
        } catch (error) {
            const errorMessage = error.response?.data?.message || 'Error Submitting the Loan Application.';
            toast.error(errorMessage);
            console.error('Error:', errorMessage);
        } finally {
            hideLoader();
        }
    };

    const fetchPropertyTypeList = async () => {
        try {
            const response = await axios.post(`${awsContextVal?.endpoint}/property-type/list`, {
                status: '1',
                sort_by: "type",
                sort_order: "ASC"
            });
            if (response?.data?.propertyTypeList) {
                setPropertyTypeList(response?.data?.propertyTypeList);
            }
        } catch (error) {
            console.error("Error fetching property types:", error);
        }
    };

    const fetchLoanPurposeList = async () => {
        try {
            const response = await axios.post(`${awsContextVal?.endpoint}/loan-purpose/list`, {
                status: '1',
                sort_by: "name",
                sort_order: "ASC"
            });
            if (response?.data?.loadPurposeList) {
                setLoanPurposeList(response?.data?.loadPurposeList);
            }
        } catch (error) {
            console.error("Error fetching Loan Purpose types:", error);
        }
    };

    useEffect(() => {
        fetchPropertyTypeList();
        fetchLoanPurposeList();
    }, []);

    useEffect(() => {
        // Function to calculate the percentage
        const calculatePercentage = () => {
            if (loanToValue && propertyValue) {
                const loanValue = parseFloat(loanToValue.replace(/,/g, ''));
                const propValue = parseFloat(propertyValue.replace(/,/g, ''));
                if (propValue > 0) {
                    const percentage = ((loanValue / propValue) * 100).toFixed(2);
                    setLtv(percentage);
                } else {
                    setLtv('0');
                }
            } else {
                setLtv('0');
            }
        };
        calculatePercentage();
    }, [loanToValue, propertyValue]);

    useEffect(() => {
        if (autocomplete) {
            const listener = autocomplete.addListener('place_changed', () => {
                const place = autocomplete.getPlace();

                if (place) {
                    if (place?.formatted_address) {
                        dispatch({ type: 'SET_FIELD_VALUE', field: 'address', value: place.formatted_address });
                    }

                    if (place.geometry && place.geometry.location) {
                        const lat = place?.geometry?.location?.lat();
                        const lng = place?.geometry?.location?.lng();

                        dispatch({ type: 'SET_FIELD_VALUE', field: 'lat', value: lat });
                        dispatch({ type: 'SET_FIELD_VALUE', field: 'lng', value: lng });
                    }
                }
            });

            return () => {
                window.google.maps.event.removeListener(listener);
            };
        }
    }, [autocomplete]);

    const getAQuote = async () => {
        setFormSubmitted(true);
        if (!validateForm()) return;

        const loggedIn = authLocal.get();
        if (loggedIn) {
            await createBorrowerLoanApplication();
        } else {
            const formData = { propertyType, loanPurpose, address, propertyValue, loanToValue, lat, lng, ltv };
            localStorage.setItem("pendingApplicationData", JSON.stringify(formData));

            toast.error("Please log in to submit an application.");
            navigate(`/sign-in`, {});
        }
    };
    return (
        <div className="tw-bg-[#FFFFFF] tw-flex tw-flex-col tw-space-y-4 tw-p-5 tw-rounded-lg">
            {/* <div className="tw-p-5 tw-flex tw-flex-col tw-space-y-1 tw-rounded-lg tw-border-[1px] tw-border-[#4853E4]">
                <div className="tw-flex tw-justify-between">
                    <p className="tw-text-[#4853E4] tw-text-lg tw-font-oswald tw-font-light">Calculate Loan To Value:</p>
                    <p className="tw-text-[#4853E4] tw-font-inter tw-font-medium tw-text-lg tw-font-oswald">
                        {loanToValue && propertyValue
                            ? ((parseFloat(loanToValue.replace(/,/g, '')) / parseFloat(propertyValue.replace(/,/g, ''))) * 100).toFixed(2) + '%'
                            : '0%'}
                    </p>
                </div>

                <div className="tw-flex tw-justify-between">
                    <p className="tw-text-[#000000]/40 tw-text-lg tw-font-oswald tw-font-light">Potential Programs Available:</p>
                    <p className="tw-text-[#000000] tw-font-medium tw-font-inter tw-text-lg tw-font-oswald">0</p>
                </div>

                <div className="tw-flex tw-justify-between">
                    <p className="tw-text-[#000000]/40 tw-text-lg tw-font-oswald tw-font-light">Estimated Rate:</p>
                    <p className="tw-text-[#000000] tw-font-medium tw-font-inter tw-text-lg tw-font-oswald">0%</p>
                </div>

                <div className="tw-flex tw-justify-between">
                    <p className="tw-text-[#000000]/40 tw-text-lg tw-font-oswald tw-font-light">Potential Lenders:</p>
                    <p className="tw-text-[#000000] tw-font-medium tw-font-inter tw-text-lg tw-font-oswald">0</p>
                </div>
            </div> */}
            <div className="tw-p-5 tw-flex tw-flex-col tw-space-y-1 tw-rounded-lg tw-border-[1px] tw-border-[#4853E4]">
                <div className=' tw-flex tw-justify-between tw-py-1'>
                    <div className='tw-font-semibold tw-text-[#202224] common-font tw-text-[16px]'>
                        LTV:
                    </div>
                    <div className='tw-font-semibold tw-text-[#4853E4] common-font tw-text-[16px]'>
                        {/* {loanToValue && propertyValue
                                    ? ((parseFloat(loanToValue.replace(/,/g, '')) / parseFloat(propertyValue.replace(/,/g, ''))) * 100).toFixed(2) + '%'
                                    : '0%'} */}
                        {ltv ? ltv + '%' : ""}
                    </div>
                </div>
            </div>
            <div className='tw-flex tw-flex-col tw-space-y-4'>
                <div className="tw-flex tw-flex-col tw-space-y-1">
                    <label className="tw-font-oswald tw-font-medium tw-text-md tw-text-[#000000]">Property Address</label>

                    <input
                        id="address"
                        name="address"
                        type="text"
                        value={address}
                        onChange={handleChange}
                        placeholder="Please add your Property address here"
                        className={`tw-w-full tw-border-[1px] tw-border-[#000000]/28 tw-rounded-lg tw-p-2.5 tw-text-[#000000] tw-font-inter ${errors.address ? 'tw-border-red-500' : ''}`}
                    />
                    {errors.address && <p className="tw-text-red-500 tw-text-[14px] ">{errors.address}</p>}
                </div>
                <div className="tw-flex tw-flex-col tw-space-y-1">
                    <label className="tw-font-oswald tw-font-medium tw-text-md tw-text-[#000000]">Property Type</label>
                    <select
                        id="propertyType"
                        className={`tw-border-[1px] tw-border-[#000000]/28 tw-rounded-lg tw-p-2.5 tw-cursor-pointer tw-text-[#000000] tw-font-inter tw-pr-4  ${errors.loanPurpose ? 'tw-border-red-500' : ''}`}
                        name="propertyType" value={propertyType} onChange={handleChange}
                    >
                        <option value="">-Select-</option>
                        {propertyTypeList?.map((item) => (
                            <option key={item?.id} value={item?.id}>
                                {item?.type}
                            </option>
                        ))}
                    </select>
                    {errors.loanPurpose && <p className="tw-text-red-500 tw-text-[14px] ">{errors.loanPurpose}</p>}
                </div>
                <div className="tw-flex tw-flex-col tw-space-y-1">
                    <label className="tw-font-oswald tw-font-medium tw-text-md tw-text-[#000000]">Property Value</label>
                    <div className="tw-relative">
                        <div className="tw-absolute tw-text-black tw-bg-gray-200 tw-m-[2px] tw-rounded-l-[7px] tw-inset-y-0 tw-start-0 tw-flex tw-items-center tw-px-5 tw-pointer-events-none">
                            $
                        </div>
                        <input type="text"
                            id="propertyValue"
                            name="propertyValue"
                            value={propertyValue}
                            onChange={handleChange}
                            placeholder="Please add your Property value here"
                            className={`tw-w-full tw-border-[1px] tw-border-[#000000]/28 tw-rounded-lg tw-text-[#000000] tw-font-inter tw-focus:ring-blue-500 tw-focus:border-blue-500 tw-block tw-w-full tw-ps-14 tw-p-2.5 tw-dark:bg-gray-700 tw-dark:border-gray-600 tw-dark:placeholder-gray-400 tw-dark:text-white tw-dark:focus:ring-blue-500 tw-dark:focus:border-blue-500 ${errors.propertyValue ? 'tw-border-red-500' : ''}`} />
                    </div>
                    {errors.propertyValue && <p className="tw-text-red-500 tw-text-[14px] ">{errors.propertyValue}</p>}
                </div>

                <div className="tw-flex tw-flex-col tw-space-y-1">
                    <label className="tw-font-oswald tw-font-medium tw-text-md tw-text-[#000000]">Loan Amount</label>

                    <div className="tw-relative">
                        <div className="tw-absolute tw-text-black tw-bg-gray-200 tw-m-[2px] tw-rounded-l-[7px] tw-inset-y-0 tw-start-0 tw-flex tw-items-center tw-px-5 tw-pointer-events-none">
                            $
                        </div>
                        <input type="text"
                            id="loanToValue"
                            name="loanToValue"
                            value={loanToValue}
                            onChange={handleChange}
                            placeholder="Please add value"
                            className={`tw-w-full tw-border-[1px] tw-border-[#000000]/28 tw-rounded-lg tw-text-[#000000] tw-font-inter tw-focus:ring-blue-500 tw-focus:border-blue-500 tw-block tw-w-full tw-ps-14 tw-p-2.5 tw-dark:bg-gray-700 tw-dark:border-gray-600 tw-dark:placeholder-gray-400 tw-dark:text-white tw-dark:focus:ring-blue-500 tw-dark:focus:border-blue-500 ${errors.loanToValue ? 'tw-border-red-500' : ''}`} />
                    </div>
                    {errors.loanToValue && <p className="tw-text-red-500 tw-text-[14px] ">{errors.loanToValue}</p>}
                </div>
                <div className="tw-flex tw-flex-col tw-space-y-1">
                    <label className="tw-font-oswald tw-font-medium tw-text-md tw-text-[#000000]">Loan Purpose</label>
                    <select
                        id="loanPurpose"
                        className={`tw-border-[1px] tw-border-[#000000]/28 tw-rounded-lg tw-p-2.5 tw-cursor-pointer tw-text-[#000000] tw-font-inter tw-pr-4  ${errors.loanPurpose ? 'tw-border-red-500' : ''}`}
                        name="loanPurpose" value={loanPurpose} onChange={handleChange}
                    >
                        <option value="">-Select-</option>
                        {loanPurposeList?.map((item) => (
                            <option key={item?.id} value={item?.id}>
                                {item?.name}
                            </option>
                        ))}
                    </select>
                    {errors.loanPurpose && <p className="tw-text-red-500 tw-text-[14px] ">{errors.loanPurpose}</p>}
                </div>
                <button className='tw-w-full tw-h-[49px] tw-border tw-text-[#2A2F70] tw-font-semibold tw-border-[#DDDDDD] tw-rounded-[20px] tw-bg-[#7DDE92] text-center'
                    onClick={() => getAQuote()}>
                    Get A Qoute
                </button>
                {/* <GenericButton onClick={getAQuote} icon={GetAQuoteIcon} title='Get A Quote' openModalCallback={() => getAQuote}/> */}
            </div>
        </div>
    )
}

export default LoanCalculatorComponent;