import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { Input } from '@material-tailwind/react';
import { confirmSignUp, signIn } from "@aws-amplify/auth";
import toast from "react-hot-toast";
import axios from 'axios';
import { useLoader } from '../../contexts/LoaderContext';
import { capitalizeWords, authLocal } from '../../utils/common';
import { UserContext } from '../../contexts/UserContext';
import { AWSAmplifyConfigContext } from '../../contexts/AWSAmplifyConfigContext';

function OtpVerify() {
    const { userType, setUser, signUpUser } = useContext(UserContext);
    const awsContextVal = useContext(AWSAmplifyConfigContext);
    const navigate = useNavigate();
    const { showLoader, hideLoader } = useLoader();

    
    const [formData, setFormData] = useState({
        email: signUpUser.email,
        authenticationCode: '',
    });
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        let validationErrors = {};

        if (!formData.email) {
            validationErrors.email = 'Email Address is required';
        }
        if (!formData.authenticationCode) {
            validationErrors.authenticationCode = 'Authentication Code is required';
        }
        // If there are errors, set the errors state
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        setErrors({});
        try {
            showLoader();
            const { isSignUpComplete } = await confirmSignUp({ username: formData.email, confirmationCode: formData.authenticationCode });
            if (isSignUpComplete) {
                toast.success("OTP verified successfully.");
                const { nextStep } = await signIn({ username: formData?.email, password: signUpUser.password });

                if (nextStep.signInStep === "DONE") {
                    
                    // Fetch user details
                    const user_details = await axios.post(`${awsContextVal?.endpoint}/fetch-user`, { email: formData.email });
                    console.log("user_details", user_details);
                    if (user_details?.data?.user) {

                        //This is to update user status
                        const update_user_status = await axios.post(`${awsContextVal?.endpoint}/user/update-status`, { status: 1, id: user_details?.data?.user?.id });
                        if(update_user_status?.data?.is_success){
                            authLocal.set(user_details?.data?.user);
                            setUser(user_details?.data?.user);
                            hideLoader();

                            toast.success("Signin Successful!");

                            // Redirect based on user type
                            const userType = user_details?.data?.user?.user_type;
                            if (userType === 'borrower') {
                                navigate("/dashboard/borrower");
                            } else if (userType === 'lender') {
                                navigate("/dashboard/lender");
                            } else if (userType === 'admin') {
                                navigate("/dashboard/admin");
                            }

                            // Check for pending application data
                            const pendingData = localStorage.getItem("pendingApplicationData");
                            if (pendingData && userType === 'borrower') {
                                const { propertyType, loanPurpose, address, propertyValue, loanToValue, lat, lng, ltv } = JSON.parse(pendingData);

                                try {
                                    // Submit the pending loan application
                                    const response = await axios.post(`${awsContextVal.endpoint}/application/create`, {
                                        userId: user_details.data.user.id,
                                        property_type_id: parseInt(propertyType),
                                        property_address: address,
                                        property_value: propertyValue,
                                        loan_purpose_id: loanPurpose,
                                        loan_amount: loanToValue,
                                        ltv: ltv,
                                        lat: lat,
                                        lng: lng,
                                        property_status: 1
                                    });

                                    if (response.status === 200) {
                                        toast.success("Pending application submitted successfully.");
                                        localStorage.removeItem("pendingApplicationData"); // Clear saved data after submission
                                    } else {
                                        toast.error("Failed to submit pending application.");
                                    }
                                } catch (error) {
                                    console.error("Error submitting pending application:", error);
                                    toast.error("Error submitting pending application.");
                                }
                            }
                            else if (pendingData) {
                                localStorage.removeItem("pendingApplicationData");
                            }
                        }
                    } else {
                        hideLoader();
                        toast.error("User not found.");
                    }
                    
                } else {
                    hideLoader();
                    toast.error("User is not available.");
                }
            }
        } catch (err) {
            hideLoader();
            console.log(err);
            if (err.message) {
                if (err.message === "User cannot be confirmed. Current status is CONFIRMED") {
                    toast.error("Please enter the email you used to sign up");
                } else {
                    toast.error(err.message);
                }
            }
        }
    }

    return (
        <>
            <div className=" tw-bg-white tw-px-4 sm:tw-px-4 md:tw-px-12 lg:tw-px-12 tw-py-16 tw-flex tw-justify-center tw-items-center tw-h-screen">
                <div className="tw-w-full">
                    <div className="tw-bg-indigo-100 tw-text-[#4853E4] tw-py-1 tw-px-3 tw-rounded tw-inline-block tw-text-sm">
                        {capitalizeWords(userType)} Profile
                    </div>
                    <h2 className="tw-font-oswald tw-leading-10 tw-text-start md:tw-text-4xl lg:tw-text-3xl tw-text-[#333333] tw-my-4 tw-font-medium tw-text-center">Confirm OTP</h2>
                    <form className="tw-space-y-10 tw-mt-9" onSubmit={handleSubmit}>
                        <div className="tw-flex tw-flex-wrap md:tw-flex-nowrap lg:tw-gap-x-4 md:tw-gap-x-4 lg:tw-gap-y-0">
                            <div className="tw-w-full tw-w-full sm:tw-w-full md:tw-w-[50%] lg:tw-w-[50%]">
                                <label htmlFor="email" className="form-label">
                                    Email Address <span className="text-red-500">*</span>
                                </label>
                                <Input
                                    disabled
                                    id="email"
                                    type="email"
                                    autoFocus = {true}
                                    value={formData.email}
                                    onChange={handleChange}
                                    className={`tw-cursor-not-allowed tw-border tw-p-3 tw-border-gray-400 tw-h-14 tw-w-[100%] tw-rounded-16 tw-text-black tw-font-oswald ${errors.email ? 'tw-border-red-500' : ''}`}
                                />
                                {errors.email && <p className="tw-text-red-500 tw-text-[14px]">{errors.email}</p>}
                            </div>
                            <div className="tw-w-full tw-w-full sm:tw-w-full md:tw-w-[50%] lg:tw-w-[50%]">
                                <label htmlFor="email" className="form-label">
                                    Authentication code <span className="text-red-500">*</span>
                                </label>
                                <Input
                                    id="authenticationCode"
                                    type="text"
                                    value={formData.authenticationCode}
                                    onChange={handleChange}
                                    className={`tw-border tw-p-3 tw-border-gray-400 tw-h-14 tw-w-[100%] tw-rounded-16 tw-text-black tw-font-oswald ${errors?.authenticationCode ? 'tw-border-red-500' : ''}`}
                                />
                                {errors?.authenticationCode && <p className="tw-text-red-500 tw-text-[14px]">{errors?.authenticationCode}</p>}
                            </div>
                        </div>
                        <div className="tw-flex tw-justify-between tw-items-center">
                            <button className="tw-w-1/2 sm:tw-w-1/2 md:tw-w-1/3  lg:tw-w-1/6 tw-bg-green-300 tw-border tw-text-[#2A2F70] tw-font-bold tw-py-4 tw-rounded-full tw-mt-4">
                                Confirm
                                <span className="tw-ml-2 tw-bg-white tw-p-2 tw-rounded-full">➔</span>
                            </button>

                            <p className="tw-mb-4 tw-text-black tw-text-lg">
                                <button onClick={() => navigate(`/sign-up/borrower`)} className="tw-font-oswald tw-text-[#2A2F70]">Back to Sign-up</button>
                            </p>
                        </div>

                    </form>
                </div>
            </div>
        </>
    )
}

export default OtpVerify