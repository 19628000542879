import HealthIconHighBarsIcon from "../../../assets/icons/healthicons_high-bars.svg";
import CommunicationIcon from "../../../assets/icons/communication.svg";
import UilProcessIcon from "../../../assets/icons/uil_process.svg";
import AdvantageCard from "./AdvantageCard";

const AdvantagesArea = ({ openSignupBorrowerModal }) => {
    const advantageCards = [{
        count: '01',
        icon: HealthIconHighBarsIcon,
        title: 'Save your money and time.',
        description: 'With Re-Loan, choose the bridge loan lender that meets your needs, without paying heftybroker commissions, wasting time shopping around for the best rates, or paying multiple application fees. Spend your time and money on something else.'
    },
    {
        count: '02',
        icon: UilProcessIcon,
        title: 'We leverage AI to improve your loan journey.',
        description: 'Your bridge loan is processed quickly and accurately, with multiple options for you to choose from, with only one application. Automation enables lenders to save expensive professional hours, increase speed, and lower risk across their portfolio - all dramatically boosting their profitability and ability to serve you.',
    },
    {
        count: '03',
        icon: CommunicationIcon,
        title: 'Communicate and collaborate.',
        description: 'Re-Loan’s integrated chat allows borrowers and lenders to communicate without delays, fostering clear, real-time collaboration that simplifies the financing process.'
    }];

    return (
        <div className="tw-w-full tw-flex tw-flex-col tw-justify-center tw-mx-20">
            <div className="tw-flex tw-justify-center tw-items-center">
                <span className=" tw-w-8 tw-h-px tw-border-t tw-border-[0.5px] tw-border-blue-400"></span>
                <span className="tw-font-inter tw-px-2 tw-text-black tw-text-lg tw-font-normal tw-text-center">Features</span>
                <span className=" tw-w-8 tw-h-px tw-border-t tw-border-[0.5px] tw-border-blue-400"></span>
            </div>

            <h2 className="tw-font-oswald tw-text-4xl tw-text-black tw-leading-10 lg:tw-text-5xl tw-font-bold tw-text-center tw-my-4">The Re-Loan <span className="tw-text-[#4853e4]">Advantage</span></h2>

            <p className="tw-font-inter tw-text-[#404040] tw-text-lg tw-text-center tw-font-normal">Streamlined bridge loan lending.</p>

            <div className="tw-flex tw-my-10 tw-space-x-2">
                {
                    advantageCards.map(card => <AdvantageCard
                        key={card.count}
                        id={card.count}
                        count={card.count}
                        icon={card.icon}
                        title={card.title}
                        description={card.description}
                    />)
                }
            </div>
        </div>
    )
}

export default AdvantagesArea;
