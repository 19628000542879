import React, { useContext } from 'react'
import LogoImage from "../../../assets/icons/Logo.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules"; // Corrected Import
import "swiper/swiper-bundle.css";
import { useNavigate } from 'react-router-dom';



import '../../../auth.css'
import { UserContext } from '../../../contexts/UserContext';
const Sidebar = () => {
    const { userType } = useContext(UserContext);
    const navigate = useNavigate();

    const goToHome = () => {
        navigate("/");
    }

    const testimonials = [
        {
            paragraph: "This product has really helped our business grow significantly.",
            name: "Jennie Ruby",
            designation: "CEO, TechCorp",
            profileImage: "https://randomuser.me/api/portraits/women/1.jpg"
        },
        {
            paragraph: "Amazing service and great support. Highly recommend!",
            name: "Yoo Jimin",
            designation: "CTO, InnovateX",
            profileImage: "https://randomuser.me/api/portraits/men/1.jpg"
        },
        {
            paragraph: "Our team uses this platform daily and we love its simplicity.",
            name: "Shin Ryujin",
            designation: "COO, WebDyno",
            profileImage: "https://randomuser.me/api/portraits/women/2.jpg"
        },
        {
            paragraph: "A fantastic tool that has made our processes much smoother.",
            name: "Kim Taehyung",
            designation: "Founder, VisionSoft",
            profileImage: "https://randomuser.me/api/portraits/men/2.jpg"
        },
    ];
    return (
        <>
            {/* Left Side - Blue Section (40%) */}
            <div
                className="tw-h-screen tw-text-white tw-px-4 sm:tw-px-4 md:tw-px-8 lg:tw-px-10 tw-py-[3vh] tw-flex tw-flex-col tw-justify-between tw-overflow-hidden sidebar-background">
                {/* Logo */}
                <div className='tw-h-[50vh]'>
                    <div className='tw-h-[10vh] sm:tw-h-[10vh] md:tw-h-[15vh] lg:tw-h-[8vh] '>
                        <img src={LogoImage} alt="Re-loan logo" className="tw-mb-0" onClick={goToHome} />
                    </div>
                    <div className='tw-h-[40vh] sm:tw-h-[40vh] md:tw-h-[35vh] lg:tw-h-[38vh]  tw-flex tw-flex-col tw-justify-center tw-items-center'>
                        <div className="">
                            {userType == 'borrower' ?
                                <>
                                    <h1 className="tw-font-oswald tw-leading-tight sm:tw-leading-tight md:tw-leading-snug lg:tw-leading-normal tw-text-start tw-text-[14px] sm:tw-text-[16px] md:tw-text-lg lg:tw-text-2xl xl:tw-text-4xl tw-text-[#FFFFFF] tw-my-4 tw-font-semibold tw-text-center">
                                        Start your remarkable <div className="tw-mt-0">journey with us!</div>
                                    </h1>
                                    <p className="tw-font-inter tw-mt-5 tw-text-[12px] sm:tw-text-[12px] md:tw-text-[18px] lg:tw-text-[20px] tw-font-[500] tw-text-[#FFFFFFBF]">
                                        Our platform helps you get the best bridge loan without wasting time on multiple applications or dealing with brokers.
                                    </p>
                                </>
                                :
                                <>
                                    <h1 className="tw-font-oswald tw-leading-tight sm:tw-leading-tight md:tw-leading-snug lg:tw-leading-normal tw-text-start tw-text-[12px] sm:tw-text-[14px] md:tw-text-base lg:tw-text-lg xl:tw-text-2xl tw-text-[#FFFFFF] tw-my-4 tw-font-semibold tw-text-center tw-mt-[50px]">
                                        Bridge loan lending partners with Re-Loan are positioned to thrive now and in the future.
                                    </h1>
                                    <p className="tw-font-inter tw-mt-5 tw-text-[12px] sm:tw-text-[12px] md:tw-text-[18px] lg:tw-text-[20px] tw-font-[500] tw-text-[#FFFFFFBF]">
                                        Dramatically increase your efficiency and
                                        profitability with our bridge loan focused
                                        platform. We bring the leads to you!
                                    </p>
                                </>
                            }


                        </div>
                    </div>
                </div>

                {/* Main Text Section */}

                <div className='tw-h-[50vh]'>
                    <div className="tw-mt-10 tw-pb-10">
                        <Swiper
                            modules={[Pagination, Autoplay]}
                            id="swiper"
                            slidesPerView="auto"
                            spaceBetween={30}
                            centeredSlides={true}
                            pagination={{
                                clickable: true,
                                renderBullet: (index, className) => {
                                    return `<span className="${className} tw-indicator custom-indicator"></span>`;
                                },
                            }}
                            autoplay={{ delay: 3000 }}
                            breakpoints={{ 640: { slidesPerView: 1 }, 1024: { slidesPerView: 2 }, 1280: { slidesPerView: 3 } }}
                            style={{ paddingBottom: "20px" }}
                        >
                            {testimonials.map((testimonial, index) => (
                                <SwiperSlide key={`slide-${index}`} style={{ listStyle: "none" }}>
                                    <div className="tw-bg-white tw-text-black tw-rounded-lg tw-p-1 sm:tw-p-1 md:tw-p-3 lg:tw-p-3 tw-shadow-lg tw-h-full slide-content">
                                        <div className="tw-text-4xl tw-text-blue-400 tw-mb-2">
                                            &ldquo;
                                        </div>
                                        <p className="tw-text-sm tw-opacity-70">{testimonial.paragraph}</p>
                                        <div className="tw-flex tw-items-center tw-mt-4">
                                            <img
                                                src={testimonial.profileImage}
                                                alt={`${testimonial.name}'s profile`}
                                                className="tw-w-10 tw-h-10 tw-rounded-full tw-mr-2"
                                            />
                                            <div>
                                                <div className="tw-text-xs sm:tw-text-xs md:tw-text-[16px] lg:tw-text-[12px] tw-font-bold">{testimonial.name}</div>
                                                <div className="tw-text-xs tw-opacity-70">{testimonial.designation}</div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Sidebar;