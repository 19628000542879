// import { useEffect, useState } from "react";
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
// import InputAdornment from '@mui/material/InputAdornment';
// import IconButton from '@mui/material/IconButton';
import DatePickerComponent from "../common/datepicker/DatePicker";
import PhoneNumberInput from "../common/phoneinput/PhoneNumberInput";

const ChangePersonalInformationForm = ({ setUserInfo, onChange, userInfo, disableForm }) => {
    // const [formState, setFormState] = useState({
    //     firstName: '',
    //     lastName: '',
    //     dateOfBirth: '',
    //     email: '',
    //     phoneNumber: ''
    // });


    // useEffect(() => {
    //     if (profileUserData) {
    //         setFormState({
    //             firstName: profileUserData.first_name || '',
    //             lastName: profileUserData.last_name || '',
    //             dateOfBirth: profileUserData.dob || '',
    //             email: profileUserData.email || '',
    //             phoneNumber: profileUserData.phone_number || ''
    //         });
    //     }
    // }, [profileUserData]);

    const handleChange = (e) => {
        const { id, value } = e.target;
        // setFormState(prev => ({ ...prev, [id]: value }));
        setUserInfo(prev => ({ ...prev, [id]: value }));
        onChange();
    };

    return (
        <div className="tw-flex tw-space-x-4">
            <div className="tw-flex tw-flex-col">
                <p className="tw-text-[#202224]/70 tw-font-nunito tw-text-md tw-font-semibold">First Name</p>
                <FormControl sx={{ mr: 2, width: '100%' }} variant="outlined">
                    <OutlinedInput
                        id="firstName"
                        type='text'
                        placeholder="First Name"
                        value={userInfo?.firstName}
                        onChange={handleChange}
                        disabled={disableForm}
                    />
                </FormControl>
            </div>

            <div className="tw-flex tw-flex-col">
                <p className="tw-text-[#202224]/70 tw-font-nunito tw-text-md tw-font-semibold">Last Name</p>
                <FormControl sx={{ mr: 2, width: '100%' }} variant="outlined">
                    <OutlinedInput
                        id="lastName"
                        type='text'
                        placeholder="Last Name"
                        value={userInfo?.lastName}
                        onChange={handleChange}
                        disabled={disableForm}
                    />
                </FormControl>
            </div>

            <div className="tw-flex tw-flex-col">
                <p className="tw-text-[#202224]/70 tw-font-nunito tw-text-md tw-font-semibold">Date of Birth</p>
                {disableForm ? (
                    <input
                        type="text"
                        value={userInfo?.dob}
                        disabled
                        placeholder="Date of birth"
                        className="tw-bg-white tw-border tw-border-[#DDDDDD] tw-h-[55px] tw-rounded-[10px] tw-text-gray-500 tw-py-2 tw-px-4"
                    />
                ) : (
                    <DatePickerComponent
                        value={userInfo?.dob}
                        onChange={(date) => {
                            setUserInfo((prev) => ({ ...prev, dob: date }));
                        }}
                    />
                )}
            </div>

            <div className="tw-flex tw-flex-col">
                <p className="tw-text-[#202224]/70 tw-font-nunito tw-text-md tw-font-semibold">Email</p>
                <FormControl sx={{ mr: 2, width: '100%' }} variant="outlined">
                    <OutlinedInput
                        id="email"
                        type='email'
                        placeholder="Email"
                        value={userInfo?.email}
                        onChange={handleChange}
                        disabled
                    />
                </FormControl>
            </div>

            <div className="tw-flex tw-flex-col">
                <p className="tw-text-[#202224]/70 tw-font-nunito tw-text-md tw-font-semibold">Phone Number</p>
                <PhoneNumberInput
                    value={userInfo?.phoneNumber}
                    onChange={(phone) => {
                        setUserInfo(prev => ({ ...prev, phoneNumber: phone }));
                    }}
                    disabled={disableForm}
                />
            </div>
        </div>
    )
}

export default ChangePersonalInformationForm;