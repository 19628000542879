import React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import ApplicationTableHeader from './ApplicationTableHeader';
import { Typography } from '@mui/material';

function ApplicationTable({
    columns,
    rows,
    selectedRow,
    dataTableProps = {},
    selectedRowId,
    onRowClick,
    tableHeight = "100%",
    maxHeight,
    minHeight,
    useAutoHeight = false,
    CustomHeaderComponent,
    customHeaderProps
}) {
    // Custom "No Rows" component for DataGrid
    const NoRowsOverlay = () => (
        <Box
            sx={{
                flexGrow: 1,
                width: '100%',
                overflow: 'auto',
                height: tableHeight,
            }}
        >
            <Typography variant="body1" color="textSecondary">
                No records found
            </Typography>
        </Box>
    );

    return (
        <div className="tw-resize tw-overflow-auto tw-h-full tw-w-full tw-rounded-[10px] tw-border tw-border-[#DDDDDD] tw-flex tw-flex-col"
            style={{
                height: "100%",
                minHeight,
                maxHeight,
                minWidth: "75%",
                maxWidth: "100%",
                overflow: "hidden"
            }}>
            {CustomHeaderComponent ? (
                <CustomHeaderComponent {...customHeaderProps} />
            ) : <ApplicationTableHeader
                dataHeaderProps={{
                    title: dataTableProps?.title,
                    filter: dataTableProps?.userStatus,
                    dashboardType: dataTableProps?.dashboardType,
                    handleSortChange: dataTableProps?.handleSortChange,
                    handleItemClick: dataTableProps?.handleItemClick || '',
                    setFilters: dataTableProps?.setFilters,
                }}
            />
            }
            <Box
                sx={{
                    flexGrow: 1,
                    width: '100%',
                    overflow: 'auto',
                    height: tableHeight,
                }}
            >
                <DataGrid
                    rows={rows}
                    columns={columns}
                    autoHeight={useAutoHeight} // Toggle auto-height dynamically
                    getRowHeight={() => 'auto'}
                    onRowClick={(row) => onRowClick(row?.row)}
                    getRowId={(row) => row?.id}
                    hideSortIcons={false}
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 20 },
                        },
                        sorting: { sortModel: [{ field: 'id', sort: 'desc' }] },
                    }}
                    components={{
                        NoRowsOverlay: NoRowsOverlay, // Use custom No Rows message
                    }}
                    sx={{
                        border: 'none',
                        '& .MuiDataGrid-cell': {
                            borderRight: '1px solid #DDDDDD',
                            whiteSpace: 'normal', // Enable multiline text
                            wordWrap: 'break-word', // Break words when needed
                            fontSize: '12px',
                            display: 'flex', // Use flexbox for alignment
                            alignItems: 'center', // Vertically center items
                            justifyContent: 'center', // Horizontally center items
                            padding: "10px 5px"
                        },
                        '& .MuiDataGrid-cell:focus': {
                            outline: 'none'
                        },
                        '& .MuiDataGrid-row:hover': {
                            cursor: 'pointer'
                        },
                        boxShadow: 2,
                        // '& .MuiDataGrid-columnHeaders *': {
                        //     backgroundColor: "#DDE1EE",
                        // },
                        '& .MuiDataGrid-footerContainer': {
                            borderTop: '1px solid #DDDDDD', // Add top border
                            borderLeft: 'none',
                            borderRight: 'none',
                            borderBottom: 'none',
                        },
                        '.MuiDataGrid-iconButtonContainer': {
                            visibility: 'visible',
                        },
                        '.MuiDataGrid-sortIcon': {
                            opacity: 'inherit !important',
                        },
                    }}
                    getRowClassName={(params) =>
                        params.row.application_id === selectedRowId ? 'selected-row' : ''
                    }
                    pageSizeOptions={[10, 20, 50, 100]}
                    rowSelectionModel={[selectedRow]}
                    disableRowSelectionOnClick
                    {...dataTableProps}
                />
            </Box>
        </div>
    );
}

export default ApplicationTable;
