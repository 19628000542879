import React, { useEffect, useRef } from 'react';
import TrustedPartnersLine from "../../../assets/icons/trustePartnersLeftLines.svg";
import Adaptavist from "../../../assets/icons/adaptavist.png";
import Mailgun from "../../../assets/icons/mailgun.png";
import Illumos from "../../../assets/icons/illumos.png";
import Kabbage from "../../../assets/icons/kabbage.png";
import Jasmine from "../../../assets/icons/jasmine.png";

const TrustedPartnersArea = () => {
    const carouselRef = useRef(null);

    useEffect(() => {
        const scrollInterval = setInterval(() => {
        if (carouselRef.current) {
            // Scroll the container to the right continuously
            carouselRef.current.scrollLeft += 1;

            // Check if we've reached the end of the scrollable content, then reset to the start
            if (carouselRef.current.scrollLeft >= carouselRef.current.scrollWidth - carouselRef.current.clientWidth) {
                carouselRef.current.scrollLeft = 0;
            }
        }
        }, 100); // Adjust the interval speed here

        // Cleanup the interval on component unmount
        return () => clearInterval(scrollInterval);
    }, []);

    return (
        <div className="tw-relative tw-bg-[#4853E4] tw-flex tw-flex-col tw-py-10 tw-w-full">
            <h2 className="tw-font-oswald tw-text-4xl tw-font-bold tw-text-center">Our Trusted Partners</h2>
            
            {/* Carousel */}
            <div ref={carouselRef} className="tw-w-full tw-flex tw-justify-center tw-overflow-x-hidden tw-snap-x tw-snap-mandatory tw-scrollbar-hide tw-my-4">
                <div className="tw-flex-none tw-m-2 tw-snap-center tw-h-10">
                    <img src={Adaptavist} alt="adaptavist logo" className="tw-object-cover tw-w-full tw-h-full" />
                </div>
                
                <div className="tw-flex-none tw-m-2 tw-snap-center tw-h-10">
                    <img src={Mailgun} alt="mailgun logo" className="tw-object-cover tw-w-full tw-h-full" />
                </div>
                
                <div className="tw-flex-none tw-m-2 tw-snap-center tw-h-10">
                    <img src={Kabbage} alt="mailgun logo" className="tw-object-cover tw-w-full tw-h-full" />
                </div>
                
                <div className="tw-flex-none tw-m-2 tw-snap-center tw-h-10">
                    <img src={Illumos} alt="mailgun logo" className="tw-object-cover tw-w-full tw-h-full" />
                </div>
                
                <div className="tw-flex-none tw-m-2 tw-snap-center tw-h-10">
                    <img src={Jasmine} alt="mailgun logo" className="tw-object-cover tw-w-full tw-h-full" />
                </div>
            </div>
            <img src={TrustedPartnersLine} className="tw-absolute tw-bottom-0 tw-left-0 tw-h-20" alt="TrustedPartnersLine"/>
        </div>
    )
}

export default TrustedPartnersArea;