import React from 'react';
import { Chart } from 'react-charts';
import MoreVertIcon from '@mui/icons-material/MoreVert';

function AnnualInterestGraph({ data, loading }) {
    // Check if the data is empty
    const hasData = data.length > 0 && data[0].data.length > 0;

    return (
        <div className="tw-h-[270px] tw-border tw-border-[#DDDDDD] tw-rounded-[10px] tw-px-6 tw-py-2">
            <div className="tw-w-full tw-flex tw-justify-between tw-items-start">
                <h2 className="tw-font-inter tw-text-[#202224] tw-text-[22px] tw-font-bold">Annual Interest</h2>
                <div className="tw-text-gray-800 tw-cursor-pointer">
                    <MoreVertIcon />
                </div>
            </div>
            <div className="graph tw-h-[200px] tw-mt-[20px] tw-relative">
                {loading ? (
                    <div className="tw-text-[#242424]">Loading Annual Interest Graph...</div>
                ) : (
                    <Chart
                        data={hasData ? data : [{ label: 'No Data', data: [{ x: 'No Data', y: 0 }] }]}
                        axes={[
                            {
                                primary: true,
                                type: 'ordinal',
                                position: 'bottom',
                                label: '% Interest',
                                showGrid: false,
                                format: (x) => `${x} %`,
                            },
                            {
                                type: 'linear',
                                position: 'left',
                                label: '# of Offers',
                                showGrid: true,
                                hardMin: 0,
                                tickPadding: 10,
                                tickCount: 6,
                                tickValues: [0, 3, 6, 9, 12, 15],
                            },
                        ]}
                        series={{ type: 'bar' }}
                        getSeriesStyle={() => ({
                            color: '#555FE1',
                            width: 35,
                        })}
                        getDatumStyle={() => ({
                            color: '#7DDE92',
                            clipPath: 'inset(0 0 0 round 5px)',
                        })}
                        style={{
                            height: '100%',
                            width: '100%',
                            position: 'relative',
                        }}
                    />
                )}
                {/* Show 'No Data' message at the center when no data is available */}
                {!loading && !hasData && (
                    <div className="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center tw-text-[#A9A9A9]">
                        No Data
                    </div>
                )}
            </div>
        </div>
    );
}

export default AnnualInterestGraph;
