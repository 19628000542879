import axios from 'axios';
import toast from 'react-hot-toast';

export const fetchGraphData = async (url, payload) => {
    try {
        const response = await axios.post(url, payload);
        return response.data;
    } catch (error) {
        console.error("API Error:", error);
        toast.error('An error occurred while fetching graph data.');
        throw error;
    }
};
