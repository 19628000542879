import React, { useEffect, useState, useContext, useRef } from 'react';
import FormBox from './FormBox';
import StatsCards from '../../common/StatsCards';
import NewApplicationModal from './modal/NewApplicationModal';
import axios from 'axios';
import { UserContext } from '../../../contexts/UserContext';
import { AWSAmplifyConfigContext } from '../../../contexts/AWSAmplifyConfigContext';
import toast from 'react-hot-toast';
import StatsDropdown from '../../common/StatsDropdown';
import ApplicationTable from '../../common/applicationTable/ApplicationTable';
import { GoogleMapsProvider } from '../../../contexts/GoogleMapsContext';
import AssetGenerated from '../lender/AssetGenerated';
import BorrowerHeader from './BorrowerHeader';
import Chat from '../../common/chat-new/Chat';
import { useLoader } from '../../../contexts/LoaderContext';
import { formatTimeDifference, formatPrice, getApplicationStatus } from '../../../utils/common';

function Borrower() {
    const { user } = useContext(UserContext);
    const awsContextVal = useContext(AWSAmplifyConfigContext);
    const chatRef = useRef(null);
    const handleCloseModal = () => setOpenModal(false);
    const [openModal, setOpenModal] = useState(false);
    const [refreshKey, setRefreshKey] = useState(Math.random())
    const [loanApplications, setLoanApplications] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [borrowerApplicationsSummary, setBorrowerApplicationsSummary] = useState([]);
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [formattedTimes, setFormattedTimes] = useState([]);
    const [applicationStatus, setApplicationStatus] = useState([]);
    const [filters, setFilters] = useState({
        user_id: user?.id,
        search_text: '',
        loan_purpose_id: '',
        property_type_id: '',
        state_id: '',
        city_id: '',
        property_value_min: '',
        property_value_max: '',
        status_id: '',
        sort_by: 'a.id',
        sort_order: 'DESC'
    });

    const { setCurrentOfferPropertyDetails } = useContext(UserContext);
    const { showLoader, hideLoader } = useLoader();

    const [stats, setStats] = useState([
        { title: 'Total Applications', value: '40,689', change: '8.5%' },
        { title: 'Accepted Applications', value: '40,689', change: '8.5%' },
        { title: 'Pending Applications', value: '40,689', change: '8.5%' },
        { title: 'Rejected Applications', value: '40,689', change: '8.5%' },
        { title: 'Total Amount Received', value: '40,689', change: '8.5%' },
        { title: 'Loan Acceptance Rate', value: '120%', change: '8.5%' },
    ]);

    //This is to get Borrower Dashboard Summary

    const fetchBorrowerLoanSummary = async () => {
        if (!user) return;

        try {
            const response = await axios.post(`${awsContextVal?.endpoint}/getLenderDashboardSummary`, { userId: user?.id });
            setBorrowerApplicationsSummary(response?.data);
        } catch (err) {
            if (err.response) {
                toast.error(err.response.data?.message);
            } else {
                toast.error("An error occurred while fetching loan applications.");
            }
        }
    };

    const fetchUserLoanApplications = async () => {
        if (!user) return;

        try {
            const response = await axios.post(`${awsContextVal?.endpoint}/application/borrower/list`, filters);
            setLoanApplications(response?.data?.applications);
        } catch (err) {
            // adding a defualt location for demo purposes
            setLoanApplications([]);
            if (err.response) {
                toast.error(err.response.data?.message);
            } else {
                toast.error("An error occurred while fetching loan applications.");
            }
        }
    };
    useEffect(() => {
        // Set up an interval to call it every 30 seconds
        const intervalId = setInterval(() => {
            fetchUserLoanApplications();
        }, 30000);

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    }, [user]);

    useEffect(() => {
        fetchUserLoanApplications();
    }, [filters]);

    // const handleSortChange = (type) => {
    //     let newSortOrder = '';

    //     if (type === 'oldFirst') {
    //         newSortOrder = 'ASC';  // Set sort order to ASC for "Old First"
    //     } else if (type === 'newFirst') {
    //         newSortOrder = 'DESC'; // Set sort order to DESC for "New First"
    //     }

    //     // Update both the local sortOrder state (if necessary) and filters state
    //     setFilters((prevFilters) => ({
    //         ...prevFilters,
    //         sort_order: newSortOrder,  // Update the sort_order in filters
    //     }));
    // };
    const handleSortChange = (type) => {
        let newSortOrder = ''; 
        let sortByField = ''; 
        switch (type) {
            case 'oldFirst':
                newSortOrder = 'ASC';
                sortByField = 'a.id';
                break;
            case 'newFirst':
                newSortOrder = 'DESC';
                sortByField = 'a.id';
                break;

            // LTV Sorting
            case 'oldLtv':
                newSortOrder = 'ASC';
                sortByField = 'a.ltv';
                break;
            case 'newLtv':
                newSortOrder = 'DESC';
                sortByField = 'a.ltv';
                break;

            // Property Value Sorting
            case 'oldPropertyValue':
                newSortOrder = 'ASC';
                sortByField = 'a.property_value';
                break;
            case 'newPropertyValue':
                newSortOrder = 'DESC';
                sortByField = 'a.property_value';
                break;

            // Loan Amount Sorting
            case 'oldLoanAmount':
                newSortOrder = 'ASC';
                sortByField = 'a.loan_amount';
                break;
            case 'newLoanAmount':
                newSortOrder = 'DESC';
                sortByField = 'a.loan_amount';
                break;

            // Number of Offers Sorting
            case 'oldNumberOfOffers':
                newSortOrder = 'ASC';
                sortByField = 'a.potential_lenders';
                break;
            case 'newNumberOfOffers':
                newSortOrder = 'DESC';
                sortByField = 'a.potential_lenders';
                break;

            default:
                console.warn(`Unhandled sort type: ${type}`);
                return; 
        }

        setFilters((prevFilters) => ({
            ...prevFilters,
            sort_order: newSortOrder,
            sort_by: sortByField,
        }));
    };

    useEffect(() => {
        fetchUserLoanApplications();
        fetchBorrowerLoanSummary();
    }, [refreshKey]);


    useEffect(() => {
        const stats = [
            { title: 'Total Applications', value: borrowerApplicationsSummary?.total_applications, change: '8.5%' },
            { title: 'Accepted Applications', value: borrowerApplicationsSummary?.accepted_applications, change: '8.5%' },
            { title: 'Pending Applications', value: borrowerApplicationsSummary?.pending_applications, change: '8.5%' },
            { title: 'Rejected Applications', value: borrowerApplicationsSummary?.rejected_applications, change: '8.5%' },
            { title: 'Total Amount Lent', value: '40,689', change: '8.5%' },
            { title: 'Loan Acceptance Rate', value: '120%', change: '8.5%' },
        ];
        setStats(stats)
    }, [borrowerApplicationsSummary]);

    const handleNewApplication = async () => {
        await fetchUserLoanApplications();
        handleCloseModal();
    };

    const columns = [
        // { field: 'application_id', headerName: 'ID', minWidth: 60, flex: 0.5, editable: false },
        {
            field: 'property_address',
            headerName: 'Address',
            minWidth: 150,
            flex: 1,
            editable: false,
            sortable: true
        },
        {
            field: 'loan_amount',
            headerName: 'Loan Amount',
            minWidth: 100,
            flex: 0.8,
            editable: false,
            sortable: true
        },
        {
            field: 'property_value',
            headerName: 'Property Value',
            type: 'number',
            minWidth: 120,
            flex: 0.8,
            editable: false,
            sortable: true
        },
        {
            field: 'property_type',
            headerName: 'Property Type',
            type: 'text',
            minWidth: 120,
            flex: 0.8,
            editable: false,
            renderCell: (params) => (
                <div
                    style={{
                        textAlign: 'center',
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                        lineHeight: '1.5',
                    }}
                >
                    {params.value}
                </div>
            ),
        },
        {
            field: 'loan_purpose',
            headerName: 'Loan Purpose',
            minWidth: 140,
            flex: 1,
            editable: false,
        },
        {
            field: 'ltv',
            headerName: 'LTV',
            minWidth: 100,
            flex: 0.6,
            editable: false,
        },
        {
            field: 'number_of_offers',
            headerName: 'Number of Offers',
            type: 'number',
            minWidth: 100,
            flex: 0.6,
            editable: false,
        },
        {
            field: 'property_status',
            headerName: 'Status',
            minWidth: 140,
            flex: 0.7,
            renderCell: (params) => {
                const statusId = params?.value;
                const { render } = getApplicationStatus(statusId, user?.user_type);
                return render();
            },
        },
        {
            field: 'createdAt',
            headerName: 'Days Open',
            minWidth: 110,
            flex: 1,
            editable: false,
            renderCell: (params) => {
                const formattedTime = formattedTimes.find(
                    (item) => item.application_id === params.row.application_id
                )?.formattedTime;
                return formattedTime || formatTimeDifference(params.value);
            },
        },
    ];
    // console.log("loanApplications::::", loanApplications);
    const rows = loanApplications?.length ? loanApplications?.map(app => ({
        application_id: app?.application_id ? app?.application_id : '',
        property_address: app?.property_address ? app?.property_address : '',
        loan_purpose: app?.loan_purpose ? app?.loan_purpose : '',
        property_value: app?.property_value ? formatPrice(app?.property_value) : '',
        property_type: app?.property_type,
        loan_amount: app?.loan_amount ? formatPrice(app?.loan_amount) : '',
        number_of_offers: app?.total_application_offer ? app?.total_application_offer : "0",
        property_status: app?.property_status || '',
        ltv: app?.ltv ? `${app.ltv}%` : '0%',
        lat: app?.lat ? app?.lat : '40.758896',
        lng: app?.lng ? app?.lng : '-73.985130',
        borrower: app?.first_name + ' ' + app?.last_name,
        streetAddress: app.property_address,
        createdAt: app.application_created_at || 'Just now',
    })) : [];

    let isScrolling = false; // Prevent overlapping scroll events

    const smoothScrollTo = (target, duration = 500) => {
        if (!target) return; // Guard for undefined targets

        const start = window.scrollY;
        const end = target.offsetTop;
        const distance = end - start;
        let startTime = null;

        // Easing function for ultra-smoothness (easeInOutCubic)
        const easeInOutCubic = (t) => {
            return t < 0.5
                ? 4 * t * t * t
                : 1 - Math.pow(-2 * t + 2, 3) / 2;
        };

        const step = (timestamp) => {
            if (!startTime) startTime = timestamp;
            const progress = Math.min((timestamp - startTime) / duration, 1); // Clamp between 0 and 1
            const easedProgress = easeInOutCubic(progress);
            const scrollPosition = start + distance * easedProgress;

            window.scrollTo(0, scrollPosition);

            if (progress < 1) {
                requestAnimationFrame(step); // Continue scrolling
            } else {
                isScrolling = false; // Reset flag after scrolling
            }
        };

        requestAnimationFrame(step);
    };

    const handleRowClick = (params) => {
        if (isScrolling) return; // Prevent overlapping scroll events
        isScrolling = true;

        showLoader();

        const location = rows.find((loc) => loc.application_id === params.application_id);
        if (location) {
            setSelectedLocation({ lat: location.lat, lng: location.lng }); // Update selected location
            setSelectedRowId(params.application_id);
            setCurrentOfferPropertyDetails(params);

            setTimeout(() => {
                smoothScrollTo(chatRef.current, 500);
                hideLoader();
            }, 1000);
        } else {
            hideLoader(); // Ensure loader is hidden if location is not found
        }
    };

    useEffect(() => {
        if (rows.length > 0) {
            if (selectedRowId > 0) {
                const selecteApplication =
                    rows.find((application) => application?.application_id === selectedRowId);
                setSelectedRowId(selecteApplication?.application_id);
                setCurrentOfferPropertyDetails(selecteApplication);
            } else {
                const firstRow = rows[0];
                const lastRow = rows[rows.length - 1];
                setSelectedRowId(firstRow?.application_id);
                setCurrentOfferPropertyDetails(firstRow);
                setSelectedLocation({ lat: firstRow.lat, lng: firstRow.lng });
            }
        }
    }, [loanApplications]);

    const fetchApplicationStatusList = async () => {
        showLoader();
        if (!user) return;

        try {
            const response = await axios.get(`${awsContextVal?.endpoint}/application-status/list`);
            setApplicationStatus(response?.data?.applications_status);
        } catch (err) {
            // adding a defualt location for demo purposes
            if (err.response) {
                toast.error(err.response.data?.message);
            } else {
                toast.error("An error occurred while fetching applications status.");
            }
        } finally {
            hideLoader();
        }
    }

    const handleItemClick = (id) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            status_id: id,
        }));
    }

    useEffect(() => {
        fetchApplicationStatusList();
    }, [user])

    const borrowerHeaderProps = {
        userStatus: applicationStatus,
        handleSortChange,
        handleItemClick,
        setFilters,
        filters
    };

    return (
        <GoogleMapsProvider>
            <div className="tw-h-full tw-w-full">
                <header className="common-font tw-flex tw-justify-between tw-items-center tw-w-full tw-h-[60px]">
                    {/* <div>
                        <h1 className="tw-text-black tw-text-[40px] tw-font-bold tw-leading-[60px]">Borrower Dashboard</h1>
              
                    </div> */}
                    {/* <button
                        className="tw-w-[193px] tw-h-[49px] tw-bg-[#7DDE92] tw-font-semibold tw-text-[#2A2F70] tw-rounded-lg tw-py-[12px] tw-px-[18px] tw-gap-[4px] tw-text-[18px] tw-leading-[24.55px]"
                        onClick={handleOpenModal}
                    >
                        New Application +
                    </button> */}
                </header>
                {/*
                <div className="tw-block">
                    <StatsDropdown />

                    <div className="tw-flex tw-justify-between tw-bg-white tw-rounded-lg">
                        {stats?.map((stat, index) => (
                            <StatsCards
                                key={index}
                                title={stat?.title}
                                value={stat?.value}
                                change={stat?.change}
                            />
                        ))}
                    </div>
                </div>
		*/}

                <div className="tw-flex tw-mt-1 hidden tw-justify-between tw-space-x-4">
                    <div className="tw-w-2/3">
                        <ApplicationTable
                            dataTableProps={{
                                getRowId: (row, index) => `${index}-${row?.application_id}`,
                                userStatus: applicationStatus,
                                dashboardType: 'borrower',
                                handleSortChange,
                                handleItemClick,
                                setFilters,
                                getRowClassName: (params) =>
                                    params.row.application_id === selectedRowId ? 'selected-row' : ''
                            }}
                            rows={rows}
                            selectedRow={selectedLocation || rows[rows.length - 1]}
                            columns={columns}
                            onRowClick={handleRowClick}
                            customHeaderProps={borrowerHeaderProps}
                            CustomHeaderComponent={BorrowerHeader}
                            tableHeight="400px"
                            maxHeight= '120vh'
                            minHeight= '50%'
                        />
                    </div>
                    <div className='tw-w-1/3'>
                        <FormBox callBack={() => setRefreshKey(Math.random())} selectedLocation={selectedLocation} />
                    </div>
                </div>

                <div className='tw-flex tw-mt-4 hidden tw-justify-between '>
                    <div className='tw-w-2/3'>
                        <Chat
                            ref={chatRef}
                            application_id={selectedRowId}
                            fetchUserLoanApplications={fetchUserLoanApplications}
                            setSelectedAppId={setSelectedRowId}
                        />
                    </div>

                    <div className='tw-w-1/3'>
                        <AssetGenerated />
                    </div>
                </div>
                <NewApplicationModal open={openModal} handleClose={handleCloseModal} onNewApplication={handleNewApplication} />
            </div>
        </GoogleMapsProvider>

    );
}

export default Borrower;
