import React from 'react';
import {
    Typography
    
} from "@material-tailwind/react";

function ApplicationTableHeader({ dataHeaderProps }) {
    return (
        <div className='tw-h-[40px] tw-mx-[20px] tw-my-[20px] tw-flex'>
            <>
                {dataHeaderProps?.title && dataHeaderProps?.title != '' ?
                    <>
                        <Typography className='tw-text-[#202224] common-font tw-text-[22px] tw-font-semibold'> {dataHeaderProps?.title}</Typography>
                    </>
                    :

                    <div className='tw-flex-grow-[4] tw-border-b-[1px] tw-border-[#DDDDDD] tw-items-center tw-pl-3'>
                        <i className="fas fa-search tw-text-[#4F4F4F] tw-mr-2 tw-opacity-70"></i>
                        <input
                            type="text"
                            placeholder="Search application here..."
                            className="tw-text-[#4F4F4F] common-font tw-text-[14px] tw-font-normal tw-italic tw-bg-transparent tw-border-none focus:tw-outline-none"
                        />
                    </div>
                }
            </>
        </div>
    )
}

export default ApplicationTableHeader