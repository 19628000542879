import React, { useState, useEffect } from 'react';

function ConversationFilter({ onApply, onReset, initialFilters }) {
    const [interest, setInterest] = useState('');
    const [loanMonths, setLoanMonths] = useState('');
    const [originationFee, setOriginationFee] = useState('');
    const [errors, setErrors] = useState({ interest: '', loanMonths: '', originationFee: '' });

    useEffect(() => {
        // Initialize values from props
        setInterest(initialFilters.interest_on_loan || '');
        setLoanMonths(initialFilters.loan_duration || '');
        setOriginationFee(initialFilters.fees || '');
    }, [initialFilters]);

    const handleSubmit = () => {
        const newErrors = { interest: '', loanMonths: '', originationFee: '' };
        let isValid = true;

        if (isNaN(Number(interest))) {
            newErrors.interest = 'Please enter a valid interest rate.';
            isValid = false;
        }

        if (isNaN(Number(loanMonths))) {
            newErrors.loanMonths = 'Please enter a valid number of loan months.';
            isValid = false;
        }

        if (isNaN(Number(originationFee))) {
            newErrors.originationFee = 'Please enter a valid origination fee.';
            isValid = false;
        }

        setErrors(newErrors);

        if (isValid) {
            onApply({ interest, loanMonths, originationFee });
        }
    };

    const handleReset = () => {
        setInterest('');
        setLoanMonths('');
        setOriginationFee('');
        setErrors({ interest: '', loanMonths: '', originationFee: '' });

        // Call the parent-provided reset handler to trigger API
        if (onReset) {
            onReset();
        }
    };

    return (
        <div className="tw-space-y-3 tw-px-3 tw-py-2" onMouseDown={(e) => e.stopPropagation()}>
            <label className="tw-text-gray-700 tw-text-sm tw-font-semibold">Interest Rate (%)</label>
            <input
                type="text"
                value={interest}
                onChange={(e) => setInterest(e.target.value)}
                placeholder="Enter interest rate"
                className="tw-w-full tw-border tw-border-gray-300 tw-rounded-md tw-p-[8px] tw-text-sm tw-outline-none focus:tw-border-blue-500 tw-text-[#242424]"
            />
            {errors.interest && <p className="tw-text-red-500 tw-text-sm">{errors.interest}</p>}

            <label className="tw-text-gray-700 tw-text-sm tw-font-semibold">Loan Months</label>
            <input
                type="text"
                value={loanMonths}
                onChange={(e) => setLoanMonths(e.target.value)}
                placeholder="Enter loan months"
                className="tw-w-full tw-border tw-border-gray-300 tw-rounded-md tw-p-[8px] tw-text-sm tw-outline-none focus:tw-border-blue-500 tw-text-[#242424]"
            />
            {errors.loanMonths && <p className="tw-text-red-500 tw-text-sm">{errors.loanMonths}</p>}

            <label className="tw-text-gray-700 tw-text-sm tw-font-semibold">Origination Fee</label>
            <input
                type="text"
                value={originationFee}
                onChange={(e) => setOriginationFee(e.target.value)}
                placeholder="Enter origination fee"
                className="tw-w-full tw-border tw-border-gray-300 tw-rounded-md tw-p-[8px] tw-text-sm tw-outline-none focus:tw-border-blue-500 tw-text-[#242424]"
            />
            {errors.originationFee && <p className="tw-text-red-500 tw-text-sm">{errors.originationFee}</p>}

            <div className="tw-flex tw-justify-between tw-mt-3">
                <button tabIndex={0} className='tw-m-1 tw-flex tw-justify-center tw-items-center tw-cursor-pointer tw-mr-4' onClick={handleReset}>
                    <i className="tw-text-white fas fa-redo tw-me-[10px] tw-filter-icons-bg"></i>
                    <span className='tw-text-[#2A2F70] tw-text-[18px] tw-font-semibold common-font'>
                        Reset
                    </span>
                </button>
     
                <button
                    className="tw-bg-green-500 tw-text-white tw-text-sm tw-font-semibold tw-py-[6px] tw-px-[20px] tw-rounded-md hover:tw-bg-green-600"
                    onClick={handleSubmit}
                >
                    Apply Filters
                </button>
            </div>
        </div>
    );
}

export default ConversationFilter;
